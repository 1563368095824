import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function MenuProveedor() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menú</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/proveedor" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        {/* Recursos */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'recursos' || path[2] === 'recurso' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-person-video2"></i>
            <span>Recursos</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/proveedor/recursos">Ver Todos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/recurso/crear">
                Crear Nuevo <i className="bi bi-person-add"></i>
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/recursos/proyectos">Proyectos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/recursos/desvinculados">Desvinculados</Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/documentacion/recursos">
                Informes <i className="bi bi-file-earmark-bar-graph"></i>
              </Link>
            </li>
          </ul>
        </li>

        {/* GASTO ADICIONAL */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'gastos' || path[2] === 'gastos' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-coin"></i>
            <span>Gastos Adicionales</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/proveedor/gastos">Ir</Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/gastos/nuevo">Nueva Solicitud</Link>
            </li>
            <li className="submenu-item disabled">
              <Link to="/proveedor/gastos/documentos">
                Informes <i className="bi bi-file-earmark-bar-graph"></i>
              </Link>
            </li>
          </ul>
        </li>

        {/* OVT */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'ovt' || path[2] === 'ovt' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-clock-fill"></i>
            <span>Overtime</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/proveedor/overtime">Ir</Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/overtime/nuevo">Nueva Solicitud</Link>
            </li>
            <li className="submenu-item">
              <Link to="/proveedor/overtime/documentos">
                Informes <i className="bi bi-file-earmark-bar-graph"></i>
              </Link>
            </li>
          </ul>
        </li>

        {/* Reclamos */}
        {/* <li
          className={'sidebar-item ' + (path[2] === 'reclamos' ? 'active' : '')}
        >
          <Link to="/proveedor/reclamos" className="sidebar-link">
            <i className="bi bi-bell-fill"></i>
            <span>Reclamos</span>
          </Link>
        </li> */}

        {/* Sistema */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'documentos' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-gear"></i>
            <span>Sistema</span>
          </Link>
          <ul className="submenu">
            {/* <li className="submenu-item">
              <Link to="/proveedor/documentos/empresa">Empresa</Link>
            </li> */}
            <li className="submenu-item">
              <Link to="/proveedor/sistema/correos">Correos</Link>
            </li>
          </ul>
        </li>
        {/* Cerrar Sesion */}
        <li className="sidebar-item">
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuProveedor;
