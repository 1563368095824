import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { urlS3 } from '../../../config';
import {
  getLastRecursos,
  getLastRecursosByProveedor,
} from '../../../services/recursos';

function UltimosRecursos({ rut_proveedor, apiToken }) {
  const laUrl = useLocation().pathname;
  const perfil = useMemo(() => laUrl.split('/')[1], [laUrl]);
  const [ultimosRecursos, setUltimosRecursos] = useState([]);

  async function fetchRecursos() {
    try {
      let data;
      if (perfil === 'proveedor') {
        data = await getLastRecursosByProveedor(rut_proveedor, apiToken);
      } else if (perfil === 'administracion' || perfil === 'spoc') {
        data = await getLastRecursos(apiToken);
      }
      setUltimosRecursos(data.recursos);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRecursos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card shadow">
      <div className="card-header">
        <h6>ÚLTIMAS INCORPORACIONES</h6>
      </div>
      <div className="card-content pb-4">
        {ultimosRecursos.length ? (
          ultimosRecursos.map((recurso, i) => (
            <div className="recent-message d-flex px-4 py-3" key={i}>
              <div className="avatar avatar-xl">
                {recurso.foto ? (
                  <img
                    src={urlS3 + recurso.foto}
                    alt={recurso.nombre.slice(0, 3).toUpperCase()}
                  />
                ) : (
                  <span className="avatar-content bg-secondary rounded-circle">
                    {recurso.nombre.slice(0, 3).toUpperCase()}
                  </span>
                )}
              </div>
              <div className="name ms-4">
                <Link to={`/${perfil}/recurso/${recurso.rut}`}>
                  <h6 className="mb-1">{`${recurso.nombre} ${recurso.apellido}`}</h6>
                </Link>
                <p className="text-muted mb-0">
                  {recurso.proveedor_nombre || recurso.region}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="recent-message d-flex px-4 py-3">
            <h6>No hay informacion</h6>
          </div>
        )}
        {`/${perfil}` === laUrl && (
          <div className="px-4">
            <Link
              to={`/${perfil}/recursos`}
              className="btn btn-block btn-outline-primary font-bold mt-3"
            >
              Ver Todos
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default UltimosRecursos;
