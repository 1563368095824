import { useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { uploadComprobanteGasto } from '../../../../services/gastosAdicionales';

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      'auto'
    );
  });
};

const FileInput = ({ inputRef, handleFileChange }) => {
  return (
    <div className="form-group">
      <div className="custom-file">
        <input
          ref={inputRef}
          type="file"
          className="custom-file-input"
          id="imageFile"
          onChange={handleFileChange}
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <label className="custom-file-label" htmlFor="imageFile">
          Elegir archivo
        </label>
      </div>
    </div>
  );
};

const ImageUploadPreview = ({
  previewImage,
  handleFileDrop,
  handleFileDragOver,
}) => {
  return (
    <div className="form-group text-center border border-primary">
      <div
        className="image-upload-preview"
        onDrop={handleFileDrop}
        onDragOver={handleFileDragOver}
      >
        <p className="text-muted">Puede arrastrar imagen aqui</p>
        {previewImage && (
          <img
            className="img-fluid mx-auto"
            src={previewImage}
            alt="Upload Preview"
          />
        )}
      </div>
    </div>
  );
};

function ModalComprobanteItem({ data, setActualizarData }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const inputMontoJustificado = useRef(null);
  const inputComentarioItem = useRef(null);

  useEffect(() => {
    if (data) {
      const monto = data.monto_justificado ?? data.monto_preaprobado;
      inputMontoJustificado.current.value = monto;
    }
  }, [data]);

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);

      const supportedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];

      if (file.type.startsWith('image/')) {
        resizeFile(file).then((uri) => {
          setFile(uri);
          setPreviewImage(fileURL);
        });
      } else if (supportedTypes.includes(file.type)) {
        setFile(file);
        setPreviewImage(null);
      } else {
        throw new Error('File type not supported');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = () => {
    if (inputMontoJustificado.current.value) {
      const { monto_justificado, monto_preaprobado, monto_total } = data;
      const inputMontoJustificadoValue = Number(
        inputMontoJustificado.current.value
      );
      const montoJustificado = monto_justificado ?? monto_preaprobado;

      data.monto_total =
        Number(monto_total) + (inputMontoJustificadoValue - montoJustificado);
      data.monto_justificado = inputMontoJustificadoValue;
      data.comentario_justificacion = inputComentarioItem.current.value;

      uploadComprobanteGasto(file, data).then((res) => {
        if (res.status === 200) {
          setActualizarData(true);
          inputComentarioItem.current.value = '';
          inputMontoJustificado.current.value = '';
          setFile(null);
          inputFile.current.value = '';
        }
      });
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const fileURL = URL.createObjectURL(file);

    const supportedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (file.type.startsWith('image/')) {
      resizeFile(file).then((uri) => {
        setFile(uri);
        setPreviewImage(fileURL);
      });
    } else if (supportedTypes.includes(file.type)) {
      setFile(file);
      setPreviewImage(null);
    } else {
      console.log('File type not supported');
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="modal fade"
      id="modalComprobanteItem"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalComprobanteItemLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalComprobanteItemLabel">
              Comprobante
            </h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="inputMontoJustificado">Monto justificado</label>
              <input
                type="number"
                className="form-control"
                id="inputMontoJustificado"
                ref={inputMontoJustificado}
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputComentarioItem">Comentario</label>
              <textarea
                className="form-control"
                id="inputComentarioItem"
                rows="3"
                ref={inputComentarioItem}
              ></textarea>
            </div>
            <FileInput
              inputRef={inputFile}
              handleFileChange={handleFileChange}
            />
            <ImageUploadPreview
              previewImage={previewImage}
              handleFileDrop={handleFileDrop}
              handleFileDragOver={handleFileDragOver}
            />
            <div className="alert alert-light-info">
              Permite el envio de PDF, e imagenes jpg, jpeg, png
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setFile(null);
                inputFile.current.value = '';
                inputComentarioItem.current.value = '';
                inputMontoJustificado.current.value = '';
              }}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUpload}
              data-bs-dismiss="modal"
            >
              Subir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalComprobanteItem;
