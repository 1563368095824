import { apiUrl } from '../config';

//  get all documentos de los proveedores
export const getDocumentosProveedores = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/documentos/proveedores')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// create documento de los proveedores
export const createDocumentoProveedor = async (documento, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/documentos/proveedores')}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(documento),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// delete documento de los proveedores
export const deleteDocumentoProveedor = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/documentos/proveedores')}/${id}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};
