import DetalleOvertimeAdm from './DetalleOvertimeAdm';
import useUser from '../../../hooks/useUser';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import { SpinerDownload } from '../../general/loader/SpinerLoader';
import { actualizarOvertime, getOvertime } from '../../../services/overtimes';
import ModalEditHoras from './ModalEditHoras';
import {
  AlertaConfirmComentario,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import ModalHorasAprobadas from './ModalHorasAprobadas';

function OvertimeDetalleAdm() {
  const { dataUserLogged, apiToken } = useUser();
  const { id } = useParams();
  const [overtime, setOvertime] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [updateData, setUpdateData] = useState({});
  const [horasAprobadas, setHorasAprobadas] = useState(0);

  useEffect(() => {
    getSolicitud(id, apiToken);
  }, [id, apiToken]);

  const getSolicitud = async (id, apiToken) => {
    const res = await getOvertime(id, apiToken);
    if (res && res.status === 200 && res.overtime) {
      setOvertime(res.overtime);
    }
    setIsLoading(false);
  };

  const handleActualizarOvt = async (estado, tituloModal) => {
    AlertaConfirmComentario({
      title: `¿${tituloModal}?`,
      text: `Se actualizará la solicitud N: ${overtime.correlativo}`,
      callback: async (comentario) => {
        setIsLoading(true);

        if (!updateData.hasOwnProperty('id')) {
          updateData.id = id;
        }

        if (!updateData.hasOwnProperty('estado')) {
          updateData.estado_id = estado;
        }

        // Vamos a agregar el comentario donde corresponda
        if (estado === 2) {
          if (!updateData.hasOwnProperty('horas_preaprobadas')) {
            updateData.horas_preaprobadas = overtime.horas_solicitadas;
          }

          if (!updateData.hasOwnProperty('comentario_preaprobacion')) {
            updateData.comentario_preaprobacion =
              comentario === '' ? null : comentario;
          }

          if (!updateData.hasOwnProperty('preaprueba_id')) {
            updateData.preaprueba_id = dataUserLogged.id;
          }
        } else if (estado === 4) {
          if (!updateData.hasOwnProperty('horas_aprobadas')) {
            if (horasAprobadas !== 0) {
              updateData.horas_aprobadas = horasAprobadas;
            } else {
              updateData.horas_aprobadas = overtime.horas_justificadas;
            }
          }
          if (!updateData.hasOwnProperty('aprobador_id')) {
            updateData.aprueba_id = dataUserLogged.id;
          }

          if (!updateData.hasOwnProperty('comentario_aprobacion')) {
            updateData.comentario_aprobacion =
              comentario === '' ? null : comentario;
          }
        } else if (estado === 5) {
          if (!updateData.hasOwnProperty('consolidador_id')) {
            updateData.consolida_id = dataUserLogged.id;
          }

          if (!updateData.hasOwnProperty('comentario_consolidacion')) {
            updateData.comentario_consolidacion =
              comentario === '' ? null : comentario;
          }
        } else if (estado === 6) {
          if (!updateData.hasOwnProperty('consolidador_id')) {
            updateData.anula_id = dataUserLogged.id;
          }

          if (!updateData.hasOwnProperty('comentario_anulacion')) {
            updateData.comentario_anulacion =
              comentario === '' ? null : comentario;
          }
        }

        try {
          const res = await actualizarOvertime(updateData, apiToken);
          if (res && res.status === 200) {
            AlertaSuccess({
              title: 'Solicitud actualizada',
              text: 'La solicitud se actualizó correctamente',
            });
            getSolicitud(id, apiToken);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al actualizar',
            text: 'Ocurrió un error al actualizar la solicitud',
          });
        }
        setIsLoading(false);
      },
    });
  };

  // const handleActualizarOvt = async (estado) => {
  //   AlertaConfirm({
  //     title: '¿Confirmar cambios?',
  //     text: `Se actualizará la solicitud N: ${overtime.correlativo}`,
  //     callback: async () => {
  //       setIsLoading(true);
  //       // const updateData = {};

  //       if (!updateData.hasOwnProperty('id')) {
  //         updateData.id = id;
  //       }

  //       if (!updateData.hasOwnProperty('estado')) {
  //         updateData.estado_id = estado;
  //       }

  //       if (estado === 2) {
  //         if (!updateData.hasOwnProperty('horas_preaprobadas')) {
  //           updateData.horas_preaprobadas = overtime.horas_solicitadas;
  //         }

  //         if (!updateData.hasOwnProperty('comentario_preaprobacion')) {
  //           updateData.comentario_preaprobacion = null;
  //         }

  //         if (!updateData.hasOwnProperty('preaprueba_id')) {
  //           updateData.preaprueba_id = dataUserLogged.id;
  //         }
  //       } else if (estado === 4) {
  //         if (!updateData.hasOwnProperty('horas_aprobadas')) {
  //           if (horasAprobadas !== 0) {
  //             updateData.horas_aprobadas = horasAprobadas;
  //           } else {
  //             updateData.horas_aprobadas = overtime.horas_justificadas;
  //           }
  //           // updateData.horas_aprobadas = overtime.horas_justificadas;
  //         }
  //         if (!updateData.hasOwnProperty('aprobador_id')) {
  //           updateData.aprueba_id = dataUserLogged.id;
  //         }
  //       } else if (estado === 5) {
  //         if (!updateData.hasOwnProperty('consolidador_id')) {
  //           updateData.consolida_id = dataUserLogged.id;
  //         }
  //       } else if (estado === 6) {
  //         if (!updateData.hasOwnProperty('consolidador_id')) {
  //           updateData.anula_id = dataUserLogged.id;
  //         }
  //       }

  //       try {
  //         const res = await actualizarOvertime(updateData, apiToken);
  //         if (res && res.status === 200) {
  //           AlertaSuccess({
  //             title: 'Solicitud actualizada',
  //             text: 'La solicitud se actualizó correctamente',
  //           });
  //           getSolicitud(id, apiToken);
  //         }
  //       } catch (error) {
  //         AlertaError({
  //           title: 'Error al actualizar',
  //           text: 'Ocurrió un error al actualizar la solicitud',
  //         });
  //       }
  //       setIsLoading(false);
  //     },
  //   });
  // };

  return (
    <>
      {isLoading ? <SpinerDownload /> : null}
      <section className="row">
        <div className="col-12 col-lg-9">
          {/*  Contenido Principal  */}
          <div className="row">
            <DetalleOvertimeAdm data={overtime} updHora={updateData} />
          </div>
        </div>
        {/*  Tercera columna  */}
        <div className="col-12 col-lg-3">
          {/*  Card info usuario logueado  */}
          <CardUserLogged />

          {/*  Card Estado Solicitud  */}
          <div className="card">
            <div className="card-header">
              <h4>Estado Solicitud</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <h6>
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <span className="badge bg-light-info fs-5">
                        {overtime.estado}
                      </span>
                      <p className="mt-3">{overtime.estado_descripcion}</p>
                    </>
                  )}
                </h6>
              </div>
            </div>
          </div>

          {/*  Card de accion  */}
          <div className="card">
            <div className="card-header">
              <h4>Acciones</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <h6>
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      {overtime.estado === 'Generada' ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              handleActualizarOvt(2, 'Preaprobar OVT')
                            }
                          >
                            Preaprobar
                          </button>
                          <button
                            className="btn btn-warning btn-sm mx-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modalEditarHoras"
                          >
                            Cambiar horas
                          </button>
                        </>
                      ) : null}
                      {overtime.estado === 'Justificada' ? (
                        <>
                          <button
                            className="btn btn-success btn-sm"
                            onClick={() =>
                              handleActualizarOvt(4, 'Aprobar OVT')
                            }
                          >
                            Aprobar Overtime
                          </button>
                          <button
                            className="btn btn-secondary btn-sm mx-1"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modalHorasAprobadas"
                          >
                            Cambiar Horas Aprobadas
                          </button>
                        </>
                      ) : null}
                      {overtime.estado === 'Aprobada' ? (
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            handleActualizarOvt(5, 'Consolidar OVT')
                          }
                        >
                          Consolidar Overtime
                        </button>
                      ) : null}
                      {(dataUserLogged.rol === 'Administrador' &&
                        overtime.estado_id < 5) ||
                      overtime.estado_id > 6 ? (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleActualizarOvt(6, 'Anular OVT')}
                        >
                          Anular
                        </button>
                      ) : null}
                      {Number(overtime.estado_id) === 6 ? (
                        <button className="btn btn-primary" disabled>
                          Reactivar solicitud
                        </button>
                      ) : null}
                    </div>
                  )}
                </h6>
              </div>
            </div>
          </div>
        </div>
        {overtime ? (
          <ModalEditHoras
            hrSolicitada={overtime.horas_solicitadas}
            updateData={setUpdateData}
          />
        ) : null}
        {overtime ? <ModalHorasAprobadas hrChange={setHorasAprobadas} /> : null}
      </section>
    </>
  );
}

export default OvertimeDetalleAdm;
