import { urlS3Overtime } from '../../../config';

function ModalComprobanteOvt({ ticket, handleModalClose }) {
  const isImage = (file) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    return imageExtensions.some((ext) => file.toLowerCase().endsWith(ext));
  };

  const isPdf = (file) => {
    return file.toLowerCase().endsWith('pdf');
  };

  const renderFile = () => {
    if (!ticket) return null;
    const file = ticket.comprobante;

    if (isImage(file)) {
      return (
        <img src={`${urlS3Overtime}${file}`} alt="" className="img-fluid" />
      );
    } else if (isPdf(file)) {
      return (
        <embed
          src={`${urlS3Overtime}${file}`}
          type="application/pdf"
          width="100%"
          height="650px"
        />
      );
    } else {
      return <p>Formato de archivo no soportado</p>;
    }
  };

  return (
    <div
      className="modal fade modal-lg"
      id="modalVerComprobante"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {!ticket
                ? null
                : `Comprobante Overtime Ticket/Servicio: ${ticket.nomenclatura}${ticket.ticket}`}
            </h5>
          </div>
          <div className="modal-body text-center">
            {/* imagen */}
            {renderFile()}
          </div>

          <div className="modal-footer d-flex justify-content-between">
            <div>
              {!ticket
                ? null
                : `Horas justificadas: ${ticket.horas_ticket.slice(
                    0,
                    -3
                  )} hrs.`}
            </div>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleModalClose}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalComprobanteOvt;
