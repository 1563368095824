//  format date whit isNan validation
const NO_DECLARED = 'No declarada';
const DATE_REGEX = /^\d{4}-\d{2}-\d{2}/;

const addLeadingZero = (n) => (n < 10 ? `0${n}` : n);

export const formatDate = (date) => {
  if (!date) {
    return NO_DECLARED;
  }
  let newDate = new Date(date);
  if (typeof date === 'string') {
    newDate = new Date(date.match(DATE_REGEX)[0]); // obtengo solo la fecha de la cadena
  }
  if (isNaN(newDate.getTime())) {
    return NO_DECLARED;
  }
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
  const yyyy = newDate.getFullYear();
  let mm = addLeadingZero(newDate.getMonth() + 1);
  let dd = addLeadingZero(newDate.getDate());
  return `${dd}-${mm}-${yyyy}`;
};

// formatear fecha y hora whit isNan validation
export const formatDateTime = (date) => {
  if (date === null) {
    return NO_DECLARED;
  }
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return NO_DECLARED;
  }
  const month = addLeadingZero(d.getMonth() + 1);
  const day = addLeadingZero(d.getDate());
  const year = d.getFullYear();
  const hour = addLeadingZero(d.getHours());
  const minute = addLeadingZero(d.getMinutes());

  return `${day}-${month}-${year} a las ${hour}:${minute} hrs`;
};

// calcural edad whit isNaN validation
export const calcularEdad = (date) => {
  if (!isNaN(date) || date === null || date === '0000-00-00') {
    return '';
  }
  const hoy = new Date();
  const cumpleanos = new Date(date);
  cumpleanos.setMinutes(
    cumpleanos.getMinutes() + cumpleanos.getTimezoneOffset()
  );
  let edad = hoy.getFullYear() - cumpleanos.getFullYear();
  const m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return `( ${edad} años )`;
};
