import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getEstadisticasGastosProveedor } from '../../../services/gastosAdicionales';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import TablaGastosMes from './TablaGastosMes';

function Gastos() {
  const [estadisticas, setEstadisticas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { dataUserLogged, apiToken } = useUser();
  const perfil = useLocation().pathname.split('/')[1];

  useEffect(() => {
    getEstadisticas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEstadisticas = async () => {
    const res = await getEstadisticasGastosProveedor(
      dataUserLogged.rut,
      apiToken
    );
    if (res && res.status === 200 && res.data) {
      setEstadisticas(res.data);
      setIsLoading(false);
    }
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Sin Justificar Gastos
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-vencidos">
                      {isLoading ? null : estadisticas.total_preaprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Solicitudes Preaprobadas
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-por-vencer">
                      {isLoading ? null : estadisticas.total_preaprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Solicitudes Aprobadas
                    </h6>
                    <h6
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      {isLoading ? null : estadisticas.total_aprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Aprobado Del Mes
                    </h6>
                    <h6 className="font-extrabold mb-0">
                      $
                      {isLoading
                        ? null
                        : estadisticas.total_monto_aprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tablas */}
        <TablaGastosMes />
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  En Preaprobacion  */}
        <div className="card">
  <div className="card-body px-1">
    <h6 className='text-center'>ÚLTIMAS SOLICITUDES REALIZADAS</h6>
    <div className="list-group list-group-flush">
      {isLoading 
        ? null 
        : estadisticas.ultimos_gastos.map((gasto) => (
            <Link 
              key={gasto.id} 
              to={`/${perfil}/gastos/${gasto.id}`} 
              className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
            >
              <div className="name col-8">
                <h6 className="mb-1">N:{gasto.correlativo}</h6>
                <p className="text-muted mb-0"><small>{`${gasto.recurso_nombre} ${gasto.recurso_apellido}`}</small></p>
                <small className="text-muted">{gasto.created_at} hrs</small>
              </div>
              <div className="col-4 ms-3">
                <h6 className="text-success mb-0">
                  $ {gasto.monto_total}
                </h6>
                <small className="text-info">{gasto.estado}</small>
              </div>
            </Link>
          ))
      }
    </div>
  </div>
</div>


        {/*  Card Preaprobados  */}
        <div className="card">
          <div className="card-body px-1">
            <h6 className='text-center'>ÚLTIMOS PREAPROBADOS</h6>
            <div className="list-group list-group-flush">
              {isLoading 
                ? null 
                : estadisticas.ultimos_gastos_preaprobados.map((gasto) => (
                    <Link 
                      key={gasto.id} 
                      to={`/${perfil}/gastos/${gasto.id}`} 
                      className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
                    >
                      <div className="name col-8">
                        <h6 className="mb-1">N:{gasto.correlativo}</h6>
                        <p className="text-muted mb-0"><small>{`${gasto.recurso_nombre} ${gasto.recurso_apellido}`}</small></p>
                        <small className="text-muted">{gasto.fecha_preaprobacion} hrs</small>
                      </div>
                      <div className="col-4 ms-3">
                        <h6 className="text-success mb-0">
                          $ {gasto.monto_total}
                        </h6>
                      </div>
                    </Link>
                  ))
              }
            </div>
          </div>
        </div>


        {/*  Card Aprobados  */}
        <div className="card">
  <div className="card-body px-1">
    <h6 className='text-center'>ÚLTIMOS APROBADOS</h6>
    <div className="list-group list-group-flush">
      {isLoading 
        ? null 
        : estadisticas.ultimos_gastos_aprobados.map((gasto) => (
            <Link 
              key={gasto.id} 
              to={`/${perfil}/gastos/${gasto.id}`} 
              className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
            >
              <div className="name col-8">
                <h6 className="mb-1">N:{gasto.correlativo}</h6>
                <p className="text-muted mb-0"><small>{`${gasto.recurso_nombre} ${gasto.recurso_apellido}`}</small></p>
                <small className="text-muted">{gasto.fecha_aprobacion} hrs</small>
              </div>
              <div className="col-4 ms-3">
                <h6 className="text-success mb-0">
                  $ {gasto.monto_total}
                </h6>
              </div>
            </Link>
          ))
      }
    </div>
  </div>
</div>

      </div>
    </section>
  );
}

export default Gastos;
