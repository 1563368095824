import Chart from 'react-apexcharts';

function GastosMontosProveedoresMes({ datos }) {
  let series = [];
  let options = {};

  if (datos) {
    series = datos.map((item) => Number(item.total));

    const colors = datos.map((item) => {
      switch (item.nombre) {
        case 'Eikorp Ltda.':
          return '#FF8000'; // naranja
        case 'HyO Ltda.':
          return '#01DF3A'; // verde
        case 'SSI S.A.':
          return '#00BFFF'; // celeste
        case 'Stefanini':
          return '#0B0B61'; // azul
        default:
          return '#D3D3D3'; // gris como color por defecto
      }
    });

    options = {
      labels: datos.map((item) => `${item.nombre}`),

      series: series,
      colors: colors,

      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const nombre = opts.w.config.labels[opts.seriesIndex];
          const total = opts.w.globals.series[opts.seriesIndex];
          return `${nombre}: $${total}`;
        },
      },
    };
  }

  return <Chart options={options} series={series} type="pie" width={450} />;
}

export default GastosMontosProveedoresMes;
