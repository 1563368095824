import { urlS3GastosAdicionales } from '../../../../config';

function ModalVerComprobante({ imagen, handleModalClose }) {
  const isImage = (file) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    return imageExtensions.some((ext) => file.toLowerCase().endsWith(ext));
  };

  const isPdf = (file) => {
    return file.toLowerCase().endsWith('pdf');
  };

  const renderFile = () => {
    if (!imagen) return null;
    const file = imagen.imagen;

    if (isImage(file)) {
      return (
        <img
          src={`${urlS3GastosAdicionales}${file}`}
          alt=""
          className="img-fluid"
        />
      );
    } else if (isPdf(file)) {
      return (
        <embed
          src={`${urlS3GastosAdicionales}${file}`}
          type="application/pdf"
          width="100%"
          height="650px"
        />
      );
    } else {
      return <p>Formato de archivo no soportado</p>;
    }
  };

  return (
    <div
      className="modal fade modal-lg"
      id="modalVerComprobante"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {!imagen ? null : `Comprobante Gasto Ticket: ${imagen.ticket}`}
            </h5>
          </div>
          <div className="modal-body text-center">
            {/* imagen */}
            {renderFile()}
          </div>

          <div className="modal-footer d-flex justify-content-between">
            <div>{!imagen ? null : `Concepto: ${imagen.item}`}</div>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleModalClose}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalVerComprobante;
