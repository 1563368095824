import { getOvertimesPendientesAprobacion } from '../../../services/overtimes';
import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { Link } from 'react-router-dom';
import CustomTable from '../../general/CustomTable';
import { formatDate } from '../../../hooks/useDates';

const columns = [
  {
    name: 'Sol. N°',
    selector: (row) => row.correlativo,
    sortable: true,
    clave: 'correlativo',
    width: '90px',
  },
  {
    name: 'Fecha',
    selector: (row) => formatDate(row.fecha_ovt),
    sortable: true,
    clave: 'fecha_ovt',
  },
  {
    name: 'Proveedor',
    selector: (row) => row.proveedor_nombre,
    sortable: true,
    clave: 'proveedor_nombre',
  },
  {
    name: 'Recurso',
    selector: (row) => (row.nombre ? row.nombre : row.desviculado_nombre),
    sortable: true,
    clave: 'nombre',
  },
  {
    name: 'Apellido',
    selector: (row) =>
      row.apellido ? row.apellido : row.desvinculado_apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'Hrs Justificadas',
    selector: (row) => row.horas_justificadas.slice(0, -3),
    sortable: true,
    clave: 'horas_justificadas',
  },
  {
    name: 'Estado',
    selector: (row) => row.estado,
    sortable: true,
    clave: 'estado',
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link
        to={`/administracion/overtime/${row.id}`}
        className="btn btn-outline-primary btn-sm"
      >
        <i className="bi bi-eye"></i>
      </Link>
    ),
    width: '5%',
    right: true,
  },
];

function TablaOvtPendAprob() {
  const { apiToken } = useUser();
  const [overtimes, setOvertimes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOvertimesPendientesAprobacion(apiToken);
      setOvertimes(data.overtimes);
    };
    setIsLoading(false);
    fetchData();
  }, [apiToken]);

  const excelName = `Gastos adicionales pendientes de aprobación`;

  return (
    <div className="row" id="table-hover-row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              OVERTIMES PENDIENTES DE APROBACIÓN FINAL
            </h4>
          </div>
          <div className="card-body">
            <CustomTable
              columns={columns}
              data={overtimes}
              isLoading={isLoading}
              excelName={excelName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablaOvtPendAprob;
