import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlS3 } from '../../../config';
import useUser from '../../../hooks/useUser';
import '../../../assets/css/main/timbre-rojo.css';
import {
  formatDate,
  formatDateTime,
  calcularEdad,
} from '../../../hooks/useDates';
import {
  getRecursoDesviculado,
  updateRecontratable,
} from '../../../services/recursos';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import CardProfileProgress from '../../general/cardsInfo/CardProfileProgress';
import { SpinerDownload } from '../loader/SpinerLoader';
import { AlertaConfirm, AlertaError, AlertaSuccess } from '../Alerts';

const estados = ['Inactivo', 'Activo']; // estado del recurso
const tipoContratos = ['', 'Indefinido', 'A Plazo']; // tipo de contrato del recurso

function DetalleRecursoDesvinculado() {
  const { apiToken, dataUserLogged, role } = useUser();
  const { id } = useParams();
  const [recurso, setRecurso] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [recontratable, setRecontratable] = useState(null);

  useEffect(() => {
    getRecursoDesviculado(id, apiToken).then((data) => {
      if (data && data.status === 200 && data.recurso) {
        setRecurso(data.recurso);
        setRecontratable(Number(data.recurso.recontratable));
        setIsLoading(false);
      }
    });
  }, [id, apiToken]);

  const handleRecontratable = (e) => {
    setRecontratable(Number(e.target.value));
  };

  const handleUpdateRecontratable = async () => {
    AlertaConfirm({
      title: '¿Recontratable?',
      text: `Se actualizara el recurso: ${recurso.nombre} ${recurso.apellido}`,
      callback: async () => {
        setIsLoading(true);
        const actualizar = {
          id: recurso.id,
          recontratable,
          usuario_id: dataUserLogged.id,
        };
        const res = await updateRecontratable(actualizar, apiToken);
        if (res && res.status === 200) {
          AlertaSuccess({
            title: 'Recurso Actualizado',
            success: `Se actualizo el recurso: ${recurso.nombre} ${recurso.apellido}`,
            callback: async () => {
              getRecursoDesviculado(id, apiToken).then((data) => {
                if (data && data.status === 200 && data.recurso) {
                  setRecurso(data.recurso);
                  setRecontratable(Number(data.recurso.recontratable));
                  setIsLoading(false);
                }
              });
            },
          });
        } else {
          AlertaError({
            title: 'Error al actualizar recurso',
            error: 'Ocurrio un error al actualizar el recurso',
          });
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {isLoading ? <SpinerDownload /> : null}
        <div className="card">
          <div className="despedido-badge">Desvinculado</div>
          <div className="card-content">
            <div className="card-body">
              <h4 className="card-title text-uppercase">{`${recurso.nombre} ${recurso.apellido}`}</h4>
              <div className="float-end">
                {recurso.foto && (
                  <img
                    src={`${urlS3}${recurso.foto}`}
                    alt={`Foto ${recurso.nombre}`}
                    height="350"
                    width="350"
                  />
                )}
              </div>
              <p className="card-text">
                <span className="text-muted">Nombre: </span>
                <span className="font-bold text-uppercase">
                  {recurso.nombre}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Apellido: </span>
                <span className="font-bold text-uppercase">
                  {recurso.apellido}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Rut: </span>
                <span className="font-bold text-uppercase">{recurso.rut}</span>
              </p>
              <p className="card-text">
                <span className="text-muted">Fecha de Nacimiento: </span>
                <span className="font-bold text-uppercase">
                  {`${formatDate(recurso.fecha_nacimiento)} ${calcularEdad(
                    recurso.fecha_nacimiento
                  )}`}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Dirección: </span>
                <span className="font-bold text-uppercase">
                  {recurso.direccion}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Comuna: </span>
                <span className="font-bold text-uppercase">
                  {recurso.comuna}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Región: </span>
                <span className="font-bold text-uppercase">
                  {recurso.region}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Teléfono: </span>
                <span className="font-bold">{recurso.telefono}</span>
              </p>
              <p className="card-text">
                <span className="text-muted">Email: </span>
                <span className="font-bold">{recurso.email}</span>
              </p>
              <p className="card-text">
                <span className="text-muted">Estado: </span>
                <span className="font-bold text-uppercase">
                  {estados[recurso.estado]}
                </span>
              </p>
              {/* Divider */}
              <div className="divider divider-left mt-5">
                <div className="divider-text">Información laboral</div>
              </div>

              <p className="card-text">
                <span className="text-muted">Proveedor: </span>
                <span className="font-bold text-uppercase">
                  {recurso.proveedor}
                </span>
              </p>
              <div className="d-flex justify-content-between">
                <p className="card-text">
                  <span className="text-muted">Fecha de Ingreso: </span>
                  <span className="font-bold text-uppercase">
                    {formatDate(recurso.fecha_ingreso)}
                  </span>
                </p>
                <p className="card-text">
                  <span className="text-muted">Tipo de Contrato: </span>
                  <span className="font-bold text-uppercase">
                    {tipoContratos[recurso.tipo_contrato]}
                  </span>
                </p>
                <p className="card-tex">
                  <span className="text-muted">Fecha Termino Plazo: </span>
                  <span className="font-bold text-uppercase">
                    {formatDate(recurso.fecha_termino)}
                  </span>
                </p>
              </div>

              <p className="card-text">
                <span className="text-muted">Cargo: </span>
                <span className="font-bold text-uppercase">
                  {recurso.cargo}
                </span>
              </p>

              {/* Divider */}
              <div className="divider divider-left mt-5">
                <div className="divider-text">Información Desvinculación</div>
              </div>

              <div className="d-flex justify-content-between">
                <p className="card-text">
                  <span className="text-muted">Fecha desvinculación: </span>
                  <span className="font-bold text-uppercase">
                    {formatDate(recurso.fecha_baja)}
                  </span>
                </p>
                <p className="card-text">
                  <span className="text-muted">Motivo: </span>
                  {role === 'Spoc' ? (
                    <i
                      className="bi bi-lock-fill"
                      style={{ fontSize: '1.5rem' }}
                    ></i>
                  ) : (
                    <span className="font-bold text-uppercase">
                      {recurso.motivo_desvinculacion || 'Sin información'}
                    </span>
                  )}
                </p>
                <p className="card-text">
                  <span className="text-muted">Recontratable: </span>
                  {role === 'Spoc' ? (
                    <i
                      className="bi bi-lock-fill"
                      style={{ fontSize: '1.5rem' }}
                    ></i>
                  ) : (
                    <span className="font-bold text-uppercase">
                      {Number(recurso.recontratable) ? 'Si' : 'No'}
                    </span>
                  )}
                </p>
              </div>
              <p className="card-text">
                <span className="text-muted">Comentario desvinculación: </span>
                {role === 'Spoc' ? (
                  <i
                    className="bi bi-lock-fill"
                    style={{ fontSize: '1.5rem' }}
                  ></i>
                ) : (
                  <span className="font-bold text-uppercase">
                    {recurso.comentario_desvinculacion || 'Sin comentario'}
                  </span>
                )}
              </p>

              <small className="text-muted float-end">
                Ultima modificación{' '}
                {formatDateTime(recurso.updated_at) ||
                  formatDateTime(recurso.created_at)}
              </small>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />
        {/* card avance perfil */}
        <CardProfileProgress recurso={recurso} />
        {/*  Card edicion  */}
        {dataUserLogged.rol === 'Administrador' && (
          <div className="card">
            <div className="card-header">
              <h4>Recurso Recontratable</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="recontratable"
                      id="recontratable-si"
                      value={1}
                      checked={recontratable === 1}
                      onChange={handleRecontratable}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="recontratable-si"
                    >
                      SI
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="recontratable"
                      id="recontratable-no"
                      value={0}
                      checked={recontratable === 0}
                      onChange={handleRecontratable}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="recontratable-no"
                    >
                      NO
                    </label>
                  </div>
                </div>
                <div className="mt-3">
                  <span>Se modificara a nombre de: {dataUserLogged.name}</span>
                </div>
              </div>
            </div>
            <div className="card-footer text-center">
              <button
                type="button"
                className="btn btn-icon btn-outline-primary"
                onClick={handleUpdateRecontratable}
              >
                <i className="bi bi-cloud-arrow-up"></i> Guardar Cambios
              </button>
            </div>
          </div>
        )}
      </div>
      )
    </section>
  );
}

export default DetalleRecursoDesvinculado;
