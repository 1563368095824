import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar({ menu }) {
  return (
    <div id="sidebar" className="active">
      <div className="sidebar-wrapper active shadow">
        <div className="sidebar-header position-relative">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo">
              <Link to="/">GePro</Link>
            </div>
            {/* Boton Modo Dark */}
            <div className="theme-toggle d-flex gap-2 align-items-center">
              <div>
                <i
                  className="bi bi-brightness-high"
                  style={{ fontSize: 1.2 + 'rem' }}
                ></i>
              </div>

              <div className="form-check form-switch fs-6 mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="toggle-dark"
                />
                <label className="form-check-label"></label>
              </div>
              <div>
                <i
                  className="bi bi-moon-stars"
                  style={{ fontSize: 1 + 'rem' }}
                ></i>
              </div>
            </div>
            {/* Botoon toggle dispositivo movil */}
            <div className="sidebar-toggler x">
              <Link className="sidebar-hide d-xl-none d-block">
                <i className="bi bi-x bi-middle"></i>
              </Link>
            </div>
          </div>
        </div>
        {/* Sidebar Menu parameter*/}
        {menu}
      </div>
    </div>
  );
}

export default Sidebar;
