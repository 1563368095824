import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function Login({ onLogin }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { isLogged, isLoginLoading, hasLoginError, login } = useUser();

  useEffect(() => {
    if (isLogged) {
      window.location.reload(); // reload page, capturara el estado PublicRoute
    }
  }, [isLogged, navigate, onLogin]);

  // funcion al hacer submit el form
  const handleSubmit = (e) => {
    e.preventDefault();
    // llama al hook useUser para hacer login
    login({ username, password });
  };

  return (
    <>
      <h3 className="auth-title">Log in.</h3>
      <p className="auth-subtitle mb-5">
        Debe loguearse para acceder a la aplicación
      </p>

      {isLoginLoading && <strong>Validando usuario...</strong>}
      {hasLoginError && (
        <div className="alert alert-light-danger color-danger">
          <i className="bi bi-exclamation-circle"></i> Usuario o contraseña
          incorrectos.
        </div>
      )}
      {!isLoginLoading && (
        <form onSubmit={handleSubmit}>
          <div className="form-group position-relative has-icon-left mb-4">
            <input
              type="email"
              className="form-control form-control-xl"
              placeholder="Usuario"
              name="email"
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="form-control-icon">
              <i className="bi bi-person"></i>
            </div>
          </div>
          <div className="form-group position-relative has-icon-left mb-4">
            <input
              type="password"
              className="form-control form-control-xl"
              placeholder="Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="form-control-icon">
              <i className="bi bi-shield-lock"></i>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-block btn-lg shadow-lg mt-5"
          >
            Ingresar
          </button>
        </form>
      )}

      {/* <div className="text-center mt-5 text-lg fs-4">
        <p>
          <Link to="recuperar" className="font-bold">
            Olvido el password?
          </Link>
        </p>
      </div> */}
    </>
  );
}

export default Login;
