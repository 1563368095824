import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  getTiposGastos,
  deleteTipoGasto,
  createTipoGasto,
} from '../../../services/tiposGastos';

import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const initialState = {
  id: '',
  tipo: '',
  descripcion: '',
};

function TiposGastos() {
  const { apiToken } = useUser();
  const [tiposGastos, setTiposGastos] = useState([]);
  const [tipoGasto, setTipoGasto] = useState({ ...initialState });
  const [isLoading, setIsLoading] = useState(false);

  // get data
  const getData = (apiToken) => {
    setIsLoading(true);
    getTiposGastos(apiToken).then((data) => {
      setTiposGastos(data.tipos_gastos || data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  // handleChange
  const handleChange = (e) => {
    setTipoGasto({
      ...tipoGasto,
      [e.target.name]: e.target.value,
    });
  };

  // tipo gasto edit
  const handleProyectoClicked = (tipoGasto) => {
    setTipoGasto(tipoGasto);
  };

  // delete tipo gasto
  const handleDeleteProyecto = (tipoGasto) => {
    AlertaConfirm({
      title: '¿Eliminar el concepto de gasto?',
      text: `Se eliminara ${tipoGasto.tipo}`,
      callback: () => {
        deleteTipoGasto(tipoGasto.id, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({
              message: 'Concepto de gasto eliminado correctamente',
            });
            getData(apiToken);
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (tipoGasto.tipo === '') {
      AlertaError({ error: 'Debe ingresar un concepto de gasto' });
      return;
    }
    AlertaConfirm({
      title: '¿Crear Concepto de Gasto?',
      text: `Se creara ${tipoGasto.tipo}`,
      callback: () => {
        createTipoGasto(tipoGasto, apiToken).then((data) => {
          if (data.status === 200) {
            if (tipoGasto.id === '') {
              setTiposGastos([...tiposGastos, data.tipo_gasto]);
            } else {
              const tiposGastosUpdate = tiposGastos.map((tipoGasto) => {
                if (tipoGasto.id === data.tipo_gasto.id) {
                  return data.tipo_gasto;
                }
                return tipoGasto;
              });
              setTiposGastos(tiposGastosUpdate);
            }
            AlertaSuccess({
              success: data.message,
              callback: () => {
                setTipoGasto({ ...initialState });
                document.getElementById('form-create-tipo').reset();
              },
            });
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>Items de Gastos Adicionales</h3>
          {/* table */}
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="col-1">Id</th>
                <th className="col-2">Tipo de gasto</th>
                <th>Descripción</th>
                <th className="col-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="3">
                    Obteniendo Información...
                    <SpinerDownload />
                  </td>
                </tr>
              ) : tiposGastos['message'] ? (
                <tr>
                  <td colSpan="6">{tiposGastos['message']}</td>
                </tr>
              ) : (
                tiposGastos.map((tipoGasto) => (
                  <tr key={tipoGasto.id}>
                    <td>{tipoGasto.id}</td>
                    <td>{tipoGasto.tipo}</td>
                    <td>{tipoGasto.descripcion}</td>
                    <td>
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          className="btn btn-primary btn-sm mr-2"
                          onClick={() => handleProyectoClicked(tipoGasto)}
                        >
                          Editar
                        </button>

                        <button
                          className="btn btn-danger btn-sm ms-1"
                          onClick={() => handleDeleteProyecto(tipoGasto)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Agregar Item de gasto</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                id="form-create-tipo"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  {/* Tipo */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="tipo">Concepto</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Conpepto"
                        name="tipo"
                        value={tipoGasto.tipo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Descripcion */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="descripcion">Descripción</label>
                      <textarea
                        className="form-control"
                        placeholder="Descripción"
                        name="descripcion"
                        value={tipoGasto.descripcion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                      onClick={() => setTipoGasto({ ...initialState })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TiposGastos;
