import React from 'react';

function CardInfoInasistencia() {
  return (
    <div className="card">
      <div className="card-header">
        <h4>Inasistencia Observada Hoy</h4>
      </div>
      <div className="card-body">
        <h6>No disponible</h6>
        <div id="chart-visitors-profile"></div>
      </div>
    </div>
  );
}

export default CardInfoInasistencia;
