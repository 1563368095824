import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { getRecursos } from '../../../services/recursos';

function RecursosSelect({ onSelect, onReset, selectRef }) {
  // obtengo apiToken y dataUserLogged del contexto usando el hook personalizado
  const { apiToken, dataUserLogged } = useUser();
  const [recursos, setRecursos] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  // uso el hook useEffect para llamar a getRecursosByProveedor y establecer el estado recursos con los datos devueltos
  useEffect(() => {
    getRecursos(apiToken).then((data) => {
      setRecursos(data.recursos);
    });
  }, [apiToken, dataUserLogged.rut]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (onSelect) {
      const selectedRecurso = recursos.find(
        (recurso) => recurso.rut === event.target.value
      );
      onSelect(selectedRecurso);
    }
  };

  const handleFormReset = () => {
    setSelectedOption('');
    if (onReset) {
      onReset();
    }
  };

  return (
    <div className="col-md-12 col-12">
      <div className="form-group mandatory">
        <label htmlFor="input-recurso" className="form-label">
          Recurso
        </label>
        <select
          value={selectedOption}
          onChange={handleOptionChange}
          onReset={handleFormReset}
          className="form-select"
          id="input-recurso"
          ref={selectRef}
        >
          <option value={''}>Seleccionar recurso</option>

          {recursos.map((recurso) => (
            <option
              key={recurso.rut}
              value={recurso.rut}
              className="d-flex justify-content-between"
            >
              {`${recurso.nombre} ${recurso.apellido}`}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default RecursosSelect;
