import Chart from 'react-apexcharts';

function RecursosRegiones({ recursosRegion }) {
  const regiones = [
    { id: 15, nombre: 'Arica Parinacota' },
    { id: 1, nombre: 'Tarapacá' },
    { id: 2, nombre: 'Antofagasta' },
    { id: 3, nombre: 'Atacama' },
    { id: 4, nombre: 'Coquimbo' },
    { id: 5, nombre: 'Valparaíso' },
    { id: 13, nombre: 'Metropolitana' },
    { id: 6, nombre: "O'Higgins" },
    { id: 7, nombre: 'Maule' },
    { id: 16, nombre: 'Ñuble' },
    { id: 8, nombre: 'Biobío' },
    { id: 9, nombre: 'Araucanía' },
    { id: 14, nombre: 'Los Ríos' },
    { id: 10, nombre: 'Los Lagos' },
    { id: 11, nombre: 'Aysén' },
    { id: 12, nombre: 'Magallanes' },
  ];

  const recursos = regiones.map((region) => {
    const total = recursosRegion.reduce((acc, cur) => {
      return cur[`r${region.id}`] ? acc + parseInt(cur[`r${region.id}`]) : acc;
    }, 0);
    return { ...region, total };
  });

  return (
    <>
      <Chart
        options={{
          chart: {
            id: 'recursos-regiones',
          },
          xaxis: {
            title: {
              text: 'REGIONES',
            },
            categories: regiones.map((region) => region.nombre),
          },
          yaxis: {
            max: recursos.find((region) => region.id === 13).total,
            title: {
              text: 'CANTIDAD DE RECURSOS',
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['#BDBDBD'],
            },
          },
        }}
        series={[
          {
            name: 'Recursos',
            data: recursos.map((region) => region.total),
          },
        ]}
        type="bar"
        height={350}
      />
    </>
  );
}

export default RecursosRegiones;
