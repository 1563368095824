import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import DetalleProveedor from './DetalleProveedor';
import {
  getGastoAdicionalById,
  JustificarGastoAdicional,
} from '../../../services/gastosAdicionales';
import useUser from '../../../hooks/useUser';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

function GastoDetalleProveedor() {
  const { dataUserLogged, apiToken } = useUser();
  const { id } = useParams();
  const [gastoAdicional, setGastoAdicional] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [actualizarData, setActualizarData] = useState(false);

  useEffect(() => {
    getSolicitud(id, apiToken, dataUserLogged.rut);
  }, [id, apiToken, dataUserLogged.rut]);

  useEffect(() => {
    if (actualizarData) {
      setIsLoading(true);
      getSolicitud(id, apiToken, dataUserLogged.rut);
      setActualizarData(false);
    }
  }, [actualizarData, id, apiToken, dataUserLogged.rut]);

  const getSolicitud = async (id, apiToken, rut) => {
    const res = await getGastoAdicionalById(id, apiToken, rut);
    if (res && res.status === 200 && res.data) {
      setGastoAdicional(res.data);
      setIsLoading(false);
    }
  };

  const handleJustificarGastos = async () => {
    AlertaConfirm({
      title: '¿Justificar Gastos?',
      text: `Se justificaran los gastos de la solicutd: ${gastoAdicional.correlativo}`,
      callback: async () => {
        setIsLoading(true);

        // Recorre los tickets y actualiza los montos justificados si son null
        const gastosJustificados = [];
        const ticketsActualizados = gastoAdicional.tickets.map((ticket) => {
          const itemsGastosActualizados = ticket.items_gastos.map((item) => {
            if (item.monto_justificado === null) {
              item.monto_justificado = item.monto_preaprobado;
              gastosJustificados.push({
                id: item.id,
                monto_justificado: item.monto_preaprobado,
              });
            }
            return item;
          });
          ticket.items_gastos = itemsGastosActualizados;
          return ticket;
        });

        // Actualiza el estado con los tickets actualizados
        setGastoAdicional({
          ...gastoAdicional,
          tickets: ticketsActualizados,
        });

        const actualizar = {
          id: gastoAdicional.id,
          justifica_id: dataUserLogged.id,
          estado_id: 3,
          usuario_id: dataUserLogged.id,
          items_gastos: gastosJustificados,
        };
        try {
          const res = await JustificarGastoAdicional(actualizar, apiToken);
          if (res && res.status === 200 && res.data) {
            AlertaSuccess({
              title: 'Gastos Justificados',
              success: `Se justificaron los gastos de la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al justificar gastos',
            error: 'Ocurrio un error al justificar los gastos',
          });
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      {isLoading || actualizarData ? <SpinerDownload /> : null}
      <section className="row">
        <div className="col-12 col-lg-9">
          {/*  Contenido Principal  */}
          <div className="row">
            <DetalleProveedor
              data={gastoAdicional}
              setActualizarData={setActualizarData}
            />
          </div>
        </div>
        {/*  Tercera columna  */}
        <div className="col-12 col-lg-3">
          {/*  Card info usuario logueado  */}
          <CardUserLogged />

          {/*  Card ultimos recursos  */}

          {/* Ultimas desvinculaciones */}

          {/*  Card Estado Solicitud  */}
          <div className="card">
            <div className="card-header">
              <h4>Estado Solicitud</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <h6>
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <span className="badge bg-light-info fs-5">
                        {gastoAdicional.estado}
                      </span>
                      <p className="mt-3">
                        {gastoAdicional.estado_descripcion}
                      </p>
                    </>
                  )}
                </h6>
              </div>
            </div>
          </div>

          {/*  Card de accion  */}
          <div className="card">
            <div className="card-header">
              <h4>Acciones</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <h6>
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : gastoAdicional.estado === 'Preaprobada' ||
                    gastoAdicional.estado === 'Rechazada' ? (
                    <>
                      <button
                        className="btn btn-success"
                        onClick={handleJustificarGastos}
                      >
                        <i className="bi bi-cloud-upload"></i> Justificar Gastos
                      </button>
                      <p className="mt-3">
                        Presionar una vez agregados todos los comprobantes que
                        justifiquen los gastos.
                      </p>
                    </>
                  ) : null}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GastoDetalleProveedor;
