import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const showAlert = (options) => MySwal.fire(options);

export const AlertaError = ({ title = 'Error', error }) =>
  showAlert({
    title,
    text: error,
    icon: 'error',
    confirmButtonText: 'Aceptar',
  });

export const AlertaSuccess = ({ title = 'Éxito', success }) =>
  showAlert({
    title,
    text: success,
    icon: 'success',
    confirmButtonText: 'Aceptar',
  });

export const AlertaWarning = ({ warning }) =>
  showAlert({
    title: 'Advertencia',
    text: warning,
    icon: 'warning',
    confirmButtonText: 'Aceptar',
  });

export const AlertaInfo = ({ success }) =>
  showAlert({
    position: 'top-end',
    icon: 'success',
    title: success,
    showConfirmButton: false,
    timer: 1500,
  });

export const AlertaConfirm = ({ title, text, callback }) =>
  showAlert({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });

export const AlertaConfirmComentario = ({ title, text, callback }) =>
  showAlert({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
    input: 'textarea',
    inputPlaceholder: 'Comentario',
    inputAttributes: {
      autocapitalize: 'off',
    },
    showLoaderOnConfirm: true,
    preConfirm: (comentario) => comentario,
  }).then((result) => {
    if (result.isConfirmed) {
      callback(result.value);
    }
  });
