import { apiUrl } from '../config';

// Obtiene los cargos
export const getCargos = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/cargos')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Crea un cargo
export const createCargo = async (cargo, apiToken) => {
  const response = await fetch(`${apiUrl('administracion/cargo')}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(cargo),
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Actualiza un cargo
export const updateCargo = async (cargo, apiToken) => {
  const response = await fetch(`${apiUrl('administracion/cargo')}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(cargo),
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Elimina un cargo
export const deleteCargo = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('administracion/cargo')}/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};
