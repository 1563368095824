import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import GastoDetalle from './GastoDetalle';
import {
  AprobarGastoAdicional,
  ConsolidarGastoAdicional,
  getGastoAdicionalById,
  preaprobacionSolicitud,
  AnularGastoAdicional,
  ReactivarGastoAdicional,
  RechazarGastoAdicional,
} from '../../../services/gastosAdicionales';
import useUser from '../../../hooks/useUser';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
  AlertaConfirmComentario,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

function GastoDetalleAdm() {
  const { dataUserLogged, apiToken } = useUser();
  const { id } = useParams();
  const [gastoAdicional, setGastoAdicional] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [actualizarData, setActualizarData] = useState(false);

  useEffect(() => {
    getSolicitud(id, apiToken, dataUserLogged.rut);
  }, [id, apiToken, dataUserLogged.rut]);

  useEffect(() => {
    if (actualizarData) {
      getSolicitud(id, apiToken, dataUserLogged.rut);
      setActualizarData(false);
    }
  }, [actualizarData, id, apiToken, dataUserLogged.rut]);

  const getSolicitud = async (id, apiToken, rut) => {
    const res = await getGastoAdicionalById(id, apiToken, rut);
    if (res && res.status === 200 && res.data) {
      setGastoAdicional(res.data);
      setIsLoading(false);
    }
  };

  const handlePreaprobar = async () => {
    AlertaConfirm({
      title: '¿Preaprobar Solicitud?',
      text: `Se preaprobara para ejecución la solicitud: ${gastoAdicional.correlativo}`,
      callback: async () => {
        setIsLoading(true);

        // Recorre los tickets y actualiza los montos preaprobados si son null
        const gastosPreaprobados = [];
        const ticketsActualizados = gastoAdicional.tickets.map((ticket) => {
          const itemsGastosActualizados = ticket.items_gastos.map((item) => {
            if (item.monto_preaprobado === null) {
              item.monto_preaprobado = item.monto_solicitado;
              gastosPreaprobados.push({
                id: item.id,
                monto_preaprobado: item.monto_solicitado,
              });
            }
            return item;
          });
          ticket.items_gastos = itemsGastosActualizados;
          return ticket;
        });

        // Actualiza los tickets con los montos preaprobados
        setGastoAdicional({
          ...gastoAdicional,
          tickets: ticketsActualizados,
        });

        const actualizar = {
          id: gastoAdicional.id,
          preaprueba_id: dataUserLogged.id,
          estado_id: 2,
          items_gastos: gastosPreaprobados,
        };

        try {
          const res = await preaprobacionSolicitud(actualizar, apiToken);
          if (res && res.status === 200 && res.data) {
            AlertaSuccess({
              title: 'Solicitud Preaprobada',
              success: `Se preaprobo la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al preaprobar solicitud',
            error: 'Ocurrió un error al intentar preaprobar la solicitud',
          });
        }
        setIsLoading(false);
      },
    });
  };

  const handleAprobar = async () => {
    AlertaConfirm({
      title: '¿Aprobar Solicitud?',
      text: `Se Aprobaran los gastos de la solicitud: ${gastoAdicional.correlativo}`,
      callback: async () => {
        setIsLoading(true);
        const actualizar = {
          id: gastoAdicional.id,
          aprueba_id: dataUserLogged.id,
          estado_id: 4,
        };
        try {
          const res = await AprobarGastoAdicional(actualizar, apiToken);
          if (res && res.status === 200 && res.data) {
            AlertaSuccess({
              title: 'Solicitud Aprobada',
              success: `Se aprobaron los gastos de la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al aprobar solicitud',
            error: 'Ocurrió un error al intentar aprobar la solicitud',
          });
        }
        setIsLoading(false);
      },
    });
  };

  const handleConsolidar = async () => {
    AlertaConfirm({
      title: '¿Consolidar Solicitud?',
      text: `Se marcara la solicitud ${gastoAdicional.correlativo} como consolidada`,
      callback: async () => {
        setIsLoading(true);
        const actualizar = {
          id: gastoAdicional.id,
          consolida_id: dataUserLogged.id,
          estado_id: 5,
        };
        try {
          const res = await ConsolidarGastoAdicional(actualizar, apiToken);
          if (res && res.status === 200 && res.data) {
            AlertaSuccess({
              title: 'Solicitud Consolidada',
              success: `Se consolidaron los gastos de la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al consolidar solicitud',
            error: 'Ocurrió un error al intentar consolidar la solicitud',
          });
        }
        setIsLoading(false);
      },
    });
  };

  const handleRechazarGastos = async () => {
    AlertaConfirmComentario({
      title: 'Rechazar Servicio?',
      text: `Se rechazara el servicio justificado: ${gastoAdicional.correlativo}`,
      callback: async (comentario) => {
        setIsLoading(true);
        const rechazar = {
          id: gastoAdicional.id,
          rechaza_id: dataUserLogged.id,
          estado_id: 7,
          comentario_rechazo: comentario === '' ? null : comentario, // captura el comentario aquí
        };
        try {
          const res = await RechazarGastoAdicional(rechazar, apiToken);
          if (res && res.status === 200) {
            AlertaSuccess({
              title: 'Servicio Rechazado',
              success: `Se rechazo la justificación de la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al rechazar servicio',
            error: 'Ocurrió un error al intentar rechazar el servicio',
          });
        }
        setIsLoading(false);
      },
    });
  };

  const handleAnularServicio = async () => {
    AlertaConfirmComentario({
      title: '¿Anular Servicio?',
      text: `Se anulara el servicio de la solicitud: ${gastoAdicional.correlativo}`,
      callback: async (comentario) => {
        setIsLoading(true);
        const anular = {
          id: gastoAdicional.id,
          anula_id: dataUserLogged.id,
          estado_id: 6,
          comentario_anulacion: comentario === '' ? null : comentario, // captura el comentario aquí
        };
        try {
          const res = await AnularGastoAdicional(anular, apiToken);
          if (res && res.status === 200) {
            AlertaSuccess({
              title: 'Servicio Anulado',
              success: `Se anulo el servicio de la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al anular servicio',
            error: 'Ocurrió un error al intentar anular el servicio',
          });
        }
        setIsLoading(false);
      },
    });
  };

  const handleReactivarSolicitud = async () => {
    AlertaConfirmComentario({
      title: '¿Reactivar Solicitud?',
      text: `Se reactivara la solicitud: ${gastoAdicional.correlativo}`,
      callback: async (comentario) => {
        setIsLoading(true);
        const reactivar = {
          id: gastoAdicional.id,
          reactiva_id: dataUserLogged.id,
          estado_id: 1,
          comentario_reactivacion: comentario === '' ? null : comentario, // captura el comentario aquí
        };
        try {
          const res = await ReactivarGastoAdicional(reactivar, apiToken);
          if (res && res.status === 200) {
            AlertaSuccess({
              title: 'Solicitud Reactivada',
              success: `Se reactivó la solicitud: ${gastoAdicional.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al reactivar solicitud',
            error: 'Ocurrió un error al intentar reactivar la solicitud',
          });
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      {isLoading || actualizarData ? <SpinerDownload /> : null}
      <section className="row">
        <div className="col-12 col-lg-9">
          {/*  Contenido Principal  */}
          <div className="row">
            <GastoDetalle
              data={gastoAdicional}
              setActualizarData={setActualizarData}
            />
          </div>
        </div>
        {/*  Tercera columna  */}
        <div className="col-12 col-md-3 d-flex flex-wrap">
          {/*  Card info usuario logueado  */}
          <div className="col-12 order-md-1 order-3">
            <CardUserLogged />
          </div>

          {/*  Card Estado Solicitud  */}
          <div className="card col-12 order-md-2 order-2 shadow">
            <div className="card-header">
              <h6 className='text-center'>ESTADO SOLICITUD</h6>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                    <h6>
                      <span className="badge bg-light-info fs-5">
                        {gastoAdicional.estado}
                      </span>
                      </h6>
                      <p className="mt-3">
                      {gastoAdicional.estado_descripcion}
                    </p>
                    </> 
                  )}      
              </div>
            </div>
          </div>

          {/*  Card de accion  */}
          <div className="card col-12 order-md-3 order-1 shadow">
            <div className="card-header">
              <h6 className='text-center'>GASTO ADICIONAL</h6>
            </div>
            <div className="card-body px-4">
             
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    {gastoAdicional.estado === 'Generada' ? (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={handlePreaprobar}
                        >
                          Preaprobar
                        </button>
                      </>
                    ) : null}
                    {gastoAdicional.estado === 'Justificada' ? (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={handleAprobar}
                        >
                          Aprobar
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={handleRechazarGastos}
                        >
                          Rechazar
                        </button>
                      </>
                    ) : null}
                    {gastoAdicional.estado === 'Aprobada' ? (
                      <button
                        className="btn btn-success"
                        onClick={handleConsolidar}
                      >
                        Consolidar Gastos
                      </button>
                    ) : null}
                    {dataUserLogged.rol === 'Administrador' &&
                    gastoAdicional.estado_id < 6 ? (
                      <button
                        className="btn btn-secondary"
                        onClick={handleAnularServicio}
                      >
                        Anular
                      </button>
                    ) : null}
                    {Number(gastoAdicional.estado_id) === 6 ? (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleReactivarSolicitud}
                      >
                        Reactivar solicitud
                      </button>
                    ) : null}
                  </div>
                )}
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GastoDetalleAdm;
