import { apiUrl } from '../config';

// genera el excel de los recursos para proveedores
export const generaExcelRecursosProveedor = async (rut, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/excel/recursos')}/${rut}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  return data;
};

// genera el excel de los recursos admin
export const generaExcelRecursos = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/excel/recursos')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  return data;
};
