import React, { useEffect, useState } from 'react';
import CardUserLogged from '../general/cardsInfo/CardUserLogged';
import { getRecursosPorRegion } from '../../services/recursos';
import UltimasDesvinculaciones from '../general/cardsInfo/UltimasDesvinculaciones';
import UltimosRecursos from '../general/cardsInfo/UltimosRecursos';
import { getProveedores } from '../../services/proveedores';
import useUser from '../../hooks/useUser';
import RecursosRegiones from '../general/charts/RecursosRegiones';
import RecursosRegionesXproveedor from '../general/charts/RecursosRegionesXproveedor';
import { formatDate, formatDateTime } from '../../hooks/useDates';

function DashSpoc() {
  const { apiToken, isLogged, role } = useUser();
  const [proveedores, setProveedores] = useState([]);
  const [cantRecursos, setCantRecursos] = useState([]);
  const [reclamos, setReclamos] = useState([]);

  useEffect(() => {
    console.log(role);
    if (isLogged && role === 'Spoc') {
      getProveedores(apiToken).then((data) => {
        setProveedores(data);
        localStorage.setItem('cantidad-proveedores', data.cantidad);
      });
      getRecursosPorRegion(apiToken).then((data) => {
        setCantRecursos(data.recursosRegiones);
      });
    }
  }, [apiToken, isLogged, role]);

  const total = cantRecursos.reduce(
    (acc, cur) => parseInt(acc) + parseInt(cur.total),
    0
  );
  localStorage.setItem('cantidad-recursos', total);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Documentos Pendientes
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-vencidos">
                      0
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Documentos Por vencer
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-por-vencer">
                      0
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Total Recursos</h6>
                    <h4 className="font-extrabold mb-0" id="cantidad-recursos">
                      {total}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Proveedores</h6>
                    <h4
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      {proveedores.cantidad}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Cards estadisticas end  */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Recursos por región</h4>
              </div>
              <div className="card-body">
                <div id="chart-profile-visit">
                  <RecursosRegiones recursosRegion={cantRecursos} />
                </div>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <h6>
                      Total país: <span>{total}</span> Recursos activos
                    </h6>
                  </div>

                  {/* updated at */}
                  <div className=" col-6 text-end text-muted ">
                    <small>
                      Ultima actualización: {formatDateTime(Date.now())}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Recursos regiones por proveedor */}
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Recursos por región segun proveedor</h4>
              </div>
              <div className="card-body">
                <div id="chart-profile-visit">
                  <RecursosRegionesXproveedor recursosRegion={cantRecursos} />
                </div>
                <hr />
                <div className="row">
                  <div className="col-6">
                    <h6>
                      Total país: <span>{total}</span> Recursos activos
                    </h6>
                  </div>

                  {/* updated at */}
                  <div className=" col-6 text-end text-muted ">
                    <small>
                      Ultima actualización: {formatDateTime(Date.now())}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4">
            <div className="card">
              <div className="card-header">
                <h4>Proveedores Activos</h4>
              </div>
              <div className="card-body">
                {/* Row proveedor */}
                {proveedores.cantidad > 0 ? (
                  proveedores.proveedores.map((proveedor, i) => (
                    <div className="row" key={i}>
                      <div className="col-12">
                        <div className="d-flex align-items-center">
                          <svg
                            className="bi text-primary"
                            width="32"
                            height="32"
                            fill="blue"
                            style={{ width: 10 + 'px' }}
                          >
                            <use xlinkHref="assets/images/bootstrap-icons.svg#circle-fill" />
                          </svg>
                          <h5 className="mb-0 ms-3">{proveedor.nombre}</h5>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <svg
                          className="bi text-primary"
                          width="32"
                          height="32"
                          fill="blue"
                          style={{ width: 10 + 'px' }}
                        >
                          <use xlinkHref="assets/images/bootstrap-icons.svg#circle-fill" />
                        </svg>
                        <h5 className="mb-0 ms-3">No hay proveedores</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h4>Últimos Reclamos</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-lg">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Proveedor</th>
                        <th>Fecha</th>
                        <th>Tipo Reclamo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reclamos.length > 0 ? (
                        reclamos.map((reclamo, i) => (
                          <tr key={i}>
                            <td className="col-5">
                              <div className="d-flex align-items-center">
                                <div className="avatar avatar-md">
                                  {/* <img src="assets/images/faces/5.jpg" /> */}
                                </div>
                                <p className="font-bold ms-3 mb-0">
                                  {`${reclamo.nombre} ${reclamo.apellido}`}
                                </p>
                              </div>
                            </td>
                            <td className="col-auto">
                              <p className="mb-0">{reclamo.proveedor}</p>
                            </td>
                            <td className="col-auto">
                              <p className="mb-0">
                                {formatDate(reclamo.fecha_incidencia)}
                              </p>
                            </td>
                            <td className="col-auto">
                              <p className="mb-0">{reclamo.tipo_reclamo}</p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="col-5">
                            No hay registros
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />
        {/*  Card ultimos recursos  */}
        <UltimosRecursos apiToken={apiToken} />
        {/* Ultimos desvinculados */}
        <UltimasDesvinculaciones apiToken={apiToken} />
        {/*  Card asistencia  */}
        <div className="card">
          <div className="card-header">
            <h4>Asistencia</h4>
            <p>No Disponible</p>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashSpoc;
