import '../../assets/css/pages/error403.css';
const Error403 = () => {
  return (
    <>
      <div className="layout-error row position-absolute top-0 start-50 translate-middle-x">
        <div className="eye-error col-2">
          <div className="eye-error-eyebrow"></div>
          <div className="eye-error-ball"> </div>
          <div className="eye-error-ball2">
            <div className="eye-error-cornia">
              <div className="eye-error-pupil"></div>
            </div>
          </div>
        </div>
        <div className="bee-error col-2">
          <div className="bee-error-eye bee-error-eye-left"></div>
          <div className="bee-error-eye bee-error-eye-right"></div>

          <div className="bee-error-body"></div>
          <div className="bee-error-wing-left"></div>
          <div className="bee-error-wing-right"></div>
        </div>
        <div className="M-error col-2">M</div>
      </div>
      <div className="position-absolute bottom-0 start-50 translate-middle-x text-center mb-5">
        <div className="col-12">
          <h1>403</h1>
        </div>
        <div className="col-12">
          <h2>Acceso Denegado</h2>
        </div>
      </div>
    </>
  );
};

export default Error403;
