/** RUTAS
 * Rutas de la aplicación que son importadas en App.jsx
 */

export const LOGIN = '/'; // login
export const RECUPERAR = '/recuperar'; // recuperar contraseña

export const ADMINISTRACION = '/administracion'; // administracion
export const PROVEEDOR = '/proveedor'; // proveedor
export const SPOC = '/spoc'; // spoc
