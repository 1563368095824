import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  createUsuario,
  deleteUsuario,
  getUsuarios,
} from '../../../services/usuarios';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import CustomTable from '../../general/CustomTable';

const ADMINISTRACION = 1;
const PROVEEDOR = 2;
const SPOC = 3;

function Usuarios() {
  const { apiToken } = useUser();

  const [usuarios, setUsuarios] = useState([]);
  const [editing, setEditing] = useState(false);
  const [usuario, setUsuario] = useState({
    name: '',
    email: '',
    // password: '',
    // passwordConfirmacion: '',
    rut: '',
    role: '',
  });
  const [formTitle, setFormTitle] = useState('Nuevo Usuario');

  const columns = [
    {
      name: 'NOMBRE',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'EMAIL',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'PERFIL',
      selector: (row) => row.rol,
      sortable: true,
    },
    {
      name: 'EMPRESA',
      selector: (row) => row.proveedor_nombre,
      sortable: true,
    },
    {
      name: 'ACCIONES',
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleEditUser(row)}
          >
            <i className="bi bi-pencil-square"></i>
          </button>
          <button
            className="btn btn-danger btn-sm ms-2"
            onClick={() => handleDeleteUser(row)}
          >
            <i className="bi bi-trash"></i>
          </button>
        </>
      ),
      right: true,
      width: '8%',
    },
  ];

  // get data
  const getData = (apiToken) => {
    getUsuarios(apiToken).then((data) => {
      setUsuarios(data.users);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  const handleChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validación de contraseñas
    if (usuario.password !== usuario.passwordConfirmacion) {
      AlertaError({
        error: 'Las contraseñas no coinciden',
      });
      return;
    }

    const actionText = editing ? 'editar' : 'crear';
    const actionTitle = `¿Desea ${actionText} el usuario?`;
    const actionDescription = editing
      ? `Se actualizarán los datos del usuario ${usuario.name}.`
      : `Se creará a ${usuario.name} en el sistema`;

    AlertaConfirm({
      title: actionTitle,
      text: actionDescription,
      callback: () => {
        if (editing) {
          createUsuario(usuario, apiToken).then((response) => {
            if (response.status === 200) {
              AlertaSuccess({
                title: 'Usuario actualizado correctamente',
                success: `El usuario ${usuario.name} ha sido actualizado correctamente`,
              });
              setEditing(false);
              getData(apiToken);
            } else {
              AlertaError({
                error: 'Error al actualizar el usuario',
              });
            }
          });
        } else {
          createUsuario(usuario, apiToken).then((response) => {
            if (response.status === 200) {
              AlertaSuccess({
                title: 'Usuario creado correctamente',
                success: `El usuario ${usuario.name} ha sido creado correctamente`,
              });
              getData(apiToken);
            } else {
              AlertaError({
                error: 'Error al crear el usuario',
              });
            }
          });
        }
        // Reiniciar el estado del usuario
        setUsuario({
          name: '',
          email: '',
          password: '',
          passwordConfirmacion: '',
          rut: '',
          role: '',
        });
        e.target.reset();
        setFormTitle('Nuevo Usuario');
      },
    });
  };

  const handleEditUser = (row) => {
    setFormTitle('Editar Usuario');
    setEditing(true);
    setUsuario(row);
  };

  const handleDeleteUser = (row) => {
    AlertaConfirm({
      title: `¿Desea eliminar el usuario?`,
      text: `Se eliminará el usuario ${row.name} del sistema`,
      callback: () => {
        deleteUsuario(row.id, apiToken).then((response) => {
          if (response.status === 200) {
            AlertaSuccess({
              title: 'Usuario eliminado correctamente',
              success: `El usuario ${row.name} ha sido eliminado correctamente`,
            });
            getData(apiToken);
          } else {
            AlertaError({
              error: 'Error al eliminar el usuario',
            });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-md-6 col-12">
          <h3>Usuarios activos</h3>
          {/* table */}
          <CustomTable columns={columns} data={usuarios} expandableRows />
        </div>
        <div className="col-md-6 col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{formTitle}</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                onSubmit={handleSubmit}
                id="form-create-user"
              >
                <div className="row">
                  {/* Nombres */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-nombre" className="form-label">
                        Nombre Apellido
                      </label>
                      <input
                        type="text"
                        id="input-nombre"
                        className="form-control"
                        placeholder="Nombre"
                        name="name"
                        value={usuario.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        id="input-email"
                        className="form-control"
                        name="email"
                        value={usuario.email}
                        onChange={handleChange}
                        placeholder="email"
                      />
                    </div>
                  </div>
                  {/* Password */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-password" className="form-label">
                        Password
                      </label>
                      <input
                        type="text"
                        id="input-password"
                        className="form-control"
                        name="password"
                        onChange={handleChange}
                        placeholder="password"
                      />
                    </div>
                  </div>

                  {/* Password Confirmacion */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label
                        htmlFor="input-password-confirmacion"
                        className="form-label"
                      >
                        Password Confirmación
                      </label>
                      <input
                        type="text"
                        id="input-password-confirmacion"
                        className="form-control"
                        name="passwordConfirmacion"
                        onChange={handleChange}
                        placeholder="password"
                      />
                    </div>
                  </div>

                  {/* Rut Proveedor */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label
                        htmlFor="input-rut-proveedor"
                        className="form-label"
                      >
                        RUT Proveedor
                      </label>
                      <input
                        className="form-control"
                        id="input-rut-proveedor"
                        name="rut"
                        value={usuario.rut}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* Rol */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-role" className="form-label">
                        Rol
                      </label>
                      {/* select */}
                      <select
                        className="form-select"
                        name="role"
                        value={usuario.role}
                        onChange={handleChange}
                      >
                        <option value="">Seleccione</option>
                        <option value={ADMINISTRACION}>Administrador</option>
                        <option value={PROVEEDOR}>Proveedor</option>
                        <option value={SPOC}>Spoc</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                      onClick={() => setUsuario({})}
                    >
                      Borrar Form
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Usuarios;
