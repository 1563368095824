import { useEffect, useState } from 'react';
import { formatDate, formatDateTime } from '../../../hooks/useDates';
import useUser from '../../../hooks/useUser';
import ModalVerComprobante from '../../general/forms/gastos/ModalVerComprobante';
import ModalMapaCliente from '../../general/gastos/ModalMapaCliente';
import ModalEditValorItem from './modal/ModalEditValorItem';
import ModalEditarJustificado from './modal/ModalEditarJustificado';

function GastosDetalleAdm({ data, setActualizarData }) {
  const [itemId, setItemId] = useState(null);
  const [imagenModal, setImagenModal] = useState(null);
  const [montoTotal, setMontoTotal] = useState(0);
  const { apiToken, dataUserLogged } = useUser();
  const [direccionModal, setDireccionModal] = useState(null);

  useEffect(() => {
    if (data) {
      setMontoTotal(data.monto_total);
    }
  }, [data]);

  const handleModalClose = () => {
    setImagenModal(null);
  };

  function getClassNameTable(estado) {
    const estadoActual = data.estado;
    if (estado === estadoActual) {
      return 'col-1 table-success';
    }
    return 'col-1';
  }

  return !data ? null : (
    <div className="card">
      {data.estado_id >= 6 && (
        <div className="despedido-badge">{data.estado}</div>
      )}
      <div className="card-header">
        <h5 className="card-title">INFORMACIÓN SERVICIO CON GASTO ADICIONAL</h5>
      </div>
      <div className="card-body">
        {/* Datos solicitud */}
        <div className="d-flex justify-content-between flex-wrap">
          <div className="col-md-10 col-12 order-md-1 order-2">
            <div className="row">
              <div className="col-md-6 col-12">
                <p>
                  <strong>Fecha Solicitud:</strong>{' '}
                  {formatDateTime(data.created_at)}
                </p>
              </div>
              <div className="col-md-6 col-12">
                <p>
                  <strong>Solicitado por:</strong> {data.solicitante}
                </p>
              </div>
            </div>
            <p className="card-text">
              <strong>Fecha atención:</strong> {formatDate(data.fecha_atencion)}
            </p>
            <p className="card-text">
              <strong>Recurso Asignado:</strong> {data.recurso_nombre}{' '}
              {data.recurso_apellido}
            </p>
            <div className="row mb-2">
              <strong className="col-md-3 col-12">Comentario solicitud:</strong>
              <span className="col-md-9 col-12">{data.observacion}</span>
            </div>

            {/* Preaprobacion data */}
            {data.preaprueba && Number(data.estado_id) >= 2 && (
              <div className="row">
                <p className="card-text col-md-6 col-12">
                  <strong>Preaprobado por:</strong> {data.preaprueba}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Fecha Preaprobación:</strong>{' '}
                  {formatDateTime(data.fecha_preaprobacion)}
                </p>
              </div>
            )}

            {/* Justificacion data */}
            {data.justifica && Number(data.estado_id) >= 3 && (
              <div className="row">
                <p className="card-text col-md-6 col-12">
                  <strong>Justificado por:</strong> {data.justifica}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Fecha Justificación:</strong>{' '}
                  {formatDateTime(data.fecha_justificacion)}
                </p>
              </div>
            )}
            {/* Aprobacion data */}
            {data.aprueba && Number(data.estado_id) >= 4 && (
              <div className="row">
                <p className="card-text col-md-6 col-12">
                  <strong>Aprobado por:</strong> {data.aprueba}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Fecha Aprobación:</strong>{' '}
                  {formatDateTime(data.fecha_aprobacion)}
                </p>
              </div>
            )}
            {/* Consolidacion data */}
            {data.consolida && Number(data.estado_id) >= 5 && (
              <div className="row">
                <p className="card-text col-md-6 col-12">
                  <strong>Consolidado por:</strong> {data.consolida}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Fecha Consolidación:</strong>{' '}
                  {formatDateTime(data.fecha_consolidacion)}
                </p>
              </div>
            )}
            {/* Rechazo data */}
            {data.rechaza || Number(data.estado_id) === 7 ? (
              <div className="row">
                <p className="card-text col-6">
                  <strong>Rechazado por:</strong> {data.rechaza}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Fecha rechazo:</strong>{' '}
                  {formatDateTime(data.fecha_rechazo)}
                </p>
                <p className="card-text text-danger col-md-6 col-12">
                  <strong>Comentario rechazo:</strong> {data.comentario_rechazo}
                </p>
              </div>
            ) : null}
            {/* anulacion data */}
            {data.anula && Number(data.estado_id) >= 6 && (
              <div className="row">
                <p className="card-text text-danger col-6">
                  <strong>Anulado por:</strong> {data.anula}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Fecha anulación:</strong>{' '}
                  {formatDateTime(data.deleted_at)}
                </p>
                <p className="card-text col-md-6 col-12">
                  <strong>Comentario anulación:</strong>{' '}
                  {data.comentario_anulacion}
                </p>
              </div>
            )}
          </div>
          <div className="col-md-2 col-12 order-md-2 order-1">
            <div className="row">
              <div className="col-6 col-md-12">
                <strong>Sol. N°:</strong>
                <h4>{data.correlativo}</h4>
              </div>
              <div className="col-6 col-md-12">
                <strong>Monto total:</strong> <h2>${montoTotal}</h2>
              </div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="divider divider-left mt-2">
          <div className="divider-text">TICKETS</div>
        </div>

        {/* Tickets */}
        {data.tickets.map((ticket, index) => (
          <div className="row border border-primary mb-3 px-3 pt-3" key={index}>
            <div className="row">
              <p className="card-text col-md-6 col-12">
                <strong>Proyecto:</strong> {ticket.proyecto}
              </p>

              <p className="card-text col-md-5 col-11">
                <strong>Ticket:</strong>{' '}
                {`${ticket.nomenclatura}${ticket.ticket}`}
              </p>
              <div className="col-1">
                <button
                  className="btn btn-outline-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalMapaCliente"
                  onClick={() => {
                    setDireccionModal(
                      `${ticket.direccion}, ${ticket.comuna}, ${ticket.region}`
                    );
                  }}
                >
                  <i className="bi bi-geo-alt-fill"></i>
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <p className="card-text col-md-6 col-12">
                <strong>Dirección:</strong> {ticket.direccion}
              </p>

              <p className="card-text col-md-3 col-6">
                <strong>Comuna:</strong> {ticket.comuna}
              </p>

              <p className="card-text col-md-3 col-6">
                <strong>Región:</strong> {ticket.region}
              </p>
            </div>

            <div className="row mb-2">
              <strong className="col-md-2 col-12">Comentario Ticket:</strong>
              <span className="col-md-9 col-12">{ticket.observacion}</span>
            </div>

            {/* Divider */}
            <div className="divider divider-left mt-2">
              <div className="divider-text">GASTOS DEL TICKET</div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="col-1">Item</th>
                    <th className="col-1">Kms</th>
                    <th className={getClassNameTable('Generada')}>
                      $ Solicitado
                    </th>
                    <th className={getClassNameTable('Preaprobada')}>
                      $ Preaprobado
                    </th>
                    <th className={getClassNameTable('Justificada')}>
                      $ Justificado
                    </th>
                    <th className="col-4">Comentario solicitante</th>
                    <th className="col-1">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {ticket.items_gastos
                    ? ticket.items_gastos.map((item, index) => (
                        <tr key={index}>
                          <td>{item.concepto}</td>
                          <td>{item.kilometros || 'NA'}</td>
                          <td className={getClassNameTable('Generada')}>
                            {item.monto_solicitado}
                          </td>
                          <td className={getClassNameTable('Preaprobada')}>
                            {item.monto_preaprobado}
                          </td>
                          <td className={getClassNameTable('Justificada')}>
                            {item.monto_justificado}
                          </td>
                          <td>
                            <table className="table mb-0">
                              <tbody>
                                <tr>
                                  <td>{item.comentario_solicitante}</td>
                                </tr>
                                {item.comentario_preaprobacion !== null &&
                                  item.comentario_preaprobacion !==
                                    'undefined' &&
                                  Boolean(
                                    item.comentario_preaprobacion.length
                                  ) && (
                                    <>
                                      <tr>
                                        <td>
                                          <strong>
                                            Comentario Preaprobación
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{item.comentario_preaprobacion}</td>
                                      </tr>
                                    </>
                                  )}
                                {item.comentario_justificacion !== null &&
                                  item.comentario_justificacion !==
                                    'undefined' &&
                                  Boolean(
                                    item.comentario_justificacion.length
                                  ) && (
                                    <>
                                      <tr>
                                        <td>
                                          <strong>
                                            Comentario Justificacion
                                          </strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{item.comentario_justificacion}</td>
                                      </tr>
                                    </>
                                  )}
                              </tbody>
                            </table>
                          </td>
                          <td className="d-flex">
                            {Number(data.estado_id) === 1 ? (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#modalEditarPreaprobado"
                                onClick={() =>
                                  setItemId({
                                    item_id: item.id,
                                    apiToken: apiToken,
                                    monto_solicitado: item.monto_solicitado,
                                    monto_preaprobado: item.monto_preaprobado,
                                    solicitud_id: data.id,
                                    monto_total: data.monto_total,
                                    usuario_id: dataUserLogged.id,
                                  })
                                }
                              >
                                $ Monto
                              </button>
                            ) : null}
                            {Number(data.estado_id) === 3 ? (
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#modalEditarJustificado"
                                onClick={() =>
                                  setItemId({
                                    id: item.id,
                                    apiToken: apiToken,
                                    monto_justificado: item.monto_justificado,
                                    solicitud_id: data.id,
                                    monto_total: data.monto_total,
                                    usuario_id: dataUserLogged.id,
                                  })
                                }
                              >
                                $ Monto
                              </button>
                            ) : null}
                            {item.comprobante_url && data.estado_id !== 1 && (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#modalVerComprobante"
                                onClick={() =>
                                  setImagenModal({
                                    imagen: item.comprobante_url,
                                    ticket: ticket.nomenclatura + ticket.ticket,
                                    item: item.concepto,
                                  })
                                }
                              >
                                <i className="bi bi-camera"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
      <ModalEditValorItem data={itemId} setActualizarData={setActualizarData} />
      <ModalEditarJustificado
        data={itemId}
        setActualizarData={setActualizarData}
      />
      <ModalVerComprobante
        imagen={imagenModal}
        handleModalClose={handleModalClose}
      />
      <ModalMapaCliente direccion={direccionModal} />
    </div>
  );
}

export default GastosDetalleAdm;
