import Chart from 'react-apexcharts';

function RecursosRegionesXproveedor({ recursosRegion }) {
  //   const proveedores = [];

  //   change name to key rut for name
  const recursosProveedores = recursosRegion.map((item) => {
    return {
      name: item.nombre,
      data: [
        item.r15,
        item.r1,
        item.r2,
        item.r3,
        item.r4,
        item.r5,
        ['0'],
        item.r6,
        item.r7,
        item.r16,
        item.r8,
        item.r9,
        item.r14,
        item.r10,
        item.r11,
        item.r12,
      ],
    };
  });

  return (
    <>
      <Chart
        options={{
          colors: ['#fe6001', '#c3e346', '#00b3e4', '#1d2342'],
          chart: {
            id: 'recursos-regiones-proveedor',
            stacked: true,
          },
          xaxis: {
            max: 165,
            title: {
              text: 'REGIONES',
            },
            categories: [
              '15 Arica Parinacota',
              '1 Tarapacá',
              '2 Antofagasta',
              '3 Atacama',
              '4 Coquimbo',
              '5 Valparaíso',
              '13 Metropolitana',
              "6 O'Higgins",
              '7 Maule',
              '16 Ñuble',
              '8 Biobío',
              '9 Araucanía',
              '14 Los Ríos',
              '10 Los Lagos',
              '11 Aysén',
              '12 Magallanes',
            ],
          },
          yaxis: {
            title: {
              text: 'RECURSOS',
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '80%',
              endingShape: 'rounded',
            },
          },
        }}
        series={recursosProveedores}
        type="bar"
        height={350}
      />
    </>
  );
}

export default RecursosRegionesXproveedor;
