import React, { useEffect, useState } from 'react';
import { getRecursos } from '../../../services/recursos';
import useUser from '../../../hooks/useUser';
import CustomTable from '../../general/CustomTable';
import { Link } from 'react-router-dom';
import { urlS3 } from '../../../config';

const columns = [
  {
    name: '',
    cell: (row) => (
      <div className="avatar avatar-lg">
        {row.foto ? (
          <img
            src={urlS3 + row.foto}
            alt={row.nombre.slice(0, 3).toUpperCase()}
          />
        ) : (
          <span className="avatar-content bg-secondary rounded-circle">
            {row.nombre.slice(0, 3).toUpperCase()}
          </span>
        )}
      </div>
    ),
    width: '7%',
  },
  {
    name: 'NOMBRE',
    selector: (row) => row.nombre,
    sortable: true,
    clave: 'nombre', // esta se usa en el componente exportar a excel
  },
  {
    name: 'APELLIDO',
    selector: (row) => row.apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'PROVEEDOR',
    selector: (row) => row.proveedor_nombre,
    sortable: true,
    clave: 'proveedor_nombre',
  },
  {
    name: 'REGION',
    selector: (row) => row.region_nombre,
    sortable: true,
    clave: 'region_nombre',
  },
  {
    name: 'CARGO',
    selector: (row) => row.cargo_nombre,
    sortable: true,
    clave: 'cargo_nombre',
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link
        to={`/spoc/recurso/${row.rut}`}
        className="btn btn-outline-primary btn-sm"
      >
        <i className="bi bi-eye"></i>
      </Link>
    ),
    width: '5%',
    rigth: true,
  },
];
function VerRecursosSpoc() {
  const { apiToken } = useUser();
  const [recursos, setRecursos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRecursos(apiToken);
      const filteredData = data.recursos.filter(
        (recurso) => recurso.cargo_nombre !== 'SPOC'
      );
      setRecursos(filteredData);
      setIsLoading(false);
    };
    fetchData();
  }, [apiToken]);

  const excelName = `Lista de recursos activos`;

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">RECURSOS ACTIVOS</h4>
        </div>
        <div className="card-body">
          <CustomTable
            columns={columns}
            data={recursos}
            isLoading={isLoading}
            exportar={true}
            excelName={excelName}
          />
        </div>
      </div>
    </section>
  );
}

export default VerRecursosSpoc;
