import { useState } from 'react';

function ModalHorasAprobadas({ hrChange }) {
  const [horas, setHoras] = useState(0);
  const handleChange = (e) => {
    setHoras(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    hrChange(horas);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setHoras(0);
  };

  return (
    <div
      className="modal fade modal-sm"
      id="modalHorasAprobadas"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Horas Aprobadas</h5>
          </div>
          <div className="modal-body text-center">
            {/* Hora inicio */}
            <div className="col-12">
              <div className="form-group mandatory">
                <label htmlFor="horas_aprobadas" className="form-label">
                  Horas aprobadas finalmente
                </label>
                <input
                  type="time"
                  className="form-control"
                  name="horas_aprobadas"
                  id="horas_aprobadas"
                  required
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              Aceptar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalHorasAprobadas;
