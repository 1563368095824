import React from 'react';
import './spinerloader.css';

export function SpinerLoad() {
  return <div className="loader-102"></div>;
}

export function SpinerDownload() {
  return <div className="loader-101"></div>;
}
