import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import { getOvertime, actualizarOvertime } from '../../../services/overtimes';
import useUser from '../../../hooks/useUser';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';
import OvertimeDetalle from './OvertimeDetalle';

function DetalleOvertime() {
  const { dataUserLogged, apiToken } = useUser();
  const { id } = useParams();
  const [overtime, setOvertime] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalHoras, setTotalHoras] = useState('');
  const [actualizarData, setActualizarData] = useState(false);

  useEffect(() => {
    if (actualizarData) {
      getSolicitud(id, apiToken);
      setActualizarData(false);
    }
  }, [id, apiToken, actualizarData]);

  useEffect(() => {
    getSolicitud(id, apiToken);
  }, [id, apiToken]);

  const getSolicitud = async (id, apiToken) => {
    const res = await getOvertime(id, apiToken);
    if (res && res.status === 200 && res.overtime) {
      setOvertime(res.overtime);
      setIsLoading(false);
    }
  };

  const handleJustificarOvt = async () => {
    if (totalHoras === '00:00' || totalHoras === '0:00' || totalHoras === '') {
      AlertaError({
        title: 'Error al justificar',
        error: 'Las horas a justificar no pueden ser 0',
      });
      return;
    }
    AlertaConfirm({
      title: '¿Justificar Overtime?',
      text: `Se justificara el overtime de la solicutd: ${overtime.correlativo}`,
      callback: async () => {
        setIsLoading(true);

        const actualizar = {
          id: overtime.id,
          justifica_id: dataUserLogged.id,
          horas_justificadas: totalHoras,
          estado_id: 3,
          usuario_id: dataUserLogged.id,
        };
        try {
          const res = await actualizarOvertime(actualizar, apiToken);
          if (res && res.status === 200) {
            console.log(res);
            AlertaSuccess({
              title: 'Overtime Justificados',
              success: `Se justifico la solicitud de overtime: ${overtime.correlativo}`,
            });
            getSolicitud(id, apiToken, dataUserLogged.rut);
          }
        } catch (error) {
          AlertaError({
            title: 'Error al justificar overtime',
            error: 'Ocurrio un error al justificar el overtime',
          });
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      {isLoading ? <SpinerDownload /> : null}
      <section className="row">
        <div className="col-12 col-lg-9">
          {/*  Contenido Principal  */}
          <div className="row">
            <OvertimeDetalle
              data={overtime}
              updHora={setTotalHoras}
              actualizarData={setActualizarData}
            />
          </div>
        </div>
        {/*  Tercera columna  */}
        <div className="col-12 col-lg-3">
          {/*  Card info usuario logueado  */}
          <CardUserLogged />

          {/*  Card ultimos recursos  */}

          {/* Ultimas desvinculaciones */}

          {/*  Card Estado Solicitud  */}
          <div className="card">
            <div className="card-header">
              <h4>Estado Solicitud</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <h6>
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <span className="badge bg-light-info fs-5">
                        {overtime.estado}
                      </span>
                      <p className="mt-3">{overtime.estado_descripcion}</p>
                    </>
                  )}
                </h6>
              </div>
            </div>
          </div>

          {/*  Card de accion  */}
          <div className="card">
            <div className="card-header">
              <h4>Acciones</h4>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile">
                <h6>
                  {isLoading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    overtime.estado === 'Preaprobada' && (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={handleJustificarOvt}
                        >
                          <i className="bi bi-cloud-upload"></i> Justificar
                          Overtime
                        </button>
                        <p className="mt-3">
                          Presionar una vez agregados todos los tickets que
                          justifiquen el overtime
                        </p>
                      </>
                    )
                  )}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetalleOvertime;
