import { AlertaError } from '../components/general/Alerts';

const campoMensajes = {
  rut: 'Debe ingresar un rut valido.',
  nombre: 'Debe ingresar un nombre al menos.',
  apellido: 'Debe ingresar un apellido al menos.',
  region: 'Debe ingresar una region.',
  rut_proveedor:
    'Falta el rut del proveedor, refresque la pagina, de continuar el error contacte a soporte',
  fecha_ingreso: 'Debe ingresar una fecha de ingreso del recurso',
  tipo_contrato: 'Debe seleccionar un tipo de contrato',
  cargo: 'Debe ingresar un cargo',
};

export const validaNuevoRecurso = (data) => {
  const campoFaltante = Object.keys(campoMensajes).find((key) => !data[key]);
  if (campoFaltante) {
    const mensajeError = campoMensajes[campoFaltante];
    AlertaError({
      title: 'Incompleto',
      error: mensajeError,
    });
    return;
  }
  return true;
};
