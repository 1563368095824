import { ADMINISTRACION, PROVEEDOR, RECUPERAR } from './routes/rutas';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserContextProvider } from './context/UserContext';
import PublicRoute from './components/router/PublicRoute';
// LOGIN
import Login from './components/login/Login';
import RecuperarPassword from './components/login/Recuperar';

/********************
   ADMINISTRACION 
 *******************/
import AdminRoute from './components/router/AdminRoute';
import DashAdmin from './components/administracion/DashAdm';

// Proveedores
import VerProveedores from './components/administracion/proveedor/VerProveedores';
import CrearProveedor from './components/administracion/proveedor/CrearProveedor';
import DetalleProveedor from './components/administracion/proveedor/DetalleProveedor';

// Recursos
import VerRecursosAdm from './components/administracion/recursos/VerRecursosAdm';
import RecursosProyectos from './components/general/paginas/RecursosProyectos';
import VerRecursosDesvinculadosAdm from './components/administracion/recursos/VerRecursosDesvinculadosAdm';

// Sistema
import Cargos from './components/administracion/sistema/Cargos';
import TiposGastos from './components/administracion/sistema/TiposGastos';
import DocRecursosAdm from './components/administracion/documentos/DocRecursosAdm';
import Proyectos from './components/administracion/sistema/Proyectos';
import DocumentacionProveedor from './components/administracion/sistema/DocumentacionProveedor';
import EstadosSolicitudes from './components/administracion/sistema/EstadosSolicitudes';
import Usuarios from './components/administracion/sistema/Usuarios';
import DocumentacionRecursos from './components/administracion/sistema/DocumentacionRecursos';

// Gasto Adicionales
import GastosAdm from './components/administracion/gastos/Gastos';
import GastosDetalleAdm from './components/administracion/gastos/GastosDetalleAdm';
import DocGastosAdicionales from './components/administracion/documentos/DocGastosAdicionales';

// Overtime
import OvertimeAdm from './components/administracion/overtime/OvertimeAdm';
import OvertimeDetalleAdm from './components/administracion/overtime/OvertimeDetalleAdm';
import DocOvertimes from './components/administracion/documentos/DocOvertimes';

// Correos
import CorreosAdm from './components/administracion/sistema/CorreosAdm';

/********************
   PROVEEDOR 
 *******************/
import ProveedorRoute from './components/router/ProveedorRoute';
import DashProveedor from './components/proveedor/DashProveedor';
import VerRecursosProveedor from './components/proveedor/recurso/VerRecursosProveedor';
import DocRecursos from './components/proveedor/documentos/DocRecursos';
import Gastos from './components/proveedor/gastos/Gastos';
import GastosNuevo from './components/proveedor/gastos/GastosNuevo';
import GastoDetalleProveedor from './components/proveedor/gastos/GastoDetalleProveedor';
import CorreosProveedor from './components/proveedor/sistema/CorreosProveedor';
import Overtime from './components/proveedor/overtime/Overtime';
import OvtNuevo from './components/proveedor/overtime/OvtNuevo';
import DetalleOvertime from './components/proveedor/overtime/DetalleOvertime';
import DocumentosOvtProv from './components/proveedor/overtime/documentos/DocumentosOvtProv';
import DocumentosGastosProveedor from './components/proveedor/gastos/documentos/DocumentosGastosProveedor';

// RECURSOS
import CrearRecurso from './components/general/forms/CrearRecurso';
import EditarRecurso from './components/general/forms/EditarRecurso';
import VerRecursosDesvinculadosP from './components/proveedor/recurso/VerRecursosDesvinculadosP';

/********************
   SPOC 
 *******************/
import SpocRoute from './components/router/SpocRoute';
import DashSpoc from './components/spoc/DashSpoc';
import OvertimeSpoc from './components/spoc/overtime/OvertimeSpoc';
// import OvtNuevoSpoc from './components/spoc/overtime/OvtNuevoSpoc'; - ELIMINAR
import OvertimeDetalleSpoc from './components/spoc/overtime/OvertimeDetalleSpoc';
import VerRecursosSpoc from './components/spoc/recursos/VerRecursosSpoc';

// GENERAL
import DetalleRecursoDesvinculado from './components/general/paginas/DetalleRecursoDesvinculado';
import DetalleRecurso from './components/general/paginas/DetalleRecurso';
import OvtNuevoIbm from './components/general/forms/ovt/OvtNuevoIbm';
import NuevaSolicitudGastos from './components/general/gastos/nuevo/NuavaSolicitudGastos';
// Errores
import Error403 from './components/errores/Error403';

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Login />} />
            <Route path={RECUPERAR} element={<RecuperarPassword />} />
          </Route>

          <Route path={ADMINISTRACION} element={<AdminRoute />}>
            <Route index element={<DashAdmin />} />
            <Route path="proveedores" element={<VerProveedores />} />
            <Route path="proveedor/crear" element={<CrearProveedor />} />
            <Route path="proveedor/:id" element={<DetalleProveedor />} />

            <Route path="recursos" element={<VerRecursosAdm />} />
            <Route path="recurso/:id" element={<DetalleRecurso />} />
            <Route path="recurso/crear" element={<CrearRecurso />} />
            <Route path="recurso/editar/:id" element={<EditarRecurso />} />
            <Route path="recursos/proyectos" element={<RecursosProyectos />} />
            <Route
              path="recursos/desvinculados"
              element={<VerRecursosDesvinculadosAdm />}
            />
            <Route
              path="recurso/desvinculado/:id"
              element={<DetalleRecursoDesvinculado />}
            />

            <Route path="gastos" element={<GastosAdm />} />
            <Route path="gastos/preaprobacion" element={<GastosAdm />} />
            <Route path="gastos/justificacion" element={<GastosAdm />} />
            <Route path="gastos/aprobacion" element={<GastosAdm />} />
            <Route path="gastos/anuladas" element={<GastosAdm />} />
            <Route path="gastos/consolidar" element={<GastosAdm />} />
            <Route path="gastos/consolidados" element={<GastosAdm />} />
            <Route path="gastos/:id" element={<GastosDetalleAdm />} />
            <Route path="gastos/nueva" element={<NuevaSolicitudGastos />} />

            <Route path="overtime" element={<OvertimeAdm />} />
            <Route path="overtime/preaprobacion" element={<OvertimeAdm />} />
            <Route path="overtime/justificacion" element={<OvertimeAdm />} />
            <Route path="overtime/aprobacion" element={<OvertimeAdm />} />
            <Route path="overtime/consolidar" element={<OvertimeAdm />} />
            <Route path="overtime/consolidados" element={<OvertimeAdm />} />
            <Route path="overtime/anuladas" element={<OvertimeAdm />} />
            <Route path="overtime/:id" element={<OvertimeDetalleAdm />} />
            <Route path="overtime/nueva" element={<OvtNuevoIbm />} />

            <Route path="sistema/usuarios" element={<Usuarios />} />
            <Route path="sistema/cargos" element={<Cargos />} />
            <Route
              path="sistema/documentacion/proveedor"
              element={<DocumentacionProveedor />}
            />
            <Route
              path="sistema/documentacion/recursos"
              element={<DocumentacionRecursos />}
            />
            <Route path="sistema/proyectos" element={<Proyectos />} />
            <Route path="sistema/solestados" element={<EstadosSolicitudes />} />
            <Route path="sistema/tiposgastos" element={<TiposGastos />} />
            <Route path="informes/recursos" element={<DocRecursosAdm />} />
            <Route
              path="informes/gastoadicional"
              element={<DocGastosAdicionales />}
            />
            <Route path="informes/overtimes" element={<DocOvertimes />} />
            <Route path="sistema/correos" element={<CorreosAdm />} />
          </Route>

          {/* PROVEEDOR */}
          <Route path={PROVEEDOR} element={<ProveedorRoute />}>
            <Route index element={<DashProveedor />} />
            <Route path="recursos" element={<VerRecursosProveedor />} />
            <Route path="recurso/crear" element={<CrearRecurso />} />
            <Route path="recurso/editar/:id" element={<EditarRecurso />} />
            <Route path="recurso/:id" element={<DetalleRecurso />} />
            <Route
              path="recursos/desvinculados"
              element={<VerRecursosDesvinculadosP />}
            />
            <Route
              path="recurso/desvinculado/:id"
              element={<DetalleRecursoDesvinculado />}
            />
            <Route path="recursos/proyectos" element={<RecursosProyectos />} />
            <Route path="documentacion/recursos" element={<DocRecursos />} />

            <Route path="gastos" element={<Gastos />} />
            <Route path="gastos/nuevo" element={<GastosNuevo />} />
            <Route path="gastos/:id" element={<GastoDetalleProveedor />} />
            <Route
              path="gastos/documentos"
              element={<DocumentosGastosProveedor />}
            />

            <Route path="overtime" element={<Overtime />} />
            <Route path="overtime/nuevo" element={<OvtNuevo />} />
            <Route path="overtime/:id" element={<DetalleOvertime />} />
            <Route path="overtime/documentos" element={<DocumentosOvtProv />} />

            <Route path="sistema/correos" element={<CorreosProveedor />} />
          </Route>

          {/* SPOC */}
          <Route path={'spoc'} element={<SpocRoute />}>
            <Route index element={<DashSpoc />} />
            <Route path="recursos" element={<VerRecursosSpoc />} />
            <Route path="recurso/:id" element={<DetalleRecurso />} />
            <Route
              path="recurso/desvinculado/:id"
              element={<DetalleRecursoDesvinculado />}
            />

            <Route path="overtime" element={<OvertimeSpoc />} />
            <Route path="overtime/nueva" element={<OvtNuevoIbm />} />
            <Route path="overtime/:id" element={<OvertimeDetalleSpoc />} />
          </Route>

          {/* Errores */}
          <Route path="*" element={<Error403 />} />
        </Routes>
      </Router>
    </UserContextProvider>
  );
}

export default App;
