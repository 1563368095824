import { useEffect, useState } from 'react';

function DocOvertimes() {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const currentMonth = new Date().getMonth() + 1;
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let year = 2023; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYears(yearsArray);
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleDownload = (event) => {
    event.preventDefault();
    const mes = event.target.getAttribute('data-mes');
    const mesTexto = months[parseInt(mes) - 1].toUpperCase();
    const downloadUrl = `https://mappstorage.s3.amazonaws.com/gepro/overtimes/informes/administracion/${selectedYear}/OVERTIMES ${mesTexto} ${selectedYear}.xlsx`;

    const link = document.createElement('a');
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4>
              OVERTIMES <i className="bi bi-clock-fill"></i>
            </h4>
          </div>
          <div className="card-body">
            <div className="row row-cols-1 row-cols-xl-6">
              {months.map((month, index) => {
                if (
                  selectedYear === new Date().getFullYear() &&
                  index >= currentMonth
                ) {
                  return null;
                }
                return (
                  <div
                    className="card border border-secondary col-2 mx-1 shadow-sm p-1"
                    key={index}
                  >
                    <div className="card-header bg-transparent p-0 m-auto">
                      <div className="d-flex px-4 py-3">
                        <div className="rounded-circle rounded-5 py-0 px-2 bg-light">
                          <i
                            className="bi bi-file-earmark-spreadsheet"
                            style={{ fontSize: 35 }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0 m-0 border-top border-bottom border-secondary text-center">
                      <h5>{month}</h5>
                      <small className="text-muted">
                        Actualizado:{' '}
                        {`${index + 1 < 10 ? '0' : ''}${
                          index + 1
                        }-${selectedYear}`}
                      </small>
                    </div>
                    <div className="card-footer p-0 m-0 text-center text-white bg-primary">
                      <button
                        className="text-white border-0 bg-transparent"
                        data-mes={`${index + 1}`}
                        onClick={handleDownload}
                      >
                        Descargar
                        <i className="bi bi-download ms-2"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <div className="card">
          <div className="card-header">
            <h4>Mostrar año</h4>
          </div>
          <div className="card-body">
            <div className="d-flex">
              <div className="form-floating me-2 col-8">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Seleccionar año</label>
              </div>
              <button className="btn btn-outline-secondary col-4" type="button">
                Mostrar
              </button>
            </div>
          </div>
        </div>
        {/* Ultimos 6 meses */}
        <div className="card">
          <div className="card-header">
            <h4>Informe últimos 6 meses</h4>
          </div>
          <div className="card-body">
            <div className="d-flex">
              <a
                className="btn btn-outline-secondary btn-block col-4"
                href="https://mappstorage.s3.amazonaws.com/gepro/overtimes/informes/administracion/2023/OVERTIMES ULTIMOS 6 MESES 2023.xlsx"
                download
              >
                Descargar
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DocOvertimes;
