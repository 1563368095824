import { useRef, useState, useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import ExportarExcel from './ExportarExcel';
import CalendarioMeses from './forms/CalendarioMeses';

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

const customStyles = {
  rows: {
    style: {
      minHeight: '55px', // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      fontSize: '15px',
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },

  pagination: {
    style: {
      fontSize: '16px',
    },
    // pagination nav button
    pageButtonsStyle: {
      backgroundColor: '#2aa198',
    },
  },
};

createTheme(
  'solarized',
  {
    text: {
      primary: 'primary',
      secondary: '#2aa198',
    },
    background: {
      default: 'transparent',
    },
  },
  'dark'
);

function CustomTable({ columns, data, calendario, isLoading, excelName }) {
  const [dataFiltrada, setDataFiltrada] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    setDataFiltrada(data);
  }, [data]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    const filteredData = data.filter((row) =>
      Object.values(row).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setDataFiltrada(filteredData);
  };

  const handleClearFilter = () => {
    setSearchValue('');
    setDataFiltrada(data);
    searchInput.current.focus();
  };

  return (
    <>
      <div className="col-12 d-flex justify-content-between flex-column flex-md-row align-items-start">
        <div className="col-md-4 col-12">
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
              <i className="bi bi-search"></i>
            </span>
            <input
              ref={searchInput}
              type="text"
              className="form-control"
              placeholder="Buscar"
              autoFocus
              value={searchValue}
              onChange={handleSearch}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleClearFilter}
            >
              Limpiar
            </button>
          </div>
        </div>
        {calendario && <CalendarioMeses onMonthChange={calendario} />}
        <div className="col-md-3 col-12 mt-md-0 mt-3">
          {!isLoading && data.length > 0 ? (
            <ExportarExcel
              excelData={data}
              fileName={excelName}
              columnMapping={columns}
            />
          ) : null}
        </div>
      </div>

      <DataTable
        customStyles={customStyles}
        theme="solarized"
        columns={columns}
        data={dataFiltrada}
        fixedHeader
        pagination
        highlightOnHover
        progressPending={isLoading}
        progressComponent="Obteniendo información..."
        paginationComponentOptions={paginationComponentOptions}
        noDataComponent="No se encontraron registros"
      />
    </>
  );
}

export default CustomTable;
