import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import ComunasSelect from './ComunasSelect';
import RegionesSelect from './RegionesSelect';
import { CargosSelect } from './CargosSelect';
import { createRecurso } from '../../../services/recursos';
import {
  AlertaError,
  AlertaSuccess,
  AlertaConfirm,
} from '../../general/Alerts';
import useUser from '../../../hooks/useUser';
import Resizer from 'react-image-file-resizer';
import { SpinerLoad } from '../../general/loader/SpinerLoader';
import { useNavigate } from 'react-router-dom';
import { validateRut } from '../../../hooks/useValidaRut';
import { validaNuevoRecurso } from '../../../hooks/useValidaRecurso';

const CONTRATO_DEFINIDO = 1;
const CONTRATO_INDEFINIDO = 2;

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });
};

const generateId = () => {
  const num = Math.floor(Math.random() * 100000000);
  return `R${num.toString().padStart(8, '0')}`;
};

const CrearRecurso = () => {
  const navigate = useNavigate();
  const { role, dataUserLogged, apiToken } = useUser();
  const [data, setData] = useState({});
  const [isloading, setIsLoading] = useState(false);
  const [rut, setRut] = useState({ rut: '', isValid: false });
  const [selDefault, setSelDefault] = useState('');
  const [selectedProjects, setSelectedProjects] = useState({
    BCI: false,
    Entel: false,
    GetNet: false,
    Transbank: false,
    Orange: false,
  });

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      rut_proveedor: dataUserLogged.rut,
      updated_id: dataUserLogged.id,
    }));
  }, [dataUserLogged.id, dataUserLogged.rut]);

  const handleProjectChange = (e) => {
    setSelectedProjects({
      ...selectedProjects,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.id !== 'plazo' && e.target.checked === true) {
      const inputPlazo = document.getElementById('fechaPlazo');
      inputPlazo.value = '';
      inputPlazo.disabled = true;
    } else {
      document.getElementById('fechaPlazo').disabled = false;
    }
  };

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      resizeFile(file).then((uri) => {
        setData({ ...data, foto: uri });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRutChange = (e) => {
    e.preventDefault();
    const rut = validateRut(e.target.value);
    setRut(rut);
    setData({ ...data, rut: rut.rut });
  };

  // Select Region
  const seleccionRegion = (region) => {
    setData({ ...data, region: region.id });
  };

  // Select Comuna
  const seleccionComuna = (comuna) => {
    setData({ ...data, comuna: comuna.id });
  };

  // Select Cargo
  const seleccionCargo = (valor) => {
    setData({ ...data, cargo: valor });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    data.proyectos = selectedProjects;
    if (rut.isValid === false) {
      AlertaError({
        error: 'Rut no válido',
      });
      return;
    }
    if (validaNuevoRecurso(data)) {
      AlertaConfirm({
        title: '¿Crear recurso?',
        text: `Se creará el recurso ${data.nombre} ${data.apellido}`,
        callback: () => {
          setIsLoading(true);

          createRecurso(data, apiToken)
            .then((response) => {
              if (response.status === 200) {
                AlertaSuccess({
                  title: 'Recurso creado',
                  text: `El recurso ${data.nombre} ${data.apellido} ha sido creado exitosamente`,
                });
                setData({});
                setSelDefault(generateId());
                navigate(-1);
              } else {
                AlertaError({
                  text: 'Error al crear el recurso, por favor intente nuevamente',
                });
              }
            })
            .catch((error) => {
              AlertaError({
                text: 'Error al crear el recurso, por favor intente nuevamente',
              });
              console.log(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
      });
    }
  };

  const handleResetForm = () => {
    setData({ rut_proveedor: dataUserLogged.rut });
    setSelDefault(generateId());
    setRut({ rut: '', isValid: false });
  };

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Formulario Nuevo Recurso</h4>
        </div>
        <div className="card-body">
          <form
            className="form"
            data-parsley-validate
            onSubmit={handleSubmit}
            id="form-create-recurso"
          >
            <div className="row">
              {/* RUT */}
              <div className="col-md-2 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-rut" className="form-label">
                    RUT <small>{rut.isValid ? 'Valido' : 'Invalido'}</small>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="input-rut"
                    name="rut"
                    value={rut.rut}
                    onChange={handleRutChange}
                  />
                </div>
              </div>
              {/* Nombres */}
              <div className="col-md-4 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-nombre" className="form-label">
                    Nombres
                  </label>
                  <input
                    type="text"
                    id="input-nombre"
                    className="form-control"
                    placeholder="Nombre"
                    name="nombre"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Apellidos */}
              <div className="col-md-4 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-apellidos" className="form-label">
                    Apellidos
                  </label>
                  <input
                    type="text"
                    id="input-apellidos"
                    className="form-control"
                    placeholder="Apellidos"
                    name="apellido"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Fecha Nacimiento */}
              <div className="col-md-2 col-12">
                <div className="form-group">
                  <label htmlFor="input-fechaNacimiento" className="form-label">
                    Fecha Nacimiento
                  </label>
                  <input
                    type="date"
                    id="input-fecha_nacimiento"
                    className="form-control"
                    name="fecha_nacimiento"
                    onChange={handleChange}
                    placeholder="Giro"
                  />
                </div>
              </div>
              {/* Direccion */}
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="input-direccion" className="form-label">
                    Dirección
                  </label>
                  <input
                    type="text"
                    id="input-direccion"
                    className="form-control"
                    name="direccion"
                    onChange={handleChange}
                    placeholder="Direccion"
                  />
                </div>
              </div>
              {/* Aca van los select region comuna */}
              <div className="col-md-3 col-12">
                <RegionesSelect
                  seleccion={seleccionRegion}
                  selDefault={selDefault}
                />
              </div>
              <div className="col-md-3 col-12">
                <ComunasSelect
                  seleccion={seleccionComuna}
                  selDefault={selDefault}
                />
              </div>

              {/* Telefono */}
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="input-telefono" className="form-label">
                    Teléfono
                  </label>
                  <InputMask
                    className="form-control"
                    id="input-telefono"
                    name="telefono"
                    onChange={handleChange}
                    mask={'+56 9 9999 9999'}
                  />
                </div>
              </div>
              {/* Email */}
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="input-email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="input-email"
                    className="form-control"
                    name="email"
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </div>
              </div>
              {/* Foto */}
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="input-foto" className="form-label">
                    Foto
                  </label>
                  <input
                    type="file"
                    id="input-foto"
                    className="form-control"
                    name="foto"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              {/* Informacion Laboral */}
              <div className="divider divider-left">
                <div className="divider-text">Información Laboral</div>
              </div>
              {/* Rut Proveedor */}
              <div className="col-md-2 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-rut-proveedor" className="form-label">
                    RUT Proveedor
                  </label>
                  <InputMask
                    className="form-control"
                    mask={'99999999-*'}
                    id="input-rut-proveedor"
                    onChange={handleChange}
                    defaultValue={dataUserLogged.rut}
                    name="rut_proveedor"
                    disabled={role !== 'Administracion' ? true : false}
                  />
                </div>
              </div>
              {/* Fecha Ingreso */}
              <div className="col-md-2 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-ingreso" className="form-label">
                    Fecha Ingreso
                  </label>
                  <input
                    type="date"
                    id="input-ingreso"
                    className="form-control"
                    name="fecha_ingreso"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Tipo de Contrato */}
              <div className=" form-group mandatory col-md-4 col-12">
                <label className="form-label d-block">
                  Tipo de Contrato / Termino Plazo
                </label>
                <div className="row">
                  <div className="col-6" onChange={handleChange.bind(this)}>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        id="fijo"
                        type="radio"
                        name="tipo_contrato"
                        value={CONTRATO_DEFINIDO}
                      />
                      <label className="form-label" htmlFor="fijo">
                        Indefinido
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        id="plazo"
                        type="radio"
                        name="tipo_contrato"
                        value={CONTRATO_INDEFINIDO}
                      />
                      <label className="form-check-label" htmlFor="plazo">
                        Plazo
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <input
                      type="date"
                      className="form-control"
                      id="fechaPlazo"
                      name="fecha_termino"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {/* Select Cargos */}
              <CargosSelect
                seleccion={seleccionCargo}
                selDefault={selDefault}
              />
              {/* Proyectos */}
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="projects" className="form-label">
                    Proyectos
                  </label>
                  <div id="projects" onChange={handleProjectChange}>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="BCI"
                        id="bci"
                      />
                      <label className="form-check-label" htmlFor="bci">
                        BCI
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Entel"
                        id="entel"
                      />
                      <label className="form-check-label" htmlFor="entel">
                        Entel
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="GetNet"
                        id="getnet"
                      />
                      <label className="form-check-label" htmlFor="getnet">
                        GetNet
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Transbank"
                        id="transbank"
                      />
                      <label className="form-check-label" htmlFor="transbank">
                        Transbank
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Orange"
                        id="orange"
                      />
                      <label className="form-check-label" htmlFor="orange">
                        Orange
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end">
                {isloading ? <SpinerLoad /> : null}
                <button type="submit" className="btn btn-primary me-1 mb-1">
                  Agregar
                </button>
                <button
                  type="reset"
                  className="btn btn-light-secondary me-1 mb-1"
                  onClick={handleResetForm}
                >
                  Borrar Form
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CrearRecurso;
