import React, { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { createCargo, getCargos, deleteCargo } from '../../../services/cargos';
import {
  AlertaError,
  AlertaSuccess,
  AlertaConfirm,
} from '../../general/Alerts';

const initialState = {
  id: '',
  cargo: '',
  descripcion: '',
};

function Cargos() {
  const { apiToken } = useUser();

  const [cargos, setCargos] = useState([]);
  const [cargo, setCargo] = useState({ ...initialState });

  // get data
  const getData = (apiToken) => {
    getCargos(apiToken).then((data) => {
      setCargos(data.cargos);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  // handleChange
  const handleChange = (e) => {
    setCargo({
      ...cargo,
      [e.target.name]: e.target.value,
    });
  };

  // cargo edit
  const handleCargoClicked = (cargo) => {
    setCargo(cargo);
  };

  // delete cargo
  const handleDeleteCargo = (cargo) => {
    AlertaConfirm({
      title: '¿Eliminar cargo?',
      text: `Se eliminara ${cargo.cargo}`,
      callback: () => {
        deleteCargo(cargo.id, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({ message: 'Cargo eliminado correctamente' });
            getData(apiToken);
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  // handleSubmit and update cargos if success
  const handleSubmit = (e) => {
    e.preventDefault();
    AlertaConfirm({
      title: '¿Crear cargo?',
      text: `Se creara ${cargo.cargo}`,
      callback: () => {
        createCargo(cargo, apiToken).then((data) => {
          if (data.status === 200) {
            if (cargo.id === '') {
              setCargos([...cargos, data.cargo]);
            } else {
              const cargosUpdated = cargos.map((cargoItem) => {
                if (cargoItem.id === cargo.id) {
                  return cargo;
                }
                return cargoItem;
              });
              setCargos(cargosUpdated);
            }
            setCargo({ ...initialState });
            document.getElementById('form-create-cargo').reset();
            AlertaSuccess({ message: 'Cargo creado correctamente' });
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>Cargos de los recursos</h3>
          {/* table */}
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>Cargo</th>
                <th>Descripción</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {cargos.map((cargo) => (
                <tr key={cargo.id}>
                  <td>{cargo.cargo}</td>
                  <td>{cargo.descripcion}</td>
                  <td>
                    <div className="col-12 d-flex justify-content-end">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleCargoClicked(cargo)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-danger btn-sm ms-1"
                        onClick={() => handleDeleteCargo(cargo)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Nuevo Cargo</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                onSubmit={handleSubmit}
                id="form-create-cargo"
              >
                <div className="row">
                  {/* Cargo */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-cargo" className="form-label">
                        Cargo
                      </label>
                      <input
                        type="text"
                        id="input-nombre"
                        className="form-control"
                        placeholder="Cargo"
                        name="cargo"
                        value={cargo.cargo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Descripcion */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="input-descripcion" className="form-label">
                        Descripción
                      </label>
                      <input
                        type="text"
                        id="input-descripcion"
                        className="form-control"
                        name="descripcion"
                        value={cargo.descripcion}
                        onChange={handleChange}
                        placeholder="Descripción"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                      onClick={() => setCargo({ ...initialState })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cargos;
