// formatear fecha
export function formatDate(date) {
  if (date) {
    const newDate = new Date(date);
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1;
    let dd = newDate.getDate() + 1;
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return `${dd}-${mm}-${yyyy}`;
  } else return 'Sin datos';
}

// formatear fecha y hora
export function formatDateTime(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();
  let hour = '' + d.getHours();
  let minute = '' + d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;

  return (
    [day, month, year].join('-') + ' a las ' + [hour, minute].join(':') + ' hrs'
  );
}

// calcural edad
export function calcularEdad(date) {
  if (date) {
    const hoy = new Date();
    const cumpleanos = new Date(date);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    const m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return `( ${edad} años )`;
  } else return '';
}
