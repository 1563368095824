import { apiUrl } from '../config';

// get proyectos
export const getProyectos = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/proyectos')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// create proyecto
export const createProyecto = async (proyecto, apiToken) => {
  const response = await fetch(`${apiUrl('administracion/proyectos/crear')}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(proyecto),
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// delete proyecto
export const deleteProyecto = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('administracion/proyectos')}/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};
