import { useState } from 'react';
import RegionesSelect from '../../general/forms/RegionesSelect';
import ComunasSelect from '../../general/forms/ComunasSelect';
import { createProveedor } from '../../../services/proveedores';
import {
  AlertaError,
  AlertaConfirm,
  AlertaSuccess,
} from '../../general/Alerts';
import useUser from '../../../hooks/useUser';
import Resizer from 'react-image-file-resizer';
import InputMask from 'react-input-mask';
import { SpinerLoad } from '../../general/loader/SpinerLoader';

// Objeto con las propiedades iniciuales del proveedor
const initialState = {
  rut: '',
  nombre: '',
  razon_social: '',
  giro: '',
  direccion: '',
  region: '',
  comuna: '',
  telefono: '',
  email: '',
  imagen: '',
};

// file resizer
const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });
};

const generateId = () => {
  const num = Math.floor(Math.random() * 100000000);
  return `R${num.toString().padStart(8, '0')}`;
};

function CrearProveedor() {
  const { apiToken } = useUser();
  const [data, setData] = useState({ ...initialState });
  const [isloading, setIsloading] = useState(false);
  const [selDefault, setSelDefault] = useState('');

  // select Region
  function seleccionRegion(valor) {
    setData({ ...data, region: valor });
  }

  // select Comuna
  function seleccionComuna(valor) {
    setData({ ...data, comuna: valor });
  }

  // al cambiar el value en los imputs
  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  // al cambiar el input de logo
  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      resizeFile(file).then((uri) => {
        setData({ ...data, imagen: uri });
      });
    } catch (error) {
      console.log(error);
    }
  };

  // al enviar el formulario
  const handleSubmit = (event) => {
    event.preventDefault();
    if (data.rut !== '' && data.nombre !== '' && data.region !== '') {
      AlertaConfirm({
        title: '¿Crear proveedor?',
        text: `Se creara el proveedor ${data.nombre}`,
        callback: () => {
          setIsloading(true);
          createProveedor(data, apiToken)
            .then((response) => {
              if (response.status === 200) {
                AlertaSuccess({
                  title: 'Proveedor creado',
                  success: `Se ha creado el proveedor ${data.nombre}`,
                  callback: () => {
                    setData({ ...initialState });
                    //  reset form
                    document.getElementById('form-create-proveedor').reset();
                    setSelDefault(generateId());
                    setIsloading(false);
                  },
                });
              } else {
                AlertaError({
                  title: response.message,
                  text: response.errors,
                });
                setIsloading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              AlertaError({
                error: 'Ha ocurrido un error al crear el proveedor',
              });
              setIsloading(false);
            });
        },
      });
    } else {
      AlertaError({ error: 'Debe completar los campos requeridos' });
    }
  };

  const handleResetForm = () => {
    setData({ ...initialState });
    setSelDefault(generateId());
  };

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Formulario de Registro</h4>
        </div>
        <div className="card-body">
          <form
            className="form"
            data-parsley-validate
            onSubmit={handleSubmit}
            id="form-create-proveedor"
          >
            <div className="row">
              <div className="col-md-3 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-rut" className="form-label">
                    RUT <small>sin puntos, con guion</small>
                  </label>
                  <InputMask
                    // last character not number
                    className="form-control"
                    mask={'99999999-*'}
                    id="input-rut"
                    name="rut"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="form-group mandatory">
                  <label htmlFor="input-nombre" className="form-label">
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="input-nombre"
                    className="form-control"
                    placeholder="Nombre"
                    name="nombre"
                    onChange={handleInputChange}
                    data-parsley-required="true"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="input-razon-social" className="form-label">
                    Razón Social
                  </label>
                  <input
                    type="text"
                    id="input-razon-social"
                    className="form-control"
                    placeholder="Razon Social"
                    name="razon_social"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="input-giro" className="form-label">
                    Giro Comercial
                  </label>
                  <input
                    type="text"
                    id="input-giro"
                    className="form-control"
                    name="giro"
                    onChange={handleInputChange}
                    placeholder="Giro"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="input-direccion" className="form-label">
                    Direccion
                  </label>
                  <input
                    type="text"
                    id="input-direccion"
                    className="form-control"
                    name="direccion"
                    onChange={handleInputChange}
                    placeholder="Company"
                  />
                </div>
              </div>
              {/* Aca van los select region */}
              <RegionesSelect
                seleccion={seleccionRegion}
                selDefault={selDefault}
              />
              {/* Aca van los select comunas */}
              <ComunasSelect
                seleccion={seleccionComuna}
                selDefault={selDefault}
              />
              {/* Telefono */}
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="input-telefono" className="form-label">
                    Teléfono
                  </label>
                  <input
                    type="number"
                    id="input-telefono"
                    className="form-control"
                    name="telefono"
                    onChange={handleInputChange}
                    placeholder="56xxxxxxxxx"
                  />
                </div>
              </div>
              {/* Email */}
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="input-email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="input-email"
                    className="form-control"
                    name="email"
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                </div>
              </div>

              {/* Logo */}
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label htmlFor="input-logo" className="form-label">
                    Logo
                  </label>
                  <input
                    type="file"
                    id="input-logo"
                    className="form-control"
                    name="imagen"
                    onChange={handleFileChange}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end">
                {isloading ? <SpinerLoad /> : null}
                <button type="submit" className="btn btn-primary me-1 mb-1">
                  Agregar
                </button>
                <button
                  type="reset"
                  className="btn btn-light-secondary me-1 mb-1"
                  onClick={handleResetForm}
                >
                  Borrar Form
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default CrearProveedor;
