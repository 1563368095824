import React, { useState, useEffect } from 'react';

const CalendarioMeses = ({ onMonthChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    setToday(new Date());
    setCurrentMonth(new Date(today.getFullYear(), today.getMonth(), 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextMonth = () => {
    const newDate = new Date(
      currentMonth.setMonth(currentMonth.getMonth() + 1)
    );
    setCurrentMonth(newDate);
  };

  const prevMonth = () => {
    const newDate = new Date(
      currentMonth.setMonth(currentMonth.getMonth() - 1)
    );
    setCurrentMonth(newDate);
  };

  const formatDate = (date) => {
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  };

  const isPrevMonthDisabled = () => {
    const prevMonthDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );
    return prevMonthDate < new Date(2023, 2, 1);
  };

  const handleSelectClick = () => {
    const monthNumber = currentMonth.getMonth();
    const yearNumber = currentMonth.getFullYear();
    onMonthChange({ month: monthNumber + 1, year: yearNumber });
  };

  return (
    <div className="">
      <button
        onClick={prevMonth}
        disabled={isPrevMonthDisabled()}
        className="btn btn-light-primary btn-sm me-3"
      >
        <i className="bi bi-arrow-left-circle"></i>
      </button>
      <span>{formatDate(currentMonth)}</span>
      <button className="btn btn-light-primary btn-sm ms-3" onClick={nextMonth}>
        <i className="bi bi-arrow-right-circle"></i>
      </button>
      <button
        className="btn btn-light-primary btn-sm ms-2"
        onClick={handleSelectClick}
      >
        <i className="bi bi-search"></i> Ver mes
      </button>
      <button
        className="btn btn-light-primary btn-sm ms-2"
        onClick={() => onMonthChange({ month: null, year: null })}
      >
        Todas
      </button>
    </div>
  );
};

export default CalendarioMeses;
