import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getProyectos } from '../../../services/proyectos.js';

function ProyectosSelect({ onSelect, selDefault, ocultarInfo }) {
  const { apiToken } = useUser();
  const [proyectos, setProyectos] = useState([]);
  const [proyecto, setProyecto] = useState();

  useEffect(() => {
    getProyectos(apiToken).then((data) => {
      setProyectos(data.proyectos);
    });
  }, [apiToken]);

  // setea el valor por defecto del select la region pasada
  useEffect(() => {
    if (selDefault.length >= 8) {
      setProyecto('');
    } else {
      setProyecto(selDefault);
    }
  }, [selDefault]);

  const handleChange = (id) => {
    const proyectoSeleccionado = proyectos.find(
      (proyecto) => proyecto.id === id
    );
    onSelect(proyectoSeleccionado);
    setProyecto(id);
  };

  return (
    <>
      <div className="form-group mandatory">
        <label htmlFor="input-proyecto" className="form-label">
          Proyecto
        </label>
        <select
          value={proyecto}
          className="form-select"
          id="basicSelect"
          name="proyecto"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        >
          <option value={''} disabled>
            Seleccionar proyecto
          </option>
          {proyectos.map((proyecto) => (
            <option key={proyecto.id} value={proyecto.id}>
              {proyecto.proyecto}
            </option>
          ))}
        </select>
        {/* link to open modal */}
        {!ocultarInfo ? (
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#modalProyecto"
            className="text-decoration-none"
          >
            <i className="bi bi-info-circle"></i> Información de proyectos
          </Link>
        ) : null}
      </div>
      {/* Modal */}
      <div
        className="modal fade text-left"
        id="modalProyecto"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProyectoLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalProyectoLabel1">
                Detalle de Proyectos
              </h5>
              <button
                type="button"
                className="close rounded-pill"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Proyecto</th>
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  {proyectos.map((proyecto) => (
                    <tr key={proyecto.id}>
                      <td>{proyecto.proyecto}</td>
                      <td>{proyecto.descripcion}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">
                <i className="bx bx-x d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Cerrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProyectosSelect;
