import Chart from 'react-apexcharts';

function GastosProveedoresPie({ datos }) {
  let series = [];
  let options = {};

  if (datos) {
    series = datos.map((item) => Number(item.solicitudes));

    const colors = datos.map((item) => {
      switch (item.nombre) {
        case 'Eikorp Ltda.':
          return '#FF8000'; // naranja
        case 'HyO Ltda.':
          return '#01DF3A'; // verde
        case 'SSI S.A.':
          return '#00BFFF'; // celeste
        case 'Stefanini':
          return '#0B0B61'; // azul
        default:
          return '#D3D3D3'; // gris como color por defecto
      }
    });

    options = {
      labels: datos.map((item) => item.nombre),
      series: series,
      colors: colors,
    };
  }

  return <Chart options={options} series={series} type="donut" width={450} />;
}

export default GastosProveedoresPie;
