import { apiUrl } from '../config';

//  create overtime
export const createOvertime = async (data, apiToken) => {
  const formData = new FormData();
  formData.append('solicitante_id', data.solicitante_id);
  formData.append('fecha_ovt', data.fecha_ovt);
  formData.append('proveedor_rut', data.proveedor_rut);
  formData.append('recurso_rut', data.recurso_rut);
  formData.append('observacion', data.observacion);
  formData.append('dia_feriado', data.dia_feriado);
  formData.append('tipo_dia', data.tipo_dia);
  formData.append('horas_solicitadas', data.horas_solicitadas);

  try {
    const response = await fetch(`${apiUrl('proveedor/overtime/nuevo')}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const res = await response.json();
    res.status = response.status;
    return res;
  } catch (error) {
    console.log(error);
  }
};

// aprobar overtime
export const preAprobacionGastos = async (data, apiToken) => {
  const formData = new FormData();
  formData.append('id', data.id);
  formData.append('id_validador', data.id_validador);
  formData.append('validacion', data.validacion);
  formData.append('comentario_preaprobacion', data.comentario_preaprobacion);
  const response = await fetch(
    `${apiUrl('administracion/overtime')}/preaprobacion`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  return response.json();
};

// Enviar el Json data al overtime
export const addTicketOvertime = async (data, apiToken) => {
  const addTicketPromises = data.tickets.map(async (ticket) => {
    const formData = new FormData();
    formData.append('fecha', data.fecha);
    formData.append('overtime_id', ticket.overtime_id);
    formData.append('ticket', ticket.ticket);
    formData.append('hora_inicio', ticket.hora_inicio);
    formData.append('hora_termino', ticket.hora_termino);
    formData.append('horas_ticket', ticket.horas_ticket);
    formData.append('proyecto_id', ticket.proyecto_id);
    formData.append('direccion', ticket.direccion || null);
    formData.append('comuna_id', ticket.comuna_id);

    for (const key in ticket) {
      if (ticket.hasOwnProperty(key)) {
        if (key === 'direccion' && ticket.direccion !== undefined) {
          formData.append('direccion', ticket.direccion);
        } else if (key === 'region_id' && ticket.region_id !== undefined) {
          formData.append('region_id', ticket.region_id);
        } else if (key === 'file' && ticket.file !== undefined) {
          formData.append('file', ticket.file);
        }
      }
    }

    const res = await fetch(`${apiUrl('spoc/overtime/addticket')}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    return await res.json();
  });

  const deleteTicketPromises = data.tickets_eliminados.map(async (ticket) => {
    const $id = ticket.id;
    const res = await fetch(`${apiUrl('spoc/overtime/deleteticket')}/${$id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    return await res.json();
  });

  const allPromises = [...addTicketPromises, ...deleteTicketPromises];

  return Promise.all(allPromises)
    .then((responses) => {
      const data = {
        status: 200,
        message: 'Overtime actualizado correctamente',
      };
      return data;
    })
    .catch((error) => {
      console.error(error);
      throw new Error('Error');
    });
};

// Obtiene overtimes segun proveedor
export const getOvertimesProveedor = async (apiToken, id, mes, ano) => {
  const response = await fetch(
    `${apiUrl('proveedor/overtimes/')}${id}/${mes}/${ano}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene un overtime segun id
export const getOvertime = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/overtime/')}${id}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene todos los overtimes del mes actual
export const getOvertimesMes = async (apiToken, mes, ano) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/mes/')}${mes}/${ano}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Justificar Overtime
export const actualizarOvertime = async (data, apiToken) => {
  // console.log(data);
  const response = await fetch(`${apiUrl('spoc/overtime/actualizar')}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const res = await response.json();
  res.status = response.status;
  return res;
};

// Obtener estadisticas Overtime
export const getEstadisticasOvertime = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/estadisticas')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener Overtimes pendientes prearpobacion
export const getOvertimesPendientesPreaprobacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/pendientes/1')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener Overtimes pendientes justificacion
export const getOvertimesPendientesJustificacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/pendientes/2')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener Overtimes pendientes aprobacion
export const getOvertimesPendientesAprobacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/pendientes/3')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener Overtimes pendientes consolidacion
export const getOvertimesPendientesConsolidacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/pendientes/4')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener Overtimes anulados
export const getOvertimesAnulados = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/pendientes/6')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener Overtimes anulados
export const getOvertimesConsolidados = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/overtimes/pendientes/5')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener estadisticas Overtime SPOC
export const getEstadisticasOvertimeSpoc = async (apiToken) => {
  const response = await fetch(`${apiUrl('spoc/overtimes/estadisticas')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener estadisticas Overtime Proveedor
export const getEstadisticasOvertimeProveedor = async (rut, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/overtimes/estadisticas/')}${rut}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};
