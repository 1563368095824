import { useCallback, useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  getRecursosProyectos,
  getRecursosProyectosByProveedor,
  updateRecursosProyectos,
} from '../../../services/recursos';
import { AlertaConfirm, AlertaError, AlertaSuccess } from '../Alerts';
import CustomTable from '../CustomTable';
import { SpinerDownload } from '../loader/SpinerLoader';

const projectNames = {
  5: 'BCI',
  1: 'Entel',
  4: 'GetNet',
  2: 'Transbank',
  8: 'Orange',
};

const createProjectColumns = (
  projectNames,
  handleCheckboxChange,
  proyectosEdit
) => {
  return Object.entries(projectNames).map(([id, name]) => ({
    name: name,
    cell: (row) => {
      const projectEdit = proyectosEdit.find(
        (pe) => pe.id_recurso === row.id_recurso && pe.id_proyecto === id
      );
      const project = row.proyectos.find((p) => p.id_proyecto === id);
      let isActive;
      if (projectEdit) {
        isActive = projectEdit.activo === '1';
      } else {
        isActive = project ? project.activo === '1' : false;
      }
      return (
        <input
          type="checkbox"
          checked={isActive}
          onChange={(event) =>
            handleCheckboxChange(row, id, event.target.checked)
          }
        />
      );
    },
    clave: `id_proyecto_${id}`,
  }));
};

function RecursosProyectos() {
  const { apiToken, dataUserLogged, role } = useUser();
  const [usuarios, setUsuarios] = useState([]);
  const [proyectosEdit, setProyectosEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getRecProys = useCallback(async () => {
    console.log(role);
    const res =
      role === 'Administracion'
        ? await getRecursosProyectos(apiToken)
        : await getRecursosProyectosByProveedor(dataUserLogged.rut, apiToken);

    if (res && res.status === 200 && res.recursos) {
      setUsuarios(res.recursos);
    }
    setIsLoading(false);
  }, [apiToken, dataUserLogged.rut, role]);

  const handleCheckboxChange = (row, projectId, checked) => {
    const newProyectoEdit = {
      id_recurso: row.id_recurso,
      id_proyecto: projectId,
      activo: checked ? '1' : '0',
    };
    setProyectosEdit((prevProyectosEdit) => [
      ...prevProyectosEdit.filter(
        (pe) => pe.id_recurso !== row.id_recurso || pe.id_proyecto !== projectId
      ),
      newProyectoEdit,
    ]);
  };

  useEffect(() => {
    getRecProys();
  }, [getRecProys]);

  const columns = [
    {
      name: 'Proveedor',
      selector: (row) => row.proveedor,
      sortable: true,
      clave: 'proveedor',
      omit: role !== 'Administracion',
    },
    {
      name: 'Recurso',
      selector: (row) => row.nombre,
      sortable: true,
      clave: 'nombre',
    },
    {
      name: '-',
      selector: (row) => row.apellido,
      sortable: true,
      clave: 'apellido',
    },
    {
      name: 'Cargo',
      selector: (row) => row.cargo,
      sortable: true,
      clave: 'cargo',
    },
    ...createProjectColumns(projectNames, handleCheckboxChange, proyectosEdit),
  ];

  const handleSend = async () => {
    AlertaConfirm({
      title: '¿Actualizar Información?',
      text: 'Se actualizaran los registros modificados',
      callback: async (result) => {
        const res = await updateRecursosProyectos(proyectosEdit, apiToken);
        if (res && res.status === 200) {
          AlertaSuccess({
            title: 'Información Actualizada',
            text: 'Los cambios se han guardado correctamente',
          });
          setProyectosEdit([]);
          getRecProys();
        } else {
          AlertaError({
            title: 'Error al Actualizar',
            text: 'No se ha podido actualizar la información',
          });
        }
      },
    });
  };

  return (
    <>
      {isLoading && <SpinerDownload />}
      <section className="section">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <h3>Relacion Recursos / Proyectos</h3>
            <CustomTable columns={columns} data={usuarios} />
          </div>
          <div
            className="col-3"
            style={{ position: 'fixed', top: 200, right: 20 }}
          >
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Información</h4>
              </div>
              <div className="card-body">
                <p>
                  En esta seccion se pueden asignar los proyectos en los que
                  participa cada recurso.
                </p>
                <div className="col-12 d-flex justify-content-center mt-5">
                  <button className="btn btn-primary" onClick={handleSend}>
                    <i className="bi bi-cloud-arrow-up"></i> Actualizar Cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecursosProyectos;
