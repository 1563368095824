import React, { useState } from 'react';

const Context = React.createContext({});

const roles = ['Unautorized', 'Administracion', 'Proveedor', 'Spoc'];

// provider user logged
export function UserContextProvider({ children }) {
  const [user, setUser] = useState(() =>
    JSON.parse(window.sessionStorage.getItem('user'))
  ); // user logged
  const [jwt, setJwt] = useState(() =>
    window.sessionStorage.getItem('api_token')
  ); // token jwt

  const [role, setRole] = useState(
    () => roles[JSON.parse(window.sessionStorage.getItem('role'))]
  ); // role user logged

  return (
    <Context.Provider value={{ user, setUser, jwt, setJwt, role, setRole }}>
      {children}
    </Context.Provider>
  );
}

export default Context;
