import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function MenuAdmin() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menú</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/administracion" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'proveedores' || path[2] === 'proveedor'
              ? 'active'
              : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-person-video2"></i>
            <span>Proveedores</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/proveedores">Ver Todos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/proveedor/crear">Crear Nuevo</Link>
            </li>
          </ul>
        </li>
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'recursos' || path[2] === 'recurso' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-collection-fill"></i>
            <span>Recursos</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/recursos">Ver Todos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/recurso/crear">Crear Nuevo</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/recursos/proyectos">Proyectos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/recursos/desvinculados">
                Desvinculados
              </Link>
            </li>
          </ul>
        </li>

        {/* GASTO ADICIONAL */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'gastos' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-coin"></i>
            <span>Gastos Adicionales</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/gastos">Ir</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/gastos/preaprobacion">
                Pendientes Preaprobación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/gastos/justificacion">
                Pendientes Justificación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/gastos/aprobacion">
                Pendientes Aprobación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/gastos/consolidar">
                Pendientes Consolidación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/gastos/consolidados">Consolidados</Link>
            </li>
            {/* <li className="submenu-item">
              <Link to="/administracion/gastos/nueva">
                Nueva solicitud <i className="bi bi-cash-coin text-success"></i>
              </Link>
            </li> */}
            <li className="submenu-item">
              <Link to="/administracion/gastos/anuladas">
                Solicitudes Anuladas{' '}
                <i className="bi bi-trash3 text-danger"></i>
              </Link>
            </li>
          </ul>
        </li>

        {/* OVERTIME */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'ovt' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-clock-fill"></i>
            <span>Overtime</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/overtime">Ir</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/preaprobacion">
                Pendientes Preaprobación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/justificacion">
                Pendientes Justificación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/aprobacion">
                Pendientes Aprobación
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/consolidar">
                Pendientes Consolidacion
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/consolidados">
                Consolidados
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/nueva">
                Nueva solicitud <i className="bi bi-stopwatch text-success"></i>
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/overtime/anuladas">
                Solicitudes Anuladas{' '}
                <i className="bi bi-trash3 text-danger"></i>
              </Link>
            </li>
          </ul>
        </li>

        {/* Informes */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'informes' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-archive-fill"></i>
            <span>Informes</span>
          </Link>
          <ul className="submenu">
            {/* <li className="submenu-item">
              <Link to="/proveedor/documentos/empresa">Empresa</Link>
            </li> */}
            <li className="submenu-item">
              <Link to="/administracion/informes/recursos">Recursos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/informes/gastoadicional">
                Gastos Adicionales
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/informes/overtimes">Overtimes</Link>
            </li>
          </ul>
        </li>
        {/* RECLAMOS */}
        {/* <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'reclamos' || path[2] === 'reclamo' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-exclamation-circle-fill"></i>
            <span>Reclamos</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/reclamo/nuevo">Generar Reclamo</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/reclamos/pendientes">
                Pendientes de Revision
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/reclamos">Ver Todos</Link>
            </li>
          </ul>
        </li> */}
        {/* ACTIVOS IBM */}
        {/* <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'activos' || path[2] === 'activo' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-bag-check-fill"></i>
            <span>Activos</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/activo/asignar">Asignar Activo</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/activos">Ver Todos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/activo/incidencia">
                Reportar Incidencia
              </Link>
            </li>
          </ul>
        </li> */}
        {/* SISTEMA */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'sistema' ? 'active' : '')
          }
        >
          <Link to="#" className="sidebar-link">
            <i className="bi bi-tools"></i>
            <span>Sistema</span>
          </Link>

          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/administracion/sistema/cargos">Gestion Cargos</Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/sistema/proyectos">
                Gestion Proyectos
              </Link>
            </li>
            <li className="submenu-item disabled">
              <Link to="/administracion/sistema/documentacion/proveedor">
                Documentacion Proveedores
              </Link>
            </li>
            <li className="submenu-item disabled">
              <Link to="/administracion/sistema/documentacion/recursos">
                Documentacion Recursos
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/sistema/usuarios">
                Gestionar Usuarios
              </Link>
            </li>
            <div className="divider-text">Gastos</div>
            <li className="submenu-item">
              <Link to="/administracion/sistema/solestados">
                Estados Solicitudes
              </Link>
            </li>
            <li className="submenu-item">
              <Link to="/administracion/sistema/tiposgastos">
                Tipos de Gastos
              </Link>
            </li>
            <div className="divider-text">Correos Administracion</div>
            <li className="submenu-item">
              <Link to="/administracion/sistema/correos">Correos</Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-item">
          {/* call to logout */}
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuAdmin;
