import { useEffect, useRef, useState } from 'react';
import { editarMontoJustificado } from '../../../../services/gastosAdicionales';

function ModalEditarJustificado({ data, setActualizarData }) {
  const [dataItem, setDataItem] = useState(null);
  const inputMontoCorreccion = useRef(null);
  const inputMontoJustificado = useRef(null);

  useEffect(() => {
    setDataItem(data);
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.monto_justificado !== null) {
        inputMontoCorreccion.current.value = data.monto_justificado;
        inputMontoJustificado.current.value = data.monto_justificado;
      }
    }
  }, [data]);

  const handleUpload = () => {
    const { monto_justificado, monto_total } = dataItem;
    const inputMontoCorreccionValue = Number(
      inputMontoCorreccion.current.value
    );
    const MontoCorreccion = inputMontoCorreccionValue ?? monto_justificado;

    dataItem.monto_total =
      Number(monto_total) + (MontoCorreccion - monto_justificado);
    dataItem.monto_justificado = inputMontoCorreccionValue;

    editarMontoJustificado(dataItem).then((res) => {
      if (res.status === 200) {
        setActualizarData(true);
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    setDataItem(null);
    inputMontoCorreccion.current.value = '';
  };

  return (
    <div
      className="modal fade modal-lg"
      id="modalEditarJustificado"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Corregir Monto Justificado por el Proveedor
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group mb-4 col-3">
                <label htmlFor="MontoSolicitado">Justificado Proveedor $</label>
                <input
                  ref={inputMontoJustificado}
                  type="number"
                  className="form-control"
                  id="MontoSolicitado"
                  name="monto_justificado"
                  disabled
                />
              </div>
              <div className="form-group mb-4 col-4">
                <label htmlFor="MontoCorreccion">
                  Corrección Administración $
                </label>
                <input
                  ref={inputMontoCorreccion}
                  type="number"
                  className="form-control"
                  id="MontoCorreccion"
                  name="monto_justificado"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleUpload}
            >
              Modificar monto
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEditarJustificado;
