import { apiUrl } from '../config';

// get all documentacion de los recursos
export const getCumplimientoInfoRecursos = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/pendiente/inforecursos')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

//  get all documentacion de los recursos por proveedor
export const getCumplimientoRecursosProveedor = async (rut, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/recursos/infopendiente')}/${rut}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};
