import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProveedor } from '../../../services/proveedores';
import { formatDateTime } from '../../../services/herramientas';
import RepresentanteCard from './RepresentanteCard';
import useUser from '../../../hooks/useUser';
import { urlS3 } from '../../../config';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';

const estados = ['Inactivo', 'Activo']; // estado del proveedor

function DetalleProveedor() {
  const { apiToken } = useUser();
  const paramRut = useParams();

  const [proveedor, setProveedor] = useState([]);

  useEffect(() => {
    getProveedor(paramRut.id, apiToken).then((data) => {
      setProveedor(data.proveedor);
    });
  }, [paramRut.id, apiToken]);

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-content">
            <div className="card-body">
              <h4 className="card-title text-uppercase">{proveedor.nombre}</h4>
              <div className="float-end">
                <img
                  src={`${urlS3}${proveedor.logo}`}
                  alt={`Logo ${proveedor.nombre}`}
                  width="150"
                />
              </div>
              <p className="card-text">
                <span className="text-muted">Razón Social: </span>
                <span className="font-bold text-uppercase">
                  {proveedor.razon_social || 'No informada'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Rut: </span>
                <span className="font-bold text-uppercase">
                  {proveedor.rut}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Giro: </span>
                <span className="font-bold text-uppercase">
                  {proveedor.giro || 'No informado'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Dirección: </span>
                <span className="font-bold text-uppercase">
                  {proveedor.direccion || 'No informada'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Comuna: </span>
                <span className="font-bold text-uppercase">
                  {proveedor.comuna || 'No informada'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Región: </span>
                <span className="font-bold text-uppercase">
                  {proveedor.region || 'No informada'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Telefono: </span>
                <span className="font-bold">
                  {proveedor.telefono || 'No informado'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Email: </span>
                <span className="font-bold">
                  {proveedor.email || 'No informado'}
                </span>
              </p>
              <p className="card-text">
                <span className="text-muted">Estado: </span>
                <span className="font-bold text-uppercase">
                  {estados[proveedor.estado]}
                </span>
              </p>

              <small className="text-muted float-end">
                Ultima modificación {formatDateTime(proveedor.updated_at)}
              </small>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title mb-0">Documentación del Proveedor</h4>
          </div>
          <div className="card-content">
            <div className="card-body pt-0">
              <p>Listado de documentos solicitados al proveedor</p>
              <ul className="list-group">
                <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                  <div className="flex-column">
                    LISTA DE VERIFICACION DE EMPRESA
                    <p>
                      <small></small>
                    </p>
                  </div>
                  <div className="image-parent">
                    <span className="badge bg-success">Valido</span>
                  </div>
                </li>
                <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                  <div className="flex-column">
                    RIOHS RECEPCION DIRECCION DEL TRABAJO Y SEREMI DE SALUD
                    <p>
                      <small></small>
                    </p>
                  </div>
                  <div className="image-parent">
                    <span className="badge bg-warning">Vencido</span>
                  </div>
                </li>
                <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                  <div className="flex-column">
                    CERTIFICADO DE MUTUALIDAD
                    <p>
                      <small>20-01-2022</small>
                    </p>
                  </div>
                  <div className="image-parent">
                    <span className="badge bg-success">Valido</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card representante legal */}
        <RepresentanteCard id={paramRut.id} />

        {/*  Card asistencia  */}
        <div className="card">
          <div className="card-header">
            <h4>Asistencia</h4>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DetalleProveedor;
