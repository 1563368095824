import { Navigate, Outlet } from 'react-router-dom';
import { ADMINISTRACION, PROVEEDOR, SPOC } from '../../routes/rutas';
import useUser from '../../hooks/useUser';
// Importo css de Auth
import '../../assets/css/pages/auth.css';

function PublicRoute() {
  const { isLogged, role } = useUser();

  if (isLogged) {
    switch (role) {
      case 'Administracion':
        return <Navigate to={ADMINISTRACION} />;
      case 'Proveedor':
        return <Navigate to={PROVEEDOR} />;
      case 'Spoc':
        return <Navigate to={SPOC} />;
      default:
      // return <Navigate to={ADMINISTRACION} />;
    }
  }

  return (
    <div id="auth">
      <div className="row h-100">
        <div className="col-lg-5 col-12">
          <div id="auth-left">
            <div className="auth-logo">
              <h1>GePro</h1>
            </div>
            <Outlet />
          </div>
        </div>
        {/* Logo IBM constado formulario login */}
        <div className="col-lg-7 d-none d-lg-block">
          <div id="auth-right">
            <div className="contenidoError">
              <div className="layout">
                <div className="eye">
                  <div className="eye--eyebrow"></div>
                  <div className="eye--ball"> </div>
                  <div className="eye--ball2">
                    <div className="eye--cornia">
                      <div className="eye--pupil"></div>
                    </div>
                  </div>
                </div>
                <div className="bee">
                  <div className="bee--eye bee--eye-left"></div>
                  <div className="bee--eye bee--eye-right"></div>

                  <div className="bee--body"></div>
                  <div className="bee--wing-left"></div>
                  <div className="bee--wing-right"></div>
                </div>
                <div className="M">M</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicRoute;
