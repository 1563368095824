import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getCargos } from '../../../services/dataSelects';

export function CargosSelect({ seleccion, selDefault }) {
  const { apiToken } = useUser();
  const [cargos, setCargos] = useState([]);
  const [cargo, setCargo] = useState();

  useEffect(() => {
    getCargos(apiToken).then((data) => {
      setCargos(data);
    });
  }, [apiToken]);

  // setea el valor por defecto del select la region pasada
  useEffect(() => {
    if (selDefault.length >= 8) {
      setCargo('');
    } else {
      setCargo(selDefault);
    }
  }, [selDefault]);

  function handleChange(value) {
    seleccion(value);
    setCargo(value);
  }

  return (
    <>
      <div className="col-md-4 col-12">
        <div className="form-group mandatory">
          <label htmlFor="input-cargo" className="form-label">
            Cargo
          </label>
          <select
            value={cargo}
            className="form-select"
            id="basicSelect"
            name="cargo"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          >
            <option value={''} disabled>
              Seleccionar cargo
            </option>
            {cargos.map((cargo) => (
              <option key={cargo.id} value={cargo.id}>
                {cargo.cargo}
              </option>
            ))}
          </select>
          {/* link to open modal */}
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#modalCargo"
            className="text-decoration-none"
          >
            <i className="bi bi-info-circle"></i> Información de cargos
          </Link>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade text-left"
        id="modalCargo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalCargoLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalCargoLabel1">
                Detalle de Cargos
              </h5>
              <button
                type="button"
                className="close rounded-pill"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Cargo</th>
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  {cargos.map((cargo) => (
                    <tr key={cargo.id}>
                      <td>{cargo.cargo}</td>
                      <td>{cargo.descripcion}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">
                <i className="bx bx-x d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Cerrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
