import { AlertaError } from '../components/general/Alerts';

const campoMensajes = {
  id_creador:
    'Solicitante > Refresque la página, si el problema persiste contacte a soporte',
  fecha_atencion:
    'Debe especificar la Fecha del servicio en la información de la solicitud.',
  proveedor_rut:
    'Proveedor > Refresque la página, si el problema persiste contacte a soporte',
  recurso_rut: 'Debe asignar un Recurso en la información de la solicitud',
  estado_id:
    'Estado > Refresque la página, si el problema persiste contacte a soporte',
  monto_total:
    'Monto total faltante o invalido, la solicitud debe tener al menos un ticket, y estos con sus respectivos items de gastos. Verifique que cada item de gasto tiene un monto valido ingresado. Recuerde Confirmar cada Ticket.',
};

const campoMensajesTicket = {
  proyecto_id: 'Debe seleccionar un proyecto',
  region_id: 'Debe seleccionar una región',
  comuna_id: 'Debe seleccionar una comuna',
  ticket:
    'Debe agregar un numero de ticket o una breve descripcion del servicio',
  direccion: 'Debe agregar una dirección',
};

const campoMensajesItem = {
  item_id: 'Debe seleccionar un tipo de gasto en el concepto',
  monto_solicitado: 'Monto solicitado',
};

export const validaSolicitudGasto = (data) => {
  const campoFaltante = Object.keys(campoMensajes).find((key) => !data[key]);
  if (campoFaltante) {
    const mensajeError = campoMensajes[campoFaltante];
    AlertaError({
      title: 'Incompleto',
      error: mensajeError,
    });
    return;
  }

  if (!data.tickets || data.tickets.length === 0) {
    AlertaError({
      title: 'Falta ticket',
      error: 'Debe haber al menos un ticket en la solicitud',
    });
    return;
  }

  for (let i = 0; i < data.tickets.length; i++) {
    let ticket = data.tickets[i];
    const campoFaltanteTicket = Object.keys(campoMensajesTicket).find(
      (key) => !ticket[key]
    );
    if (campoFaltanteTicket) {
      const mensajeError = `${
        campoMensajesTicket[campoFaltanteTicket]
      } en el ticket ${i + 1}`;
      AlertaError({
        title: 'Ticket incompleto',
        error: mensajeError,
      });
      return;
    }

    if (!ticket.items_gastos || ticket.items_gastos.length === 0) {
      const mensajeError = `Debe haber al menos un item de gasto en el ticket ${
        i + 1
      }, recuerde Confirmar Ticket luego de los cambios`;
      AlertaError({
        title: 'Falta Gasto',
        error: mensajeError,
      });
      return;
    }

    for (let j = 0; j < ticket.items_gastos.length; j++) {
      let item = ticket.items_gastos[j];
      const campoFaltanteItem = Object.keys(campoMensajesItem).find(
        (key) => !item[key]
      );
      if (campoFaltanteItem) {
        const mensajeError = `${
          campoMensajesItem[campoFaltanteItem]
        } del item ${j + 1} del ticket ${
          i + 1
        }, recuerde Confirmar Ticket luego de los cambios`;
        AlertaError({
          title: 'Gasto Incompleto',
          error: mensajeError,
        });
        return;
      }
    }
  }

  return true;
};
