import { useContext, useState, useCallback } from 'react';
import Context from '../context/UserContext';
import { loginService } from '../services/login';

function useUser() {
  const { user, setUser } = useContext(Context);
  const { jwt, setJwt } = useContext(Context);
  const { role, setRole } = useContext(Context);
  const [state, setState] = useState({ loading: false, error: false });

  const login = useCallback(
    ({ username, password }) => {
      setState({ loading: true, error: false });
      loginService(username, password)
        .then((data) => {
          if (!data.status) {
            sessionStorage.setItem('api_token', data.access_token);
            sessionStorage.setItem('user', JSON.stringify(data.user));
            sessionStorage.setItem('role', data.user.role);
            setUser(data.user);
            setJwt(data.access_token);
          } else {
            setState({ loading: false, error: true });
          }
        })
        .catch((error) => {
          window.sessionStorage.clear();
          setState({ loading: false, error: true });
        });
    },
    [setUser, setJwt]
  );

  // Logout
  const logout = useCallback(() => {
    window.sessionStorage.clear(); // clear session storage
    setUser(null); // set user to null
    setJwt(null); // set jwt to null
    setRole(null); // set role to null
  }, [setUser, setJwt, setRole]); // setRole

  // validate if expired token
  const validateToken = useCallback(() => {
    const token = sessionStorage.getItem('api_token');
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const decodedToken = JSON.parse(window.atob(base64));
      const exp = decodedToken.exp;
      const now = new Date().getTime() / 1000;
      if (exp < now) {
        return exp - now;
      }
      // return 'not expired';
      return exp - now;
    }
  }, []);

  return {
    isLogged: Boolean(user), // si el usuario esta logueado
    isLoginLoading: state.loading, // si esta cargando el login
    hasLoginError: state.error, // si hay error en el login
    dataUserLogged: user, // datos del usuario logueado
    apiToken: jwt, // token de la api
    role: role, // rol del usuario logueado
    login, // funcion para hacer login
    logout, // funcion para hacer logout
    validateToken, // funcion para validar el token
  };
}

export default useUser;
