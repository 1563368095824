import { getGastosAdicionales } from '../../../services/gastosAdicionales';
import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { Link } from 'react-router-dom';
import CustomTable from '../../general/CustomTable';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const columns = [
  {
    name: 'Sol. N°',
    selector: (row) => row.correlativo,
    sortable: true,
    clave: 'correlativo',
    width: '90px',
  },
  {
    name: 'Fecha',
    selector: (row) => row.fecha_atencion,
    sortable: true,
    clave: 'fecha_atencion',
  },
  {
    name: 'Proveedor',
    selector: (row) => row.proveedor_nombre,
    sortable: true,
    clave: 'proveedor_nombre',
  },
  {
    name: 'Recurso',
    selector: (row) => (row.nombre ? row.nombre : row.desvinculado_nombre),
    sortable: true,
    clave: 'nombre',
  },
  {
    name: 'Apellido',
    selector: (row) =>
      row.apellido ? row.apellido : row.desvinculado_apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'Monto',
    selector: (row) => row.monto_total,
    sortable: true,
    clave: 'monto_total',
  },
  {
    name: 'Estado',
    selector: (row) => row.estado,
    sortable: true,
    clave: 'estado',
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link
        to={`/administracion/gastos/${row.id}`}
        className="btn btn-outline-primary btn-sm"
      >
        <i className="bi bi-eye"></i>
      </Link>
    ),
    width: '5%',
    right: true,
  },
];

function TablaGastosMes() {
  const { apiToken } = useUser();
  const [gastos, setGastos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getGastosAdicionales(
        apiToken,
        selectedMonth.month,
        selectedMonth.year
      );
      setGastos(data.gastos_adicionales);
      setIsLoading(false);
    };
    fetchData();
  }, [apiToken, selectedMonth]);

  const handleMonthChange = (newSelectedMonth) => {
    setSelectedMonth(newSelectedMonth);
  };

  const excelName = `Gastos adicionales del mes`;

  return (
    <div className="row" id="table-hover-row">
      <div className="col-12">
        {isLoading && <SpinerDownload />}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">GASTOS ADICIONALES DEL MES</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive col-12">
              <CustomTable
                columns={columns}
                data={gastos}
                isLoading={isLoading}
                excelName={excelName}
                calendario={handleMonthChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablaGastosMes;
