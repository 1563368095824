import { useState } from 'react';
import TiposGastosSelect from '../TiposGastosSelect';

function AgregarItemGasto({ onChange, items, index, deleteItem }) {
  const [isKilometros, setIsKilometros] = useState(false);

  const seleccionItem = (itemSeleccionado) => {
    setIsKilometros(itemSeleccionado.tipo === 'Kilometraje');

    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      item_id: itemSeleccionado.id,
      tipo: itemSeleccionado.tipo,
    };
    onChange(newItems);
  };

  const handleChangeItem = (e) => {
    const { name, value } = e.target;
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      [name]: value,
    };
    onChange(newItems);
  };

  return (
    <div className="card-body position-relative">
      <div className="col-12 d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-sm btn-danger me-2 mb-1 position-absolute top-0 end-0 mt-2"
          onClick={() => deleteItem(index)}
        >
          <i className="bi bi-trash"></i>
        </button>
      </div>
      <div className="row">
        <div className="col-md-6 col-12">
          <TiposGastosSelect onSelect={seleccionItem} />
        </div>
        {isKilometros ? (
          <div className="col-md-3 col-12">
            <div className="form-group mandatory">
              <label htmlFor="kilometros" className="form-label">
                Kilometros
              </label>
              <input
                id="kilometros"
                type="number"
                className="form-control"
                placeholder="Kilometros"
                name="kilometros"
                onChange={handleChangeItem}
              />
            </div>
          </div>
        ) : null}
        <div className="col-md-3 col-12">
          <div className="form-group mandatory">
            <label htmlFor="monto_solicitado" className="form-label">
              Monto $
            </label>
            <input
              id="monto_solicitado"
              type="number"
              className="form-control"
              placeholder="Monto"
              name="monto_solicitado"
              onChange={handleChangeItem}
            />
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-group">
            <label htmlFor="comentario" className="form-label">
              Comentario
            </label>
            <input
              className="form-control"
              placeholder="Comentario"
              name="comentario_solicitante"
              onChange={handleChangeItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgregarItemGasto;
