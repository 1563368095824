import { apiUrl } from '../config';
import { capitalize } from '../hooks/useTexto';

// Obtiene la cantidad de recursos total entre todos los proveedores
export const getCantidadRecursos = async () => {
  const response = await fetch(`${apiUrl('recursos/cantidad')}`);
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los ultimos recursos
export const getLastRecursos = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/ultimos/recursos')}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene todos los recursos
export const getRecursos = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/recursos')}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene las cantidades de los recursos de los proveedores por region y total
export const getRecursosPorRegion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recursos/regiones/totales')}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// obtiene ultimos recursos desvinculados
export const getUltimosRecursosDesvinculados = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recursos/ultimos/desvinculados')}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los recursos y proyectos
export const getRecursosProyectos = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recursosproyectos')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los recursos y proyectos de un proveedor
export const getRecursosProyectosByProveedor = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/recursosproyectos/')}${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Update recusosProyectos
export const updateRecursosProyectos = async (data, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recursosproyectos')}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(data),
    }
  );
  const result = await response.json();
  result.status = response.status;
  return result;
};

// obtiene todos los recursos desvicnulados
export const getRecursosDesvinculados = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recursos/desvinculados')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

/** POR PROVEEDOR */

// Obtiene los recursos segun el proveedor
export const getRecursosByProveedor = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/recursos/')}${id}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

//  Obtiene los recursos de las regiones segun el proveedor
export const getRecursosRegionByProveedor = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/recursos/regiones/')}${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// obtiene los ultimos recursos segun el proveedor
export const getLastRecursosByProveedor = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/recursos/ultimos/')}${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene las ultimas desvinculaciones del proveedor
export const getLastDesvinculacionesByProveedor = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/recursos/ultimos/desvinculaciones/')}${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene todos los desvinculados del proveedor
export const getDesvinculadosByProveedor = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/recursos/desvinculados/')}${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

/** INDIVIDUALES */

// Obtiene un recurso
export const getRecurso = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/recurso')}/${id}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene un recurso desviculado
export const getRecursoDesviculado = async (id, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recurso/desviculado')}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los representantes legales de los proveedores
export const getRepresentantesLegales = async () => {
  const response = await fetch(`${apiUrl('recursos/representantes')}`);
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Crea un nuevo recurso formdata
export const createRecurso = async (data, apiToken) => {
  const formData = new FormData();
  for (const key in data) {
    if (key === 'foto') {
      formData.append(key, data[key], data[key].name);
    } else if (key === 'proyectos') {
      formData.append(key, JSON.stringify(data[key]));
    } else if (key === 'nombre' || key === 'apellido' || key === 'direccion') {
      formData.append(key, capitalize(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }
  // console.log(formData);
  const response = await fetch(`${apiUrl('proveedor/recurso')}`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const dataResponse = await response.json();
  dataResponse.status = response.status;
  return dataResponse;
};

// Actualiza recurso
export const updateRecurso = async (data, apiToken) => {
  const formdata = new FormData();
  formdata.append('rut', data.rut);
  formdata.append('nombre', data.nombre ? capitalize(data.nombre) : '');
  formdata.append('apellido', data.apellido ? capitalize(data.apellido) : '');
  formdata.append('fecha_nacimiento', data.fecha_nacimiento);
  formdata.append(
    'direccion',
    data.direccion ? capitalize(data.direccion) : ''
  );
  formdata.append('comuna', data.comuna);
  formdata.append('region', data.region);
  formdata.append('telefono', data.telefono);
  formdata.append('email', data.email ? data.email.toLowerCase() : '');
  formdata.append('cargo', data.cargo);
  formdata.append('rut_proveedor', data.rut_proveedor);
  formdata.append('tipo_contrato', data.tipo_contrato);
  formdata.append('fecha_ingreso', data.fecha_ingreso);
  formdata.append('fecha_termino', data.fecha_termino);
  formdata.append('imagen', data.foto);

  const response = await fetch(`${apiUrl('proveedor/recurso')}/${data.rut}`, {
    method: 'POST',
    body: formdata,
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const dataResponse = await response.json();
  dataResponse.status = response.status;
  return dataResponse;
};

// Actualiza un recurso para desvincular
export const deleteRecurso = async (desvincular, apiToken) => {
  console.log(desvincular);
  const formdata = new FormData();
  formdata.append('rut_proveedor', desvincular.rut_proveedor);
  formdata.append('rut', desvincular.rut);
  formdata.append('region', desvincular.region);
  formdata.append(
    'comentario_desvinculacion',
    desvincular.comentario_desvinculacion
  );
  formdata.append('fecha_baja', new Date().toISOString().slice(0, 10));
  formdata.append('estado', 0);
  formdata.append('motivo_desvinculacion', desvincular.motivo_desvinculacion);

  const response = await fetch(
    `${apiUrl('proveedor/recurso/desvincular')}/${desvincular.rut}`,
    {
      method: 'POST',
      body: formdata,
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const dataResponse = await response.json();
  dataResponse.status = response.status;
  return dataResponse;
};

// Actualiza estado recontratable recurso desvinculado data JSON
export const updateRecontratable = async (data, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/recurso/desvinculado')}/${data.id}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const dataResponse = await response.json();
  dataResponse.status = response.status;
  return dataResponse;
};
