import { apiUrl } from '../config';

// Obtiene los usuarios
export const getUsuarios = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/usuarios')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  return data;
};

// registra un nuevo usuario
export const createUsuario = async (usuario, apiToken) => {
  const formdata = new FormData();
  formdata.append('id', usuario.id || '');
  formdata.append('rut', usuario.rut);
  formdata.append('name', usuario.name);
  formdata.append('email', usuario.email);
  formdata.append('password', usuario.password);
  formdata.append('role', usuario.role);

  const response = await fetch(`${apiUrl('auth/register')}`, {
    method: 'POST',
    body: formdata,
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const result = await response.json();
  result.status = response.status;
  return result;
};

// Elimina un usuario
export const deleteUsuario = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('administracion/usuario')}/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

/** PROVEEDOR */
// Obtiene los usuarios de un proovedor por rut
export const getUsuariosProveedor = async (rut, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/usuarios')}/${rut}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los tipos de correos en el sistema.
export const getCorreos = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/correos')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtiene los usuarios y los correos que puede recibir por rut
export const getCorreosUsuario = async (rut, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/usuarios/correos')}/${rut}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Actualiza los correos de un usuario
export const updateCorreosUsuario = async (data, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/usuarios/correos')}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  result.status = response.status;
  return result;
};
