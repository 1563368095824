import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import TablaProveedores from './TablaProveedores';

function VerProveedores() {
  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Recursos Activos
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-vencidos">
                      0
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Documentos Por vencer
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-por-vencer">
                      0
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Total Recursos</h6>
                    <h4 className="font-extrabold mb-0" id="cantidad-recursos">
                      {localStorage.getItem('cantidad-recursos')}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Proveedores</h6>
                    <h4
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      {localStorage.getItem('cantidad-proveedores')}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabla proveedores */}
        <TablaProveedores />
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimos recursos  */}
        <div className="card">
          <div className="card-header">
            <h4>Representante</h4>
          </div>
          <div className="card-content pb-4">
            <div className="recent-message d-flex px-4 py-3">
              <div className="avatar avatar-lg bg-warning">
                {/*  <img src="assets/images/faces/1.jpg" />  */}
                <span className="avatar-content">MCD</span>
              </div>
              <div className="name ms-4">
                <h5 className="mb-1">Sin datos</h5>
                <h6 className="text-muted mb-0">Sin datos</h6>
              </div>
            </div>

            <div className="recent-message d-flex px-4 py-3">
              <h6>No hay nuevos</h6>
            </div>

            <div className="px-4">
              <button className="btn btn-block btn-xl btn-outline-primary font-bold mt-3">
                Ver Todos
              </button>
            </div>
          </div>
        </div>

        {/*  Card asistencia  */}
        <div className="card">
          <div className="card-header">
            <h4>Asistencia</h4>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerProveedores;
