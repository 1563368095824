import { useCallback, useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  updateCorreosUsuario,
  getCorreosUsuario,
} from '../../../services/usuarios';
import { AlertaConfirm } from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

function CorreosAdm() {
  const { apiToken, dataUserLogged } = useUser();
  const [usuarios, setUsuarios] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [correosSeleccionados, setCorreosSeleccionados] = useState([]);

  const getUsersMails = useCallback(async () => {
    const res = await getCorreosUsuario(dataUserLogged.rut, apiToken);
    if (res && res.status === 200 && res.data) {
      setUsuarios(res.data);
    }
    setIsLoading(false);
  }, [apiToken, dataUserLogged.rut]);

  useEffect(() => {
    getUsersMails();
  }, [getUsersMails]);

  const handleCheckboxChange = (user_id, correo_id, e) => {
    console.log(correo_id);
    const newCorreosSeleccionados = [...correosSeleccionados];
    // encuentra el índice del usuario en el array
    const userIndex = newCorreosSeleccionados.findIndex(
      (user) => user.id_usuario === user_id
    );
    // si no se ha agregado antes el usuario, lo agrega
    if (userIndex === -1) {
      newCorreosSeleccionados.push({
        id_usuario: user_id,
        correos: [{ id_correo: correo_id, estado: e.target.checked }],
      });
    } else {
      // si el usuario ya está agregado, busca el índice del correo en el array de correos
      const correoIndex = newCorreosSeleccionados[userIndex].correos.findIndex(
        (correo) => correo.id_correo === correo_id
      );
      // si el correo no se ha agregado antes, lo agrega
      if (correoIndex === -1) {
        newCorreosSeleccionados[userIndex].correos.push({
          id_correo: correo_id,
          estado: e.target.checked,
        });
      } else {
        // si el correo ya está agregado, actualiza su estado
        newCorreosSeleccionados[userIndex].correos[correoIndex].estado =
          e.target.checked;
      }
    }
    setCorreosSeleccionados(newCorreosSeleccionados);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    AlertaConfirm({
      title: '¿Está seguro de actualizar los correos?',
      text: 'Los correos se actualizarán para todos los usuarios a los que se les haya cambiado el estado.',
      callback: async () => {
        setIsLoading(true);
        try {
          const res = await updateCorreosUsuario(
            correosSeleccionados,
            apiToken
          );
          if (res && res.status === 200) {
            getUsersMails();
          }
        } catch (error) {
          console.log(error);
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      {isLoading ? <SpinerDownload /> : null}
      <section className="section">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <h3>Recepción de correos del sistema por usuarios</h3>
            {/* table */}
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th className="col-1">Gastos Adicionales</th>
                  <th className="col-1">Overtime</th>
                  {/* {correos.map((motivo) => (
                  <th className="col-1" key={motivo.id}>
                    {motivo.motivo}
                  </th>
                ))} */}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      Obteniendo datos de usuarios...
                    </td>
                  </tr>
                ) : (
                  usuarios.map((usuario) => (
                    <tr key={usuario.id_usuario}>
                      <td>{usuario.nombre}</td>
                      <td>{usuario.email}</td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={
                            usuario.correos.find(
                              (correo) => correo.id_correo === '1'
                            )
                              ? usuario.correos.find(
                                  (correo) => correo.id_correo === '1'
                                ).recibe === '1'
                              : false
                          }
                          onChange={(e) =>
                            handleCheckboxChange(usuario.id_usuario, '1', e)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={
                            usuario.correos.find(
                              (correo) => correo.id_correo === '2'
                            )
                              ? usuario.correos.find(
                                  (correo) => correo.id_correo === '2'
                                ).recibe === '1'
                              : false
                          }
                          onChange={(e) =>
                            handleCheckboxChange(usuario.id_usuario, '2', e)
                          }
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="col-12 d-flex justify-content-end mt-5">
              <button className="btn btn-primary" onClick={handleUpdate}>
                <i className="bi bi-cloud-arrow-up"></i> Actualizar Cambios
              </button>
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Información</h4>
              </div>
              <div className="card-body">
                <p>
                  En esta sección se puede configurar los correos que recibirán
                  los usuarios del sistema.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CorreosAdm;
