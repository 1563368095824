import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getEstadisticasOvertimeSpoc } from '../../../services/overtimes';
import CardUltimosOvtGenerados from '../../general/cardsInfo/CardUltimosOvtGenerados';
import CardUltimosOvtPreaprobados from '../../general/cardsInfo/CardUltimosOvtPreaprobados';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import TablaOvertimeSpoc from './TablaOvertimeSpoc';

function OvertimeSpoc() {
  const [estadisticas, setEstadisticas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { apiToken } = useUser();
  const perfil = useLocation().pathname.split('/')[1];

  useEffect(() => {
    getEstadisticas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEstadisticas = async () => {
    const res = await getEstadisticasOvertimeSpoc(apiToken);
    if (res && res.status === 200 && res.data) {
      setEstadisticas(res.data);
      setIsLoading(false);
    }
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Sin Justificar Overtimes
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-vencidos">
                      {isLoading ? null : estadisticas.total_preaprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Preaprobados</h6>
                    <h6 className="font-extrabold mb-0" id="doc-por-vencer">
                      {isLoading ? null : estadisticas.total_preaprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Aprobadas</h6>
                    <h6
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      {isLoading ? null : estadisticas.total_aprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Aprobado Del Mes
                    </h6>
                    <h6 className="font-extrabold mb-0">
                      $
                      {isLoading
                        ? null
                        : estadisticas.total_monto_aprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tablas */}
        <TablaOvertimeSpoc />
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  En Preaprobacion  */}
        {isLoading ? null : (
          <CardUltimosOvtGenerados
            data={estadisticas.ultimos_ovts}
            perfil={perfil}
          />
        )}

        {/*  Card Preaprobados  */}
        {isLoading ? null : (
          <CardUltimosOvtPreaprobados
            data={estadisticas.ultimos_ovts_preaprobados}
            perfil={perfil}
          />
        )}

        {/*  Card Aprobados  */}
        <div className="card">
          <div className="card-header">
            <h4>Ultimos Aprobados</h4>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OvertimeSpoc;
