import { apiUrl } from '../config';

// obtiene las regiones
export const getRegiones = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/regiones')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  return data.regiones;
};

// obtiene las comunas de una region
// export const getComunas = async (apiToken, regionId) => {
//   const response = await fetch(`${apiUrl('misc/comunas')}/${regionId}`, {
//     headers: {
//       Authorization: `Bearer ${apiToken}`,
//     },
//   });
//   const data = await response.json();
//   return data.comunas;
// };

// Obtiene las comunas
export const getComunas = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/comunas')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();

  return data.comunas;
};

// Obtiene los cargos
export const getCargos = async (apiToken) => {
  const response = await fetch(`${apiUrl('misc/cargos')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const data = await response.json();
  return data.cargos;
};
