import AgregarTicket from '../../general/forms/gastos/AgregarTicket';
import DetalleGastoAdicional from '../../general/gastos/DetalleGastoAdicional';
import RecursosProveedorSelect from '../../general/forms/RecursosProveedorSelect';
import useUser from '../../../hooks/useUser';
import { useState } from 'react';
import { generateKey } from '../../../hooks/useKeys';
import { createServicioAdicional } from '../../../services/gastosAdicionales';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { validaSolicitudGasto } from '../../../hooks/useValidaGastos';

const generateId = () => {
  const num = Math.floor(Math.random() * 100000000);
  return `S${num.toString().padStart(8, '0')}`;
};

function GastosNuevo() {
  const { dataUserLogged, apiToken } = useUser();

  const initialState = {
    id_creador: dataUserLogged.id,
    fecha_atencion: '',
    proveedor_rut: dataUserLogged.rut,
    recurso_rut: '',
    recurso_nombre: '',
    recurso_apellido: '',
    monto_total: 0,
    tickets: [],
    estado_id: 1,
    observacion: '',
  };
  const [data, setData] = useState(initialState);
  const [selDefault, setSelDefault] = useState('');
  const [totalSolicitud, setTotalSolicitud] = useState(0);

  // recibe los datos del formulario recurso y los agrega al estado data
  const handleChangeSolicitud = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // agrega el recurso seleccionado en el formulario al estado data
  function seleccionRecurso(recurso) {
    setData({
      ...data,
      recurso_rut: recurso.rut || '',
      recurso_nombre: recurso.nombre || '',
      recurso_apellido: recurso.apellido || '',
    });
  }

  // reset form solicitud y data
  const resetFormSolicitud = (e) => {
    setData(initialState);
    setSelDefault(generateId());
    setTickets([]);
    setTotalSolicitud(0);
    e.target.reset();
  };

  // procesa el submit
  const handleSolicitudSubmit = (e) => {
    e.preventDefault();
    // agrega tickets al estado data key tickets
    data.monto_total = totalSolicitud;
    data.tickets = tickets;
    // envia la solicitud al validar datos y luego al servidor
    if (validaSolicitudGasto(data)) {
      AlertaConfirm({
        title: 'Confirmar solicitud',
        text: '¿Estas seguro de enviar la solicitud?',
        callback: () => {
          createServicioAdicional(data, apiToken)
            .then((res) => {
              if (res.status === 200) {
                AlertaSuccess({
                  title: 'Solicitud enviada',
                  text: 'La solicitud se envio correctamente',
                });
                resetFormSolicitud(e);
              }
            })
            .catch((err) => {
              AlertaError({
                title: 'Error al enviar solicitud',
                text: 'Hubo un error al enviar la solicitud',
              });
            });
        },
      });
    }
  };

  /** FIN GASTO ADICIONAL */

  /** TICKETS */
  // estado donde se agregaran los tickets que se ingresen
  const [tickets, setTickets] = useState([]);

  // recibe el ticket que se agrega mediante el componente y lo agrega al estado tickets
  const handleAddTicket = (e) => {
    const newTicket = {
      key: generateKey(),
      proyecto: '',
      nomenclatura: '',
      region: '',
      comuna: '',
      proyecto_id: '',
      region_id: '',
      comuna_id: '',
      ticket: '',
      direccion: '',
      observacion: '',
    };
    setTickets([...tickets, newTicket]);
  };

  //  delete ticket from tickets state
  const handleDeleteTicket = (index) => {
    const updatedTickets = [...tickets];
    updatedTickets.splice(index, 1);
    setTickets(updatedTickets);
  };

  /** FIN TICKETS */

  return (
    <section className="section">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">SOLICITUD DE GASTO ADICIONAL</h4>
            </div>
            <div className="card-body">
              {/* Divider */}
              <div className="divider divider-left mt-0">
                <div className="divider-text">INFORMACIÓN SOLICITUD</div>
              </div>
              <form
                className="form"
                data-parsley-validate
                id="form-gasto-adicional"
                onSubmit={handleSolicitudSubmit}
              >
                <div className="row">
                  {/* Fecha Servicio */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="fecha_atencion" className="form-label">
                        Fecha del Servicio
                      </label>
                      <input
                        type="date"
                        id="fecha_atencion"
                        className="form-control"
                        name="fecha_atencion"
                        onChange={handleChangeSolicitud}
                        value={data.fecha_atencion}
                        required
                      />
                    </div>
                  </div>

                  {/* Recurso */}
                  <div className="col-md-8 col-12">
                    <RecursosProveedorSelect
                      onSelect={seleccionRecurso}
                      selDefault={selDefault}
                    />
                  </div>

                  {/* Observacion */}
                  <div className="col-md-12 col-12">
                    <div className="form-group">
                      <label htmlFor="observacion_s" className="form-label">
                        Comentario a la solicitud
                      </label>
                      <textarea
                        id="observacion_s"
                        className="form-control"
                        placeholder="Observacion"
                        rows="2"
                        name="observacion"
                        onChange={handleChangeSolicitud}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-between ">
                    {/* Boton cancelar */}
                    <button
                      type="reset"
                      className="btn btn-danger me-1 mb-1"
                      onClick={resetFormSolicitud}
                    >
                      Cancelar
                    </button>
                    <div className="d-flex">
                      {/* Boton Submit */}
                      <button
                        type="submit"
                        className="btn btn-success me-2 mb-1 ml-auto1"
                      >
                        Enviar Solicitud
                      </button>

                      {/* Boton Agregar Ticket */}
                      <button
                        type="button"
                        className="btn btn-primary me-2 mb-1"
                        onClick={handleAddTicket}
                      >
                        Agregar Ticket
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Divider */}
          <div className="divider divider-left mt-2">
            <div className="divider-text">TICKETS</div>
          </div>

          {/* FORMULARIO AGREGAR TICKETS */}
          {tickets.map((ticket, index) => (
            <div className="card" key={ticket.key}>
              <div className="card-body">
                <AgregarTicket
                  idKey={ticket.key}
                  onChange={setTickets}
                  index={index}
                  tickets={tickets}
                  onRemove={() => handleDeleteTicket(index)}
                  total={setTotalSolicitud}
                />
              </div>
            </div>
          ))}
        </div>
        {/* Detalle de gastos */}
        <div className="col-md-6 col-12">
          <DetalleGastoAdicional
            data={data}
            tickets={tickets}
            total={totalSolicitud}
          />
        </div>
      </div>
    </section>
  );
}

export default GastosNuevo;
