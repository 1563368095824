import { Link } from 'react-router-dom';

function CardUltimosGastosAprobados({ data, perfil }) {
  return (
    <div className="card shadow">
      <div className="card-body px-1">
        <h6 className='text-center'>ÚLTIMAS APROBADAS</h6>
        <div className="list-group list-group-flush">
          {data.map((aprobado) => (
            <Link 
              key={aprobado.id} 
              to={`/${perfil}/gastos/${aprobado.id}`} 
              className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
            >
              <div className="name col-8">
                <h6 className="mb-1">N° {aprobado.correlativo}</h6>
                <p className="text-primary mb-0">
                  {aprobado.proveedor_nombre}
                </p>
                <small className="text-muted">
                  {aprobado.fecha_aprobacion} hrs
                </small>
              </div>
              <div className="col-4 ms-3">
                <h6 className="text-success mb-0">
                  $ {aprobado.monto_total}
                </h6>
                <small className="text-info">{aprobado.estado}</small>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardUltimosGastosAprobados;
