export function validateRut(rut) {
  let valor = rut.replace('.', '');
  valor = valor.replace('-', '');
  let cuerpo = valor.slice(0, -1);
  let dv = valor.slice(-1).toUpperCase();

  if (!valor) {
    return {
      rut: '',
      isValid: false,
    };
  }

  // Asegurarse de que el valor de 'cuerpo' sea numérico
  if (isNaN(cuerpo)) {
    return {
      rut: cuerpo + '-' + dv,
      isValid: false,
    };
  }

  let suma = 0;
  let multiplo = 2;

  for (let i = 1; i <= cuerpo.length; i++) {
    suma += parseInt(cuerpo.charAt(cuerpo.length - i)) * multiplo;
    multiplo = (multiplo + 1) % 8 || 2;
  }

  let dvEsperado = 11 - (suma % 11);
  dvEsperado = dvEsperado === 11 ? 0 : dvEsperado;
  dvEsperado = dvEsperado === 10 ? 'K' : dvEsperado;

  let isValid = dv === dvEsperado.toString();

  return {
    rut: cuerpo + '-' + dv,
    isValid: isValid,
  };
}
