import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getCumplimientoInfoRecursos } from '../../../services/docRecursos';
import { generaExcelRecursos } from '../../../services/generaExcel';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import CustomTable from '../../general/CustomTable';

function iconByValue(value) {
  const className =
    Number(value) === 1
      ? 'bi bi-check-circle-fill text-success'
      : 'bi bi-x-circle-fill text-danger';
  return <i className={className}></i>;
}

function calculatePercentage(row, columns) {
  const excludedKeys = ['nombre', 'apellido', 'rut', 'proveedor'];
  const totalColumns = columns.length - excludedKeys.length;
  const columnsWithValue1 = columns.filter(
    ({ clave }) => !excludedKeys.includes(clave) && Number(row[clave]) === 1
  ).length;

  return `${Math.round((columnsWithValue1 / totalColumns) * 100)}%`;
}
const columns = [
  {
    name: 'PROVEEDOR',
    selector: (row) => row.proveedor,
    sortable: true,
    clave: 'proveedor',
  },
  {
    name: 'NOMBRE',
    selector: (row) => (
      <Link to={`/administracion/recurso/${row.rut}`}>{row.nombre}</Link>
    ),
    sortable: true,
    clave: 'nombre',
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.nombre.toLowerCase();
      const nameB = rowB.nombre.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: 'APELLIDO',
    selector: (row) => (
      <Link to={`/administracion/recurso/${row.rut}`}>{row.apellido}</Link>
    ),
    sortable: true,
    clave: 'apellido',
    sortFunction: (rowA, rowB) => {
      const nameA = rowA.apellido.toLowerCase();
      const nameB = rowB.apellido.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
  },
  {
    name: 'FECHA NACIMIENTO',
    selector: (row) => iconByValue(row.fecha_nacimiento),
    sortable: true,
    clave: 'fecha_nacimiento',
  },
  {
    name: 'DIRECCION',
    selector: (row) => iconByValue(row.direccion),
    clave: 'direccion',
  },
  {
    name: 'COMUNA',
    selector: (row) => iconByValue(row.comuna),
    sortable: true,
    clave: 'comuna',
  },
  {
    name: 'REGION',
    selector: (row) => iconByValue(row.region),
    sortable: true,
    clave: 'region',
  },
  {
    name: 'TELEFONO',
    selector: (row) => iconByValue(row.telefono),
    sortable: true,
    clave: 'telefono',
  },
  {
    name: 'EMAIL',
    selector: (row) => iconByValue(row.email),
    sortable: true,
    clave: 'email',
  },
  {
    name: 'FECHA INGRESO',
    selector: (row) => iconByValue(row.fecha_ingreso),
    sortable: true,
    clave: 'fecha_ingreso',
  },
  {
    name: 'TIPO CONTRATO',
    selector: (row) => iconByValue(row.tipo_contrato),
    sortable: true,
    clave: 'tipo_contrato',
  },
  {
    name: 'CARGO',
    selector: (row) => iconByValue(row.cargo),
    sortable: true,
    clave: 'cargo',
  },
  {
    name: 'FOTO',
    selector: (row) => iconByValue(row.foto),
    sortable: true,
    clave: 'foto',
  },
  {
    name: 'CUMPLIMIENTO',
    selector: (row) => calculatePercentage(row, columns),
    sortable: true,
    clave: 'porcentaje',
  },
];

function DocRecursos() {
  const { apiToken, dataUserLogged } = useUser();
  const [cumplimientoRecursos, setCumplimientoRecursos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [btnRecursosLoading, setBtnRecursosLoading] = useState(false);
  const excelName = `Lista de cumplimiento de informacion ${dataUserLogged.proveedor_nombre}`;

  useEffect(() => {
    async function fetchCumplimientoRecursos() {
      setIsLoading(true);
      const data = await getCumplimientoInfoRecursos(apiToken);
      setCumplimientoRecursos(data.infoPendiente);
      setIsLoading(false);
    }

    fetchCumplimientoRecursos();
  }, [apiToken, dataUserLogged.rut]);

  async function handleClickDescargarRecursos() {
    setBtnRecursosLoading(true);
    const data = await generaExcelRecursos(apiToken);
    setBtnRecursosLoading(false);

    if (data.status === 200) {
      window.open(data.ruta, '_blank');
    }
  }

  return (
    <section className="row">
      {/* Tabla cumplimiento de informacion */}
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4>Cumplimiento de Información de los Recursos</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive col-12">
              <CustomTable
                columns={columns}
                data={cumplimientoRecursos}
                isLoading={isLoading}
                excelName={excelName}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Tercera columna */}
      <div className="col-12 col-lg-3">
        <CardUserLogged />

        {/*  Descargables  */}
        <div className="card">
          <div className="card-header">
            <h4>Archivos Descargables</h4>
          </div>
          <div className="card-content pb-4">
            <div className="px-3">
              <button
                className="btn btn-block btn-xl btn-outline-success font-bold mt-3"
                type="button"
                onClick={handleClickDescargarRecursos}
              >
                {btnRecursosLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    Generando Archivo...
                  </>
                ) : (
                  <>
                    <i
                      className="bi bi-cloud-arrow-down"
                      style={{ fontSize: '1.2rem' }}
                    ></i>{' '}
                    Información Completa Recursos
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DocRecursos;
