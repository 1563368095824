import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getEstadisticasGastos } from '../../../services/gastosAdicionales';
import CardUltimosGastosAprobados from '../../general/cardsInfo/CardUltimosGastosAprobados';
import CardUltimosGastosGenerados from '../../general/cardsInfo/CardUltimosGastosGenerados';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import CardUltimosGastosJustificados from '../../general/cardsInfo/CardUltimosGastosJustificados';
import GastosMontosProveedoresMes from '../../general/charts/GastosMontosProveedoresMes';
import GastosProveedoresPie from '../../general/charts/GastosProveedoresPie';
import TablaGastosMes from './TablaGastosMes';
import TablaGastosPendAprob from './TablaGastosPendAprob';
import TablaGastosPendCons from './TablaGastosPendCons';
import TablaGastosPendJust from './TablaGastosPendJust';
import TablaGastosPendPreap from './TablaGastosPendPreap';
import TablaGastosAnuladosAdm from './TablaGastosAnuladosAdm';
import TablaGastosConsolidados from './TablaGastosConsolidados';

function Gastos() {
  const [estadisticas, setEstadisticas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { apiToken } = useUser();
  const perfil = useLocation().pathname.split('/')[1];
  const tipoTabla = useLocation().pathname.split('/')[3];

  let tabla;
  switch (tipoTabla) {
    case 'preaprobacion':
      tabla = <TablaGastosPendPreap />;
      break;
    case 'justificacion':
      tabla = <TablaGastosPendJust />;
      break;
    case 'aprobacion':
      tabla = <TablaGastosPendAprob />;
      break;
    case 'consolidar':
      tabla = <TablaGastosPendCons />;
      break;
    case 'anuladas':
      tabla = <TablaGastosAnuladosAdm />;
      break;
    case 'consolidados':
      tabla = <TablaGastosConsolidados />;
      break;
    default:
      tabla = <TablaGastosMes />;
      break;
  }

  useEffect(() => {
    getEstadisticas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEstadisticas = async () => {
    const res = await getEstadisticasGastos(apiToken);
    if (res && res.status === 200 && res.data) {
      setEstadisticas(res.data);
      setIsLoading(false);
    }
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card shadow">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Pendientes Preaprobación
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-vencidos">
                      {isLoading ? null : estadisticas.total_pendientes || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card shadow">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Pendientes Justificación
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-por-vencer">
                      {isLoading ? null : estadisticas.total_preaprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3 col-md-6">
            <div className="card shadow">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Pendientes Aprobación
                    </h6>
                    <h6
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      {isLoading ? null : estadisticas.total_justificados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card shadow">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Aprobado Del Mes
                    </h6>
                    <h6 className="font-extrabold mb-0">
                      $
                      {isLoading
                        ? null
                        : estadisticas.total_monto_aprobados || 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tablas */}
        {tabla}
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="card">
              <div className="card-header">
                <h4>Solicitudes por Proveedor</h4>
              </div>
              <div className="card-body">
                {isLoading ? null : (
                  <GastosProveedoresPie
                    datos={estadisticas.cantidad_proveedores}
                  />
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  No se inlcluyen las solicitudes con estado, Rechazado ni
                  Anulado.
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="card vh-90">
              <div className="card-header">
                <h4>Gastos por Proveedor</h4>
              </div>
              <div className="card-body">
                {isLoading ? null : (
                  <GastosMontosProveedoresMes
                    datos={estadisticas.monto_proveedores}
                  />
                )}
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  Incluye estados Preaprobadas, Justificadas, Aprobadas y
                  Consolidadas.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  En Preaprobacion  */}
        {isLoading ? null : (
          <CardUltimosGastosGenerados
            data={estadisticas.ultimos_gastos}
            perfil={perfil}
          />
        )}

        {/*  Card Preaprobados  */}
        {isLoading ? null : (
          <CardUltimosGastosJustificados
            data={estadisticas.ultimos_gastos_justificados}
            perfil={perfil}
          />
        )}

        {/*  Card Aprobados  */}
        {isLoading ? null : (
          <CardUltimosGastosAprobados
            data={estadisticas.ultimos_gastos_aprobados}
            perfil={perfil}
          />
        )}
      </div>
    </section>
  );
}

export default Gastos;
