// import ExcelExport from '../../general/ExcelExport';
import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { getOvertimesMes } from '../../../services/overtimes';
import CustomTable from '../../general/CustomTable';
import { formatDate } from '../../../hooks/useDates';
import { Link } from 'react-router-dom';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const columns = [
  {
    name: 'Sol. N°',
    selector: (row) => row.correlativo,
    sortable: true,
    clave: 'correlativo',
    width: '8%',
  },
  {
    name: 'Fecha',
    cell: (row) => formatDate(row.fecha_ovt),
    sortable: true,
    clave: 'fecha_ovt',
  },
  {
    name: 'Recurso',
    selector: (row) => row.nombre,
    sortable: true,
    clave: 'nombre',
  },
  {
    name: '-',
    selector: (row) => row.apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'Hrs solicitadas',
    selector: (row) => row.horas_solicitadas,
    sortable: true,
    clave: 'horas_solicitadas',
  },
  {
    name: 'Hrs Utilizadas',
    selector: (row) => row.horas_justificadas,
    sortable: true,
    clave: 'horas_justificadas',
  },
  {
    name: 'Estado',
    selector: (row) => row.estado,
    sortable: true,
    clave: 'estado',
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link to={`/spoc/overtime/${row.id}`} className="btn btn-outline-primary">
        <i className="bi bi-eye"></i>
      </Link>
    ),
    width: '8%',
    right: true,
  },
];

function TablaOvertimeSpoc() {
  const { apiToken } = useUser();
  const [overtimes, setOvertimes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getOvertimesMes(
        apiToken,
        selectedMonth.month,
        selectedMonth.year
      );
      setOvertimes(data.overtimes || []);
      setIsLoading(false);
    };
    fetchData();
  }, [apiToken, selectedMonth]);

  // const getOvertimes = async () => {
  //   getOvertimesMes(apiToken, selectedMonth.month, selectedMonth.year).then(
  //     (res) => {
  //       if (res.status === 200) {
  //         setOvertimes(res.overtimes);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     }
  //   );
  // };

  const handleMonthChange = (newSelectedMonth) => {
    setSelectedMonth(newSelectedMonth);
  };

  const excelName = `Overtimes del mes - SPOC`;

  return (
    <div className="row">
      <div className="col-12">
        {isLoading && <SpinerDownload />}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">OVERTIMES DEL MES</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive col-12">
              <CustomTable
                columns={columns}
                data={overtimes}
                isLoading={isLoading}
                excelName={excelName}
                calendario={handleMonthChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablaOvertimeSpoc;
