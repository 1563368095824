import * as XLSX from 'xlsx';

function ExportarExcel({ excelData, fileName, columnMapping }) {
  const uniqueKeys = [
    ...new Set(
      excelData.reduce((keys, row) => Object.keys(row).concat(keys), [])
    ),
  ].filter((clave) => columnMapping.some((col) => col.clave === clave));

  const header = uniqueKeys.map(
    (clave) => columnMapping.find((c) => c.clave === clave).name
  );

  const filteredData = excelData.map((item) =>
    uniqueKeys.reduce((newItem, clave) => {
      const col = columnMapping.find((c) => c.clave === clave);
      if (col) {
        newItem[col.name] = item[clave];
      }
      return newItem;
    }, {})
  );

  const handleDownloadExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(filteredData, { header });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <button className="btn btn-success" onClick={handleDownloadExcel}>
      <i className="bi bi-cloud-arrow-down-fill"></i> Descargar en Excel
    </button>
  );
}

export default ExportarExcel;

/**
 * @param {Array} excelData - Array de objetos con los datos a exportar
 * @param {String} fileName - Nombre del archivo a exportar
 * @param {Array} columnMapping - Array de objetos con la clave y etiqueta de cada columna
 * const columnMapping = [
  { name: 'Sol. N°', clave: 'correlativo' },
  { name: 'Fecha', clave: 'fecha_ovt' },
  { name: 'Recurso', clave: 'nombre' },
  { name: '', clave: 'apellido' },
  { name: 'Horas', clave: 'horas_solicitadas' },
  { name: 'Estado', clave: 'estado' },
];
 */
