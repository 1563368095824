// import ExcelExport from '../../general/ExcelExport';
import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { getOvertimesProveedor } from '../../../services/overtimes';
import CustomTable from '../../general/CustomTable';
import { formatDate } from '../../../hooks/useDates';
import { Link } from 'react-router-dom';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const columns = [
  {
    name: 'Sol. N°',
    selector: (row) => row.correlativo,
    sortable: true,
    clave: 'correlativo',
    width: '90px',
  },
  {
    name: 'Fecha',
    cell: (row) => formatDate(row.fecha_ovt),
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const dateA = new Date(rowA.fecha_ovt);
      const dateB = new Date(rowB.fecha_ovt);
      return dateA - dateB;
    },
    clave: 'fecha_ovt',
  },
  {
    name: 'Rut',
    selector: (row) => row.recurso_rut,
    sortable: true,
    clave: 'recurso_rut',
    omit: false,
  },
  {
    name: 'Recurso Nombre',
    selector: (row) => (row.nombre ? row.nombre : row.desviculado_nombre),
    sortable: true,
    clave: 'nombre',
  },
  {
    name: 'Apellido',
    selector: (row) =>
      row.apellido ? row.apellido : row.desvinculado_apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'Hrs solicitadas',
    selector: (row) =>
      row.horas_solicitadas ? row.horas_solicitadas.slice(0, -3) : 'pend.',
    sortable: true,
    clave: 'horas_solicitadas',
    width: '100px',
  },
  {
    name: 'Hrs Preaprobadas',
    selector: (row) =>
      row.horas_preaprobadas ? row.horas_preaprobadas.slice(0, -3) : 'p/p',
    sortable: true,
    clave: 'horas_preaprobadas',
    width: '100px',
  },
  {
    name: 'Hrs Justificadas',
    selector: (row) =>
      row.horas_justificadas ? row.horas_justificadas.slice(0, -3) : 'p/j',
    sortable: true,
    clave: 'horas_justificadas',
    width: '100px',
  },
  {
    name: 'Hrs Aprobadas',
    selector: (row) =>
      row.horas_aprobadas ? row.horas_aprobadas.slice(0, -3) : 'p/a',
    sortable: true,
    clave: 'horas_aprobadas',
    width: '100px',
  },
  {
    name: 'Estado',
    selector: (row) => row.estado,
    sortable: true,
    clave: 'estado',
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link
        to={`/proveedor/overtime/${row.id}`}
        className="btn btn-outline-primary"
      >
        <i className="bi bi-eye"></i>
      </Link>
    ),
  },
];

function TablaOvertimes() {
  const { dataUserLogged, apiToken } = useUser();
  const [overtimes, setOvertimes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getOvertimesProveedor(
        apiToken,
        dataUserLogged.rut,
        selectedMonth.month,
        selectedMonth.year
      );
      setOvertimes(data.overtimes || []);
      setIsLoading(false);
    };
    fetchData();
  }, [apiToken, dataUserLogged.rut, selectedMonth]);

  const handleMonthChange = (newSelectedMonth) => {
    setSelectedMonth(newSelectedMonth);
  };

  const excelName = `Overtimes del mes ${dataUserLogged.proveedor_nombre}`;

  return (
    <div className="row">
      <div className="col-12">
        {isLoading && <SpinerDownload />}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">OVERTIMES DEL PROVEEDOR</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive col-12">
              <CustomTable
                columns={columns}
                data={overtimes}
                isLoading={isLoading}
                excelName={excelName}
                calendario={handleMonthChange}
              />
            </div>
            <small className="text-muted">
              Pendientes: preaprobacion (p/p), justificacion (p/j), aprobacion
              (p/a)
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablaOvertimes;
