import { apiUrl } from '../config';

// get tipos gastos
export const getTiposGastos = async (apiToken) => {
  try {
    const response = await fetch(`${apiUrl('misc/gastos/tipos')}`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// delete tipo gasto
export const deleteTipoGasto = async (id, apiToken) => {
  try {
    const response = await fetch(
      `${apiUrl('administracion/gastos/tipo')}/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};

// create tipo gasto
export const createTipoGasto = async (tipoGasto, apiToken) => {
  try {
    const response = await fetch(`${apiUrl('administracion/gastos/tipo')}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(tipoGasto),
    });
    const data = await response.json();
    data.status = response.status;
    return data;
  } catch (error) {
    console.log(error);
  }
};
