import React from 'react';

function DocumentacionRecursos() {
  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>Documentacion de los Recursos</h3>
          {/* table */}
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Documento</th>
                <th>Descripcion</th>
                <th>Sigla doc.</th>
                <th>Expirable</th>
                <th>Duracion</th>
                <th>Solicitado a</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Nuevo Documento</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                id="form-create-documento"
              >
                <div className="row">
                  {/* Docuemento */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-documento" className="form-label">
                        Documento
                      </label>
                      <input
                        type="text"
                        id="input-nombre"
                        className="form-control"
                        placeholder="documento"
                        name="documento"
                      />
                    </div>
                  </div>

                  {/* Sigla */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-sigla" className="form-label">
                        Sigla
                      </label>
                      <input
                        type="text"
                        id="input-sigla"
                        className="form-control"
                        name="sigla"
                        placeholder="Sigla"
                      />
                    </div>
                  </div>

                  {/* Descripcion */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="input-descripcion" className="form-label">
                        Descripcion
                      </label>
                      <input
                        type="text"
                        id="input-descripcion"
                        className="form-control"
                        name="descripcion"
                        placeholder="Descripcion"
                      />
                    </div>
                  </div>

                  {/* Expirable */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-expirable" className="form-label">
                        Expirable
                      </label>
                      <select
                        className="form-control"
                        id="input-expirable"
                        name="expirable"
                      >
                        <option>Selecionar</option>
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </select>
                    </div>
                  </div>

                  {/* Duracion */}
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="input-duracion" className="form-label">
                        Duracion <small>(en meses)</small>
                      </label>
                      <input
                        type="number"
                        id="input-duracion"
                        className="form-control"
                        name="duracion"
                        placeholder="Duracion"
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Relación Funciones / Documentos</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form>
                {/* checkbox */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Default checkbox
                  </label>
                </div>
                {/* buton */}
                <div className="col-12 d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary me-1 mb-1">
                    Guardar Selección
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light-secondary me-1 mb-1"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DocumentacionRecursos;
