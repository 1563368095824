import React, { useEffect, useState } from 'react';
import { formatDateTime, formatDate } from '../../hooks/useDates';
import useUser from '../../hooks/useUser';
// import { getLastReclamos } from '../../services/reclamos';
import { getRecursosRegionByProveedor } from '../../services/recursos';
import CardUserLogged from '../general/cardsInfo/CardUserLogged';
import RecursosRegiones from '../general/charts/RecursosRegiones';
import UltimosRecursos from '../general/cardsInfo/UltimosRecursos';
import CardInfoInasistencia from '../general/cardsInfo/CardInfoInasistencia';
import UltimasDesvinculaciones from '../general/cardsInfo/UltimasDesvinculaciones';

function DashProveedor() {
  const { apiToken, dataUserLogged } = useUser();
  const [recursosRegiones, setRecursosRegiones] = useState([]);
  const [reclamos, setReclamos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRecursoRegiones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getRecursoRegiones() {
    setIsLoading(true);
    const res = await getRecursosRegionByProveedor(
      dataUserLogged.rut,
      apiToken
    );
    if (res.status === 200) {
      localStorage.setItem('cantidad-recursos', res.recursos_regiones.total);
      setRecursosRegiones(res.recursos_regiones);
      setIsLoading(false);
    } else {
      console.log('error');
      setIsLoading(false);
    }
  }

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Documentos Pendientes
                    </h6>
                    <h4 className="font-extrabold mb-0" id="doc-vencidos">
                      no disp.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Ovt Sin Revisar
                    </h6>
                    <h4 className="font-extrabold mb-0" id="doc-por-vencer">
                      no disp.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Recursos</h6>
                    <h4 className="font-extrabold mb-0" id="cantidad-recursos">
                      {localStorage.getItem('cantidad-recursos')}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Ausencias</h6>
                    <h4
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      no disp.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Cards estadisticas end  */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Recursos por región</h4>
              </div>
              <div className="card-body">
                <div id="chart-profile-visit">
                  <RecursosRegiones recursosRegion={[recursosRegiones]} />
                </div>
                <hr />

                <div className="row">
                  <div className="col-6">
                    <h6>
                      Total pais: {recursosRegiones.total} recursos activos
                    </h6>
                  </div>

                  {/* updated at */}
                  <div className=" col-6 text-end text-muted ">
                    <small>
                      Ultima actualización:{' '}
                      {formatDateTime(recursosRegiones.updated_at)}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4">
            {/* Inasistencia */}
            <CardInfoInasistencia />
          </div>
          <div className="col-12 col-xl-8">
            <div className="card">
              <div className="card-header">
                <h4>Últimos Reclamos</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-lg">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Región</th>
                        <th>Fecha</th>
                        <th>Tipo Reclamo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reclamos.length > 0 ? (
                        reclamos.map((reclamo, i) => (
                          <tr key={i}>
                            <td className="col-5">
                              <div className="d-flex align-items-center">
                                <div className="avatar avatar-md">
                                  <img src="assets/images/faces/5.jpg" alt="" />
                                </div>
                                <p className="font-bold ms-3 mb-0">
                                  {`${reclamo.nombre} ${reclamo.apellido}`}
                                </p>
                              </div>
                            </td>
                            <td className="col-auto">
                              <p className="mb-0">{reclamo.proveedor}</p>
                            </td>
                            <td className="col-auto">
                              <p className="mb-0">
                                {formatDate(reclamo.fecha_incidencia)}
                              </p>
                            </td>
                            <td className="col-auto">
                              <p className="mb-0">{reclamo.tipo_reclamo}</p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="col-5">
                            No hay registros
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />
        {/*  Card ultimos recursos  */}
        <UltimosRecursos
          rut_proveedor={dataUserLogged.rut}
          apiToken={apiToken}
        />
        {/*  Card utimas desviculaciones  */}
        <UltimasDesvinculaciones
          rut_proveedor={dataUserLogged.rut}
          apiToken={apiToken}
        />
      </div>
    </section>
  );
}

export default DashProveedor;
