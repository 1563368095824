import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

const apiKey = 'AIzaSyAEsHxKaNzX-KLTfXMHUnhk1-f_1TvIAXc';

function ModalMapaCliente({ direccion }) {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!direccion) {
      return;
    }
    const loader = new Loader({ apiKey: apiKey });

    loader.load().then(() => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: direccion }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location;

          const map = new window.google.maps.Map(mapRef.current, {
            center: location,
            zoom: 16,
          });

          new window.google.maps.Marker({
            position: location,
            map: map,
          });
        } else {
          console.error('Error geocoding:', status);
        }
      });
    });
  }, [direccion]);

  return (
    <div
      className="modal fade modal-lg"
      id="modalMapaCliente"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Ubicación</h5>
          </div>
          <div className="modal-body">
            <div ref={mapRef} style={{ width: '100%', height: '500px' }}></div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalMapaCliente;
