import { Link } from 'react-router-dom';

function CardUltimosGastosJustificados({ data, perfil }) {
  return (
    <div className="card shadow">
      <div className="card-body px-1">
      <h6 className='text-center'>ÚLTIMAS JUSTIFICADAS</h6>
        <div className="list-group list-group-flush">
          {data.map((justificada) => (
            <Link 
              key={justificada.id} 
              to={`/${perfil}/gastos/${justificada.id}`} 
              className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
            >
              <div className="name col-8">
                <h6 className="mb-1">N° {justificada.correlativo}</h6>
                <small className="text-muted mb-0">{justificada.justifica}</small>
                <p className="text-primary mb-0">
                  {justificada.proveedor_nombre}
                </p>
                <small className="text-muted">
                  {justificada.fecha_justificacion} hrs
                </small>
              </div>
              <div className="col-4 ms-3">
                <h6 className="text-success mb-0">
                  $ {justificada.monto_total}
                </h6>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardUltimosGastosJustificados;
