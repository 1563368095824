export const estadosSolicitudes = [
  {
    id: 1,
    estado: 'Generada',
    descripcion: 'Solicitud generada, pendiente de preaprobacion',
  },
  {
    id: 2,
    estado: 'Preaprobada',
    descripcion:
      'Solicitud preaprobada para ejecucion, pendiente de justificación',
  },
  {
    id: 3,
    estado: 'Justificada',
    descripcion:
      'Servicio ejecutado, solicitud justificada, pendiente de aprobación',
  },
  {
    id: 4,
    estado: 'Aprobada',
    descripcion: 'Solicitud aprobada, pendiente de consolidación',
  },
  {
    id: 5,
    estado: 'Consolidada',
    descripcion: 'Solicitud consolidada, Cerrada',
  },
  {
    id: 6,
    estado: 'Anulada',
    descripcion: 'Se cancela ejecucion del servicio. Cerrada',
  },
  {
    id: 7,
    estado: 'Rechazada',
    descripcion: 'Solicitud rechazada. Cerrada',
  },
];
