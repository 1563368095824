import { getGastosPendientesPreaprobacion } from '../../../services/gastosAdicionales';
import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { Link } from 'react-router-dom';
import CustomTable from '../../general/CustomTable';

const columns = [
  {
    name: 'Sol. N°',
    selector: (row) => row.correlativo,
    sortable: true,
    clave: 'correlativo',
    width: '90px',
  },
  {
    name: 'Fecha',
    selector: (row) => row.fecha_atencion,
    sortable: true,
    clave: 'fecha_atencion',
  },
  {
    name: 'Proveedor',
    selector: (row) => row.proveedor_nombre,
    sortable: true,
    clave: 'proveedor_nombre',
  },
  {
    name: 'Recurso',
    selector: (row) => row.nombre,
    sortable: true,
    clave: 'nombre',
  },
  {
    name: 'Apellido',
    selector: (row) => row.apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'Monto',
    selector: (row) => row.monto_total,
    sortable: true,
    clave: 'monto_total',
  },
  {
    name: 'Estado',
    selector: (row) => row.estado,
    sortable: true,
    clave: 'estado',
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link
        to={`/administracion/gastos/${row.id}`}
        className="btn btn-outline-primary btn-sm"
      >
        <i className="bi bi-eye"></i>
      </Link>
    ),
    width: '5%',
    right: true,
  },
];

function TablaGastosPendPreap() {
  const { apiToken } = useUser();
  const [gastos, setGastos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getGastosPendientesPreaprobacion(apiToken);
      setGastos(data.gastos_adicionales);
      setIsLoading(false);
    };
    fetchData();
  }, [apiToken]);

  const excelName = `Gastos adicionales pendientes de preaprobación`;

  return (
    <div className="row" id="table-hover-row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              GASTOS ADICIONALES PENDIENTES DE PREAPROBACIÓN
            </h4>
          </div>
          <div className="card-body">
            <CustomTable
              columns={columns}
              data={gastos}
              isLoading={isLoading}
              excelName={excelName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablaGastosPendPreap;
