import { useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import ComunasSelect from '../ComunasSelect';
import RegionesSelect from '../RegionesSelect';
import ProyectosSelect from '../ProyectosSelect';

// file resizer
const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      'auto'
    );
  });
};

const FileInput = ({ inputRef, handleFileChange }) => {
  return (
    <div className="form-group">
      <div className="custom-file">
        <input
          ref={inputRef}
          type="file"
          className="custom-file-input"
          id="imageFile"
          onChange={handleFileChange}
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <label className="custom-file-label" htmlFor="imageFile">
          Elegir archivo
        </label>
      </div>
    </div>
  );
};

const ImageUploadPreview = ({
  previewImage,
  handleFileDrop,
  handleFileDragOver,
}) => {
  return (
    <div className="form-group text-center border border-primary">
      <div
        className="image-upload-preview"
        onDrop={handleFileDrop}
        onDragOver={handleFileDragOver}
      >
        <p className="text-muted">Puede arrastrar imagen aqui</p>
        {previewImage && (
          <img
            className="img-fluid mx-auto"
            src={previewImage}
            alt="Upload Preview"
          />
        )}
      </div>
    </div>
  );
};

const iniTialState = {
  id: '',
  overtime_id: '',
  horas_ticket: '',
};

const generateId = () => {
  const num = Math.floor(Math.random() * 100000000);
  return `R${num.toString().padStart(8, '0')}`;
};

function ModalAddTicketOvt({ rendicion_id, onChange }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [ticket, setTicket] = useState(iniTialState);
  const closemodalRef = useRef(null);
  const formTicket = useRef(null);
  const [selDefault, setSelDefault] = useState('');
  const [nomenclatura, setNomenclatura] = useState('');
  const [ticketInput, setTicketInput] = useState({
    tipo: 'number',
    placeholder: 'Numero de ticket',
  });

  const handleChange = (e) => {
    setTicket({
      ...ticket,
      [e.target.name]: e.target.value,
    });
  };

  const seleccionProyecto = (proyecto) => {
    setTicket({
      ...ticket,
      proyecto_id: proyecto.id,
      proyecto: proyecto.proyecto,
      nomenclatura: proyecto.nomenclatura,
    });
    setNomenclatura(proyecto.nomenclatura);
    proyecto.nomenclatura !== 'OTS'
      ? setTicketInput({ tipo: 'number', placeholder: 'Numero de ticket' })
      : setTicketInput({
          tipo: 'text',
          placeholder: 'Actividad (breve)ej: Trabajo Bodega',
        });
  };

  const seleccionComuna = (comuna) => {
    setTicket({
      ...ticket,
      comuna_id: comuna.id,
      comuna: comuna.comuna,
    });
  };

  const seleccionRegion = (region) => {
    setTicket({
      ...ticket,
      region_id: region.id,
      region: region.region,
    });
  };

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(file);

      const supportedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];

      if (file.type.startsWith('image/')) {
        resizeFile(file).then((uri) => {
          setFile(uri);
          setPreviewImage(fileURL);
        });
      } else if (supportedTypes.includes(file.type)) {
        setFile(file);
        setPreviewImage(null);
      } else {
        throw new Error('File type not supported');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const fileURL = URL.createObjectURL(file);

    const supportedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (file.type.startsWith('image/')) {
      resizeFile(file).then((uri) => {
        setFile(uri);
        setPreviewImage(fileURL);
      });
    } else if (supportedTypes.includes(file.type)) {
      setFile(file);
      setPreviewImage(null);
    } else {
      console.log('File type not supported');
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
  };

  const calculateTicketHours = () => {
    const startHour = parseInt(ticket.hora_inicio.split(':')[0], 10);
    const startMinute = parseInt(ticket.hora_inicio.split(':')[1], 10);
    const endHour = parseInt(ticket.hora_termino.split(':')[0], 10);
    const endMinute = parseInt(ticket.hora_termino.split(':')[1], 10);
    const totalMinutes =
      endHour * 60 + endMinute - startHour * 60 - startMinute;
    const hoursDiff = Math.floor(totalMinutes / 60);
    const minutesDiff = totalMinutes % 60;
    const formattedDiff = `${hoursDiff
      .toString()
      .padStart(2, '0')}:${minutesDiff.toString().padStart(2, '0')}`;
    setTicket({
      ...ticket,
      horas_ticket: formattedDiff,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      ticket.hora_inicio == null ||
      ticket.hora_inicio.trim() === '' ||
      ticket.hora_termino == null ||
      ticket.hora_termino.trim() === '' ||
      ticket.horas_ticket == null ||
      ticket.horas_ticket.trim() === '' ||
      ticket.direccion == null ||
      ticket.direccion.trim() === '' ||
      ticket.comuna_id == null ||
      ticket.comuna_id.trim() === '' ||
      ticket.region_id.trim() === '' ||
      ticket.proyecto_id == null ||
      ticket.proyecto_id.trim() === '' ||
      ticket.ticket == null ||
      ticket.ticket.trim() === ''
    ) {
      alert('Debe completar todos los campos requeridos');
      return;
    }

    // update the state with the ticket data
    const newTicket = {
      ...ticket,
      file: file,
      id: generateId(),
      overtime_id: rendicion_id,
    };
    setTicket(newTicket);

    // call the onChange prop with the ticket data
    onChange(newTicket);

    // reset the form and close the modal
    handleCancel();
    // simulate a click on the close button
  };

  const handleCancel = () => {
    setPreviewImage(null);
    setFile(null);
    setSelDefault(generateId());
    formTicket.current.reset();
    setTicket(iniTialState);
    closemodalRef.current.click();
  };
  return (
    <div
      className="modal fade modal-lg"
      id="modalAddTicket"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Agregar Ticket Overtime</h5>
          </div>
          <div className="modal-body">
            <form
              id="formAddTicket"
              className="form mb-4"
              data-parsley-validate
              onSubmit={handleSubmit}
              ref={formTicket}
            >
              <div className="row">
                {/* Proyecto */}
                <div className="col-md-5 col-12">
                  <ProyectosSelect
                    onSelect={seleccionProyecto}
                    selDefault={selDefault}
                    ocultarInfo={true}
                  />
                </div>

                {/* Ticket */}
                <div className="col-md-7 col-12 mt-4">
                  <div className="input-group mt-2">
                    <span className="input-group-text col-2" id="nomenclatura">
                      {nomenclatura}
                    </span>

                    <input
                      type={ticketInput.tipo}
                      className="form-control"
                      placeholder={ticketInput.placeholder}
                      name="ticket"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {/* Direccion */}
                <div className="col-md-6 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="direccion" className="form-label">
                      Dirección
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="direccion"
                      id="direccion"
                      placeholder="Dirección"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Comuna */}
                <div className="col-md-3 col-12">
                  <div className="form-group mandatory">
                    <ComunasSelect
                      seleccion={seleccionComuna}
                      selDefault={selDefault}
                    />
                  </div>
                </div>

                {/* Region */}
                <div className="col-md-3 col-12">
                  <div className="form-group mandatory">
                    <RegionesSelect
                      seleccion={seleccionRegion}
                      selDefault={selDefault}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Hora inicio */}
                <div className="col-md-4 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="hora_inicio" className="form-label">
                      Hora Inicio
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="hora_inicio"
                      id="hora_inicio"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Hora Termino */}
                <div className="col-md-4 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="hora_termino" className="form-label">
                      Hora Termino
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="hora_termino"
                      id="hora_termino"
                      required
                      onChange={handleChange}
                      onBlur={calculateTicketHours}
                    />
                  </div>
                </div>
                {/* Horas ticket */}
                <div className="col-md-4 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="horas_ticket" className="form-label">
                      Horas Ticket
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="horas_ticket"
                      id="horas_ticket"
                      value={ticket.horas_ticket}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="form-group">
                    <label htmlFor="observaciones" className="form-label">
                      Observacion
                    </label>
                    <textarea
                      className="form-control"
                      name="observaciones"
                      id="observaciones"
                      rows="3"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <p className="text-muted">Comprobante de atencion</p>
                <FileInput
                  inputRef={inputFile}
                  handleFileChange={handleFileChange}
                />
                <ImageUploadPreview
                  previewImage={previewImage}
                  handleFileDrop={handleFileDrop}
                  handleFileDragOver={handleFileDragOver}
                />
                <div className="alert alert-light-info">
                  Permite el envio de PDF, e imagenes jpg, jpeg, png
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleCancel}
              ref={closemodalRef}
            >
              Cancelar
            </button>
            <button
              type="submit"
              form="formAddTicket"
              className="btn btn-primary"
              // data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              Agregar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAddTicketOvt;
