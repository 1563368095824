import React, { useRef, useState } from 'react';
import useUser from '../../../../hooks/useUser';
import { createOvertime } from '../../../../services/overtimes';

import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../../general/Alerts';
import CardUserLogged from '../../../general/cardsInfo/CardUserLogged';
import RecursosSelect from '../../../general/forms/RecursosSelect';

function OvtNuevoIbm() {
  const { dataUserLogged, apiToken } = useUser();
  const formRef = useRef(null); // referencia para el formulario
  const selectRef = useRef(null);

  const [data, setData] = useState({
    solicitante_id: dataUserLogged.id || '',
    dia_feriado: 0,
    tipo_dia: 'Normal',
  });

  // Seleccion del recurso en el componente RecursosProveedorSelect
  const seleccionRecurso = (recurso) => {
    setData({
      ...data,
      recurso_rut: recurso.rut || '',
      recurso_nombre: recurso.nombre || '',
      recurso_apellido: recurso.apellido || '',
      proveedor_rut: recurso.rut_proveedor || '',
    });
  };

  const handleTipoDiaChange = (e) => {
    const value = e.target.value;
    const isFeriado = value.includes('Feriado');
    setData({
      ...data,
      tipo_dia: value,
      dia_feriado: isFeriado ? 1 : 0,
    });
  };

  // onchange de los inputs
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = () => {
    // resetear el formulario y el select
    formRef.current.reset();
    if (selectRef.current.handleFormReset) {
      selectRef.current.handleFormReset();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      data.dia_feriado == null ||
      data.fecha_ovt == null ||
      data.fecha_ovt.trim() === '' ||
      data.horas_solicitadas == null ||
      data.horas_solicitadas.trim() === '' ||
      data.proveedor_rut == null ||
      data.proveedor_rut.trim() === '' ||
      data.recurso_rut == null ||
      data.recurso_rut.trim() === '' ||
      data.solicitante_id == null ||
      data.solicitante_id.trim() === '' ||
      data.tipo_dia == null ||
      data.tipo_dia.trim() === ''
    ) {
      AlertaError({
        title: 'Error',
        error: 'Debe completar todos los campos requeridos',
      });
      return;
    }

    AlertaConfirm({
      title: '¿Está seguro de crear la solicitud?',
      text: 'Se creará la solicitud de overtime',
      callback: async () => {
        createOvertime(data, apiToken)
          .then((res) => {
            if (res.status === 200) {
              AlertaSuccess({
                title: 'Solicitud creada',
                text: 'La solicitud de overtime se creó correctamente',
                callback: () => handleReset(),
              });
            } else {
              AlertaError({
                title: 'Error al crear la solicitud',
                error: res.message,
              });
            }
          })
          .catch((err) => {
            AlertaError({
              title: 'Error al crear la solicitud',
              text: 'Error al crear la solicitud',
            });
          });
      },
    });
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">SOLICITUD DE OVERTIME</h4>
          </div>
          <div className="card-body">
            {/* Divider */}
            <div className="divider divider-left mt-0">
              <div className="divider-text">INFORMACIÓN SOLICITUD</div>
            </div>
            <form
              className="form"
              data-parsley-validate
              id="form-gasto-adicional"
              ref={formRef} // pasa la referencia al formulario
              onSubmit={handleSubmit}
            >
              {/* Recurso */}
              <div className="col-12">
                <RecursosSelect
                  onSelect={(recurso) => seleccionRecurso(recurso)}
                  onReset={() => selectRef.current.handleFormReset()}
                  selectRef={selectRef}
                />
              </div>
              <div className="row">
                {/* Fecha Overtime */}
                <div className="col-md-3 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="fecha_ovt" className="form-label">
                      Fecha Overtime
                    </label>
                    <input
                      type="date"
                      id="fecha_ovt"
                      className="form-control"
                      name="fecha_ovt"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* Cantidad de horas */}
                <div className="col-md-3 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="horas_solicitadas" className="form-label">
                      Horas solicitadas
                    </label>
                    <input
                      type="time"
                      id="horas_solicitadas"
                      className="form-control"
                      name="horas_solicitadas"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                {/* Tipo de día */}
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label htmlFor="tipo_dia" className="form-label">
                      Tipo de día
                    </label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="tipo_dia_normal"
                          name="tipo_dia"
                          value="Normal"
                          checked={data.tipo_dia === 'Normal'}
                          onChange={handleTipoDiaChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tipo_dia_normal"
                        >
                          Normal
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="tipo_dia_feriado_normal"
                          name="tipo_dia"
                          value="Feriado normal"
                          checked={data.tipo_dia === 'Feriado normal'}
                          onChange={handleTipoDiaChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tipo_dia_feriado_normal"
                        >
                          Feriado normal
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="tipo_dia_feriado_irrenunciable"
                          name="tipo_dia"
                          value="Feriado irrenunciable"
                          checked={data.tipo_dia === 'Feriado irrenunciable'}
                          onChange={handleTipoDiaChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tipo_dia_feriado_irrenunciable"
                        >
                          Feriado irrenunciable
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Observacion */}
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label htmlFor="observacion_s" className="form-label">
                    Comentario a la solicitud
                  </label>
                  <textarea
                    id="observacion_s"
                    className="form-control"
                    placeholder="Observacion"
                    rows="2"
                    name="observacion"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-between ">
                {/* Boton cancelar */}
                <button
                  type="reset"
                  className="btn btn-danger me-1 mb-1"
                  onClick={handleReset}
                >
                  Limpiar formulario
                </button>

                {/* Boton enviar*/}
                <button type="submit" className="btn btn-primary me-1 mb-1">
                  <i className="bi bi-cloud-arrow-up"></i> Enviar Solicitud
                  Overtime
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Tercera Columna */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />
      </div>
    </section>
  );
}

export default OvtNuevoIbm;
