// development
// export const apiUrl = (path) => `http://localhost:8000/${path}`;

// production
export const apiUrl = (path) => `https://apigepro.mapp.cl/${path}`;

// url S3
export const urlS3 = 'https://mappstorage.s3.amazonaws.com/gepro/';

// url S3 gastos_adicionales
export const urlS3GastosAdicionales =
  'https://mappstorage.s3.amazonaws.com/gepro/gasto_adicional/';

// url S3 overtime
export const urlS3Overtime =
  'https://mappstorage.s3.amazonaws.com/gepro/overtimes/';
