import { useEffect, useState } from 'react';
import { formatDate, formatDateTime } from '../../../hooks/useDates';
import useUser from '../../../hooks/useUser';
import { addTicketOvertime } from '../../../services/overtimes';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerLoad } from '../../general/loader/SpinerLoader';
import ModalAddTicketOvt from '../../general/forms/ovt/ModalAddTicketOvt';
import ModalComprobanteOvt from '../../general/overtime/ModalComprobanteOvt';

function OvertimeDetalle({ data, updHora, actualizarData }) {
  const { apiToken } = useUser();
  const [verTicket, setVerTicket] = useState(null);
  const [totalHoras, setTotalHoras] = useState('');
  const [dataTickets, setDataTickets] = useState([]);
  const [ticketsEliminados, setTicketsEliminados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data && data.tickets && data.tickets.length > 0) {
      setDataTickets(data.tickets);
      setTotalHoras(calculateTotalHoras(data.tickets));
      updHora(calculateTotalHoras(data.tickets));
    }
  }, [data, updHora]);

  function calculateTotalHoras(tickets) {
    let totalMinutos = 0;
    tickets.forEach((ticket) => {
      const horasMinutos = ticket.horas_ticket.split(':');
      const horas = parseInt(horasMinutos[0]);
      const minutos = parseInt(horasMinutos[1]);
      totalMinutos += horas * 60 + minutos;
    });
    const horas = Math.floor(totalMinutos / 60);
    const minutos = totalMinutos % 60;
    return `${horas}:${minutos < 10 ? '0' : ''}${minutos}`;
  }

  const handleAddTicket = async (ticket) => {
    // agregar el ticket a data.tickets
    const newTickets = [...dataTickets, ticket];
    setDataTickets(newTickets);
    setTotalHoras(calculateTotalHoras(newTickets));
  };

  const handleDeleteTicket = async (id) => {
    const newTickets = dataTickets.filter((t) => t.id !== id);
    setDataTickets(newTickets);
    if (!id.startsWith('R')) {
      setTicketsEliminados([...ticketsEliminados, { id: id }]);
    }
    setTotalHoras(calculateTotalHoras(newTickets));
  };

  const handleModalClose = () => {
    setVerTicket(null);
  };

  const handleGuardarTickets = async () => {
    // Filtrar los tickets por ID que comience con "R"
    const filteredTickets = dataTickets.filter((ticket) =>
      ticket.id.startsWith('R')
    );
    if (filteredTickets.length > 0 || ticketsEliminados.length > 0) {
      AlertaConfirm({
        title: '¿Guardar Cambios en la Solicitud?',
        text: `Se guardaran los cambios realizados a los tickets de la solicitud: ${data.correlativo}`,
        callback: async () => {
          setIsLoading(true);

          const newData = {
            fecha: data.created_at,
            tickets: filteredTickets,
            tickets_eliminados: ticketsEliminados,
          };
          const res = await addTicketOvertime(newData, apiToken);
          if (res && res.status === 200) {
            AlertaSuccess({
              title: 'Cambios Guardados',
              success: `Se guardaron los cambios realizados a los tickets de la solicitud: ${data.correlativo}`,
            });
            setTicketsEliminados([]);
            updHora(totalHoras);
          } else {
            AlertaError({
              title: 'Error al guardar cambios',
              error: 'Ocurrio un error al guardar los cambios',
            });
          }
          setIsLoading(false);
          actualizarData(true);
        },
      });
    } else {
      AlertaSuccess({
        title: 'No es necesario guardar cambios',
        success: `No se realizaron cambios a los tickets de la solicitud: ${data.correlativo}, puede justificar la solicitud.`,
      });
      updHora(totalHoras);
    }
  };

  return !data ? null : (
    <div className="card">
      {isLoading && <SpinerLoad />}
      <div className="card-header">
        <h5 className="card-title">INFORMACIÓN SERVICIO OVERTIME</h5>
      </div>
      <div className="card-body">
        {/* Datos solicitud */}
        <div className="d-flex justify-content-between flex-wrap">
          <div className="col-md-10 col-12 order-md-1 order-2">
            <div className="row">
              <div className="col-md-6 col-12">
                <p>
                  <strong>Fecha Solicitud: </strong>
                  {formatDateTime(data.created_at)}
                </p>
              </div>
              <div className="col-md-6 col-12">
                <p>
                  <strong>Solicitado por: </strong>
                  {data.solicitante}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <p>
                  <strong>Fecha Overtime: </strong>
                  {formatDate(data.fecha_ovt)}
                </p>
              </div>
              {Number(data.dia_feriado) === 1 ? (
                <div className="col-md-6 col-12">
                  <strong className="text-danger">Dia: </strong>
                  {data.tipo_dia}
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <p>
                  <strong>Recurso Asignado: </strong>
                  {data.nombre} {data.apellido}
                </p>
              </div>
              <div className="col-md-3 col-12">
                <p>
                  <strong>Proveedor: </strong>
                  {data.proveedor_nombre}
                </p>
              </div>
              <div className="col-md-3 col-12">
                <p>
                  <strong>Region: </strong>
                  {data.region}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <p className="card-text my-3">
                <strong>Observación: </strong>
                {data.observacion}
              </p>
              <h5 className="mt-3">
                <strong>Horas solicitadas: </strong>
                {data.horas_solicitadas.slice(0, 5)} hrs
              </h5>
            </div>
          </div>

          {/* Solicitud / Horas */}
          <div className="col-md-2 col-12 order-md-2 order-1">
            <div className="row">
              <div className="col-6 col-md-12">
                <strong>Sol. N°:</strong>
                <h4>{data.correlativo}</h4>
              </div>

              <div className="col-6 col-md-12">
                <strong>Horas a pagar:</strong>
                <h2>
                  {data.estado_id < 4
                    ? totalHoras
                    : data.horas_aprobadas?.slice(0, 5) ||
                      data.horas_justificadas?.slice(0, 5) ||
                      '0'}
                  hrs
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* Divider PREAPRUEBA*/}
        {data.estado_id >= 2 && (
          <>
            <div className="divider divider-center mt-2">
              <div className="divider-text">PREAPROBACIÓN</div>
            </div>
            <div className="row">
              <p className="card-text col-md-4 col-12">
                <strong>Preaprueba: </strong>
                {data.preaprueba}
              </p>
              <p className="card-text col-md-5 col-12">
                <strong>Fecha preaprobacion: </strong>
                {formatDateTime(data.fecha_preaprobacion)}
              </p>
            </div>

            <div className="d-flex justify-content-between">
              <p className="card-text">
                <strong>Com. Preaprobacion: </strong>
                {data.comentario_preaprobacion}
              </p>
              <h5>
                <strong>Horas preaprobadas: </strong>
                {data.horas_preaprobadas &&
                  data.horas_preaprobadas.slice(0, 5)}{' '}
                hrs
              </h5>
            </div>
          </>
        )}
        {/* Divider  JUSTIFICA*/}
        {data.estado_id >= 3 && (
          <>
            <div className="divider divider-center mt-2">
              <div className="divider-text">JUSTIFICACIÓN DEL OVERTIME</div>
            </div>
            <div className="row">
              <p className="card-text col-md-4 col-12">
                <strong>Justifica:</strong> {data.justifica}
              </p>
              <p className="card-text col-md-5 col-12">
                <strong>Fecha preaprobacion: </strong>
                {formatDateTime(data.fecha_justificacion)}
              </p>
            </div>

            <div className="d-flex justify-content-between">
              <p className="card-text">
                <strong>Com. Justificacion: </strong>
                {data.comentario_preaprobacion}
              </p>
              <h5>
                <strong>Horas justificadas: </strong>
                {data.horas_justificadas &&
                  data.horas_justificadas.slice(0, 5)}{' '}
                hrs
              </h5>
            </div>
          </>
        )}
        {/* Divider  APRUEBA*/}
        {data.estado_id >= 4 && (
          <>
            <div className="divider divider-center mt-2">
              <div className="divider-text">APROBACIÓN FINAL</div>
            </div>
            <div className="row">
              <p className="card-text col-md-4 col-12">
                <strong>Aprueba: </strong>
                {data.aprueba}
              </p>
              <p className="card-text col-md-5 col-12">
                <strong>Fecha aprobacion: </strong>
                {formatDateTime(data.fecha_aprobacion)}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="card-text">
                <strong>Com. Aprobacion: </strong>
                {data.comentario_aprobacion}
              </p>
              <h5>
                <strong>Horas aprobadas: </strong>
                {data.horas_aprobadas && data.horas_aprobadas.slice(0, 5)} hrs
              </h5>
            </div>{' '}
            {/* Divider  ANULACION*/}
            {data.estado_id >= 6 && (
              <>
                <div className="divider divider-center mt-2">
                  <div className="divider-text text-danger">ANULACIÓN</div>
                </div>
                <div className="row">
                  <p className="card-text col-md-4 col-12">
                    <strong className="text-danger">Anula:</strong> {data.anula}
                  </p>
                  <p className="card-text col-md-5 col-12">
                    <strong className="text-danger">Fecha anulación: </strong>
                    {formatDateTime(data.updated_at)}
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <p className="card-text">
                    <strong className="text-danger">Com. Anulacion:</strong>{' '}
                    {data.comentario_anulacion || 'Sin Comentario'}
                  </p>
                </div>
              </>
            )}
          </>
        )}

        {/* Divider */}
        <div className="divider divider-left mt-2">
          <div className="divider-text">TICKETS</div>
        </div>
        {/* Boton agregar ticket */}
        <div className="row">
          <div className="col-12">
            {data.estado === 'Preaprobada' && (
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modalAddTicket"
              >
                Agregar Ticket
              </button>
            )}
          </div>
        </div>

        {/* Tickets */}
        <div className="row">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="col-2">Ticket</th>
                  <th className="col-1">Proyecto</th>
                  <th className="col-2">Direccion</th>
                  <th className="col-2">Comuna</th>
                  <th className="col-1">Hora Inicio</th>
                  <th className="col-1">Hora Termino</th>
                  <th className="col-1">Horas Ticket</th>
                  <th className="col-2">Observación</th>
                  <th className="col-2">Ver</th>
                </tr>
              </thead>
              <tbody>
                {dataTickets.map((ticket, i) => (
                  <tr key={i}>
                    <th scope="row">{`${ticket.nomenclatura}${ticket.ticket}`}</th>
                    <td>{ticket.proyecto}</td>
                    <td>{ticket.direccion}</td>
                    <td>{ticket.comuna}</td>
                    <td>{ticket.hora_inicio}</td>
                    <td>{ticket.hora_termino}</td>
                    <td>{ticket.horas_ticket}</td>
                    <td>{ticket.observaciones}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        {ticket.comprobante && (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#modalVerComprobante"
                            onClick={() => setVerTicket(ticket)}
                          >
                            <i className="bi bi-eye"></i>
                          </button>
                        )}
                        {/* boton eliminar */}
                        {data.estado === 'Preaprobada' && (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() =>
                              handleDeleteTicket(
                                ticket.id || ticket.ticket + ticket.hora_inicio
                              )
                            }
                          >
                            <i className="bi bi-trash3"></i>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* boton guardar tickets */}
          <div className="col-12">
            {data.estado === 'Preaprobada' && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleGuardarTickets}
              >
                Guardar Cambios
              </button>
            )}
          </div>
        </div>
      </div>
      <ModalAddTicketOvt
        rendicion_id={data.id}
        apiToken={apiToken}
        onChange={handleAddTicket}
      />
      <ModalComprobanteOvt
        ticket={verTicket}
        handleModalClose={handleModalClose}
      />
    </div>
  );
}

export default OvertimeDetalle;
