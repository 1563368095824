// import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
// import { getRecursosRegionByProveedor } from '../../../services/recursos';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import UltimasDesvinculaciones from '../../general/cardsInfo/UltimasDesvinculaciones';
import UltimosRecursos from '../../general/cardsInfo/UltimosRecursos';
import TablaRecursosProveedor from './TablaRecursosProveedor';

function VerRecursosProveedor() {
  const { apiToken, dataUserLogged } = useUser();

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {/*  Cards estadisticas  */}
        <div className="row">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon green mb-2">
                      <i className="iconly-boldProfile"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Recursos Activos
                    </h6>
                    <h6 className="font-extrabold mb-0" id="cantidad-recursos">
                      {localStorage.getItem('cantidad-recursos')}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon red mb-2">
                      <i className="iconly-boldDanger"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Ausencias Reportadas
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-vencidos">
                      no disp.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon bg-light-warning mb-2">
                      <i className="iconly-boldPaper"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">
                      Licencias Medicas
                    </h6>
                    <h6 className="font-extrabold mb-0" id="doc-por-vencer">
                      no disp.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-lg-3 col-md-6">
            <div className="card">
              <div className="card-body px-4 py-4-5">
                <div className="row">
                  <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                    <div className="stats-icon blue mb-2">
                      <i className="iconly-boldFolder"></i>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                    <h6 className="text-muted font-semibold">Con Vacaciones</h6>
                    <h6
                      className="font-extrabold mb-0"
                      id="cantidad-proveedores"
                    >
                      no disp.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabla recursos del provedor */}
        <TablaRecursosProveedor />
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />

        {/*  Card ultimos recursos  */}
        <UltimosRecursos
          rut_proveedor={dataUserLogged.rut}
          apiToken={apiToken}
        />

        {/* Ultimas desvinculaciones */}
        <UltimasDesvinculaciones
          rut_proveedor={dataUserLogged.rut}
          apiToken={apiToken}
        />

        {/*  Card asistencia  */}
        <div className="card">
          <div className="card-header">
            <h4>Asistencia</h4>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerRecursosProveedor;
