import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { getRecursosByProveedor } from '../../../services/recursos';

function RecursosProveedorSelect({ onSelect, onReset, selectRef }) {
  // obtengo apiToken y dataUserLogged del contexto usando el hook personalizado
  const { apiToken, dataUserLogged } = useUser();
  const [recursos, setRecursos] = useState([]);
  const [desvinculados, setDesvinculados] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  // uso el hook useEffect para llamar a getRecursosByProveedor y establecer el estado recursos con los datos devueltos
  useEffect(() => {
    getRecursosByProveedor(dataUserLogged.rut, apiToken).then((data) => {
      setRecursos(data.recursos);
      setDesvinculados(data.desvinculados);
    });
  }, [apiToken, dataUserLogged.rut]);

  function handleChange(value) {
    const recursoEnRecursos = recursos.find((recurso) => recurso.rut === value);
    const recursoEnDesvinculados = desvinculados.find(
      (recurso) => recurso.rut === value
    );
    const recursoSeleccionado = recursoEnRecursos || recursoEnDesvinculados;
    onSelect(recursoSeleccionado);
    setSelectedOption(value);
  }

  const handleFormReset = () => {
    setSelectedOption('');
    if (onReset) {
      onReset();
    }
  };

  return (
    <div className="col-md-12 col-12">
      <div className="form-group mandatory">
        <label htmlFor="input-recurso" className="form-label">
          Recurso{' '}
          <small className="text-info">
            (Lista incluye desvinculados dentro de los últimos 30 días)
          </small>
        </label>
        <select
          value={selectedOption}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          onReset={handleFormReset}
          className="form-select"
          id="input-recurso"
          ref={selectRef}
        >
          <option value={''}>Seleccionar recurso</option>

          {/* Recursos vinculados */}
          <optgroup label="Activos">
            {recursos.map((recurso) => (
              <option key={recurso.rut} value={recurso.rut}>
                {`${recurso.nombre} ${recurso.apellido}`}
              </option>
            ))}
          </optgroup>

          {/* Recursos desvinculados */}
          <optgroup label="Recursos desvinculados">
            {desvinculados.map((recurso) => (
              <option key={recurso.rut} value={recurso.rut}>
                {`${recurso.nombre} ${recurso.apellido}`}
              </option>
            ))}
          </optgroup>
        </select>
      </div>
    </div>
  );
}

export default RecursosProveedorSelect;
