import { apiUrl } from '../config';

// const apiToken = sessionStorage.getItem('api_token');

// Obtiene los proveedores
export const getProveedores = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/proveedores')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Crear un proveedor
export const createProveedor = async (data, apiToken) => {
  const formdata = new FormData();
  formdata.append('rut', data.rut);
  formdata.append('nombre', data.nombre);
  formdata.append('razon_social', data.razon_social);
  formdata.append('giro', data.giro);
  formdata.append('direccion', data.direccion);
  formdata.append('region', data.region);
  formdata.append('comuna', data.comuna);
  formdata.append('telefono', data.telefono);
  formdata.append('email', data.email);
  formdata.append('imagen', data.imagen);

  const response = await fetch(`${apiUrl('administracion/proveedor')}`, {
    method: 'POST',
    body: formdata,
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  });
  const result = await response.json();
  result.status = response.status;
  return result;
};

// Obtiene un proveedor
export const getProveedor = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/proveedor')}/${id}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else {
    console.log(data);
  }
};

// Obtiene el representante legal de un proveedor
export const getRepresentanteLegal = async (id, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/representante')}/${id}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.status === 200) {
    return data;
  } else {
    console.log(data);
  }
};
