import { useState, useEffect } from 'react';
import { estadosSolicitudes } from '../../../services/estadosSolicitudes';

function EstadosSolicitudes() {
  const [estados, setEstados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setEstados(estadosSolicitudes);
    setIsLoading(false);
  }, []);

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>
            Información de los estados posibles para las solicitudes de Gastos y
            Ovt
          </h3>
          {/* table */}
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="col-1">Id</th>
                <th className="col-2">Estado</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <>
                  <tr>
                    <td colSpan="3">Cargando...</td>
                  </tr>
                </>
              ) : (
                estados.map((estado) => (
                  <tr key={estado.id}>
                    <td>{estado.id}</td>
                    <td>{estado.estado}</td>
                    <td>{estado.descripcion}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default EstadosSolicitudes;
