import { useRef, useState } from 'react';

function ModalEditHoras({ hrSolicitada, updateData }) {
  const [dataItem, setDataItem] = useState(null);
  const inputHorasPreaprobadas = useRef(null);
  const inputComentarioItem = useRef(null);
  const inpitHorasSolicitadas = useRef(null);

  const handleChange = (e) => {
    setDataItem({
      ...dataItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpload = () => {
    updateData(dataItem);
    handleCancel();
  };

  const handleCancel = () => {
    setDataItem(null);
    inputHorasPreaprobadas.current.value = '';
    inputComentarioItem.current.value = '';
  };

  return (
    <div
      className="modal fade modal-lg"
      id="modalEditarHoras"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Preaprobar horas</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group mb-4 col-3">
                <label htmlFor="horas-solicitadas">Horas solicitadas:</label>
                <input
                  ref={inpitHorasSolicitadas}
                  type="time"
                  className="form-control"
                  id="horas-solicitadas"
                  name="horas_solicitadas"
                  value={hrSolicitada.slice(0, -3)}
                  disabled
                />
              </div>
              <div className="form-group mb-4 col-3">
                <label htmlFor="horas-preaprobadas">Horas preaprobadas:</label>
                <input
                  ref={inputHorasPreaprobadas}
                  type="time"
                  className="form-control"
                  id="horas-preaprobadas"
                  name="horas_preaprobadas"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label htmlFor="comentarioPreaprobacion">
                  Comentario Preaprobacion
                </label>
                <textarea
                  ref={inputComentarioItem}
                  type="text"
                  className="form-control"
                  id="comentarioPreaprobacion"
                  name="comentario_preaprobacion"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleUpload}
            >
              Modificar horas
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEditHoras;
