import { apiUrl } from '../config';

/** PROVEEDOR */

//  create servicio adicional send data to api json
export const createServicioAdicional = async (data, apiToken) => {
  const response = await fetch(`${apiUrl('proveedor/gastos/nuevo')}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  res.status = response.status;
  return res;
};

//  get servicios adicionales by proveedor
export const getServiciosAdicionalesProveedor = async (
  apiToken,
  id,
  mes,
  ano
) => {
  const response = await fetch(
    `${apiUrl('proveedor/gastos/mes/')}${id}/${mes}/${ano}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Get gasto adicional by id
export const getGastoAdicionalById = async (id, apiToken, rut) => {
  const response = await fetch(`${apiUrl('proveedor/gastos/')}${id}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// upload image to api
export const uploadComprobanteGasto = async (file, data) => {
  const formData = new FormData();
  formData.append('item_id', data.item_id);
  formData.append('solicitud_id', data.solicitud_id);
  formData.append('fecha', data.fecha);
  formData.append('proveedor_rut', data.proveedor_rut);
  formData.append('monto_justificado', data.monto_justificado);
  formData.append('monto_total', data.monto_total);
  formData.append('comentario_justificacion', data.comentario_justificacion);
  formData.append('justifica_id', data.justifica_id);
  formData.append('image', file);

  const response = await fetch(`${apiUrl('proveedor/gastos/comprobante')}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${data.apiToken}`,
    },
    body: formData,
  });
  const res = await response.json();
  res.status = response.status;
  return res;
};

// Aceptar Justificar Gasto Adicional
export const JustificarGastoAdicional = async (actualizar, apiToken) => {
  // console.log(actualizar);
  const response = await fetch(
    `${apiUrl('proveedor/gastos/justificar/')}${actualizar.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(actualizar),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener estadisticas rut proveedor
export const getEstadisticasGastosProveedor = async (rut, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/gastos/estadisticas/')}${rut}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// resumen de gasto mensual
export const getResumenGastoMensual = async (id, fecha, apiToken) => {
  const response = await fetch(
    `${apiUrl('proveedor/gastos/resumen/')}${id}/${fecha}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

/** ADMINISTRACION */

// obtener estadisticas
export const getEstadisticasGastos = async (apiToken) => {
  const response = await fetch(`${apiUrl('administracion/gastos/stats')}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  data.status = response.status;
  return data;
};

// obtener gastos adicionales del mes

export const getGastosAdicionales = async (apiToken, mes, ano) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/mes/')}${mes}/${ano}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
  // const response = await fetch(`${apiUrl('administracion/gastos')}`, {
  //   headers: {
  //     Authorization: `Bearer ${apiToken}`,
  //     'Content-Type': 'application/json',
  //   },
  // });
  // const data = await response.json();
  // data.status = response.status;
  // return data;
};

// obtener gastos pendientes preaprobacion
export const getGastosPendientesPreaprobacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/pendientes/1')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// obtener gastos pendientes justificacion
export const getGastosPendientesJustificacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/pendientes/2')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// obtener gastos pendientes aprobacion
export const getGastosPendientesAprobacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/pendientes/3')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// obetener gastos pendientes consolidacion
export const getGastosPendientesConsolidacion = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/pendientes/4')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener gastos anulados
export const getGastosAnulados = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/pendientes/6')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Obtener gastos anulados
export const getGastosConsolidados = async (apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/pendientes/5')}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Preaprobacion - Item monto preaprobado

export const preaprobacionItem = async (preaprobar) => {
  // console.log(preaprobar);
  const formData = new FormData();
  formData.append('item_id', preaprobar.item_id);
  formData.append('solicitud_id', preaprobar.solicitud_id);
  formData.append('monto_preaprobado', preaprobar.monto_preaprobado);
  formData.append('monto_total', preaprobar.monto_total);
  formData.append(
    'comentario_preaprobacion',
    preaprobar.comentario_preaprobacion
  );
  formData.append('usuario_id', preaprobar.usuario_id);

  const response = await fetch(
    `${apiUrl('administracion/gastos/itempreaprobado')}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${preaprobar.apiToken}`,
      },
      body: formData,
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Esitar monto justificado por administracion
export const editarMontoJustificado = async (editar) => {
  // console.log(editar);
  const formData = new FormData();
  formData.append('id', editar.id);
  formData.append('solicitud_id', editar.solicitud_id);
  formData.append('monto_justificado', editar.monto_justificado);
  formData.append('monto_total', editar.monto_total);
  formData.append('usuario_id', editar.usuario_id);

  const response = await fetch(
    `${apiUrl('administracion/gastos/editjustificado')}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${editar.apiToken}`,
      },
      body: formData,
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// preaprueba la solicitud
export const preaprobacionSolicitud = async (preaprobar, apiToken) => {
  // console.log(preaprobar);
  const response = await fetch(
    `${apiUrl('administracion/gastos/preaprobar/')}${preaprobar.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(preaprobar),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Aprobar Gasto Adicional
export const AprobarGastoAdicional = async (aprobar, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/aprobar/')}${aprobar.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(aprobar),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Consolidar Gasto Adicional
export const ConsolidarGastoAdicional = async (consolidar, apiToken) => {
  const response = await fetch(
    `${apiUrl('administracion/gastos/consolidar/')}${consolidar.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(consolidar),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Consolidar Gasto Adicional
export const RechazarGastoAdicional = async (rechazar, apiToken) => {
  console.log(rechazar);
  const response = await fetch(
    `${apiUrl('administracion/gastos/rechazar/')}${rechazar.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rechazar),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Anular Gasto Adicional
export const AnularGastoAdicional = async (anular, apiToken) => {
  // console.log(anular);
  const response = await fetch(
    `${apiUrl('administracion/gastos/anular/')}${anular.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(anular),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};

// Reactivar gasto adicional
export const ReactivarGastoAdicional = async (reactivar, apiToken) => {
  // console.log(reactivar);
  const response = await fetch(
    `${apiUrl('administracion/gastos/restaurar/')}${reactivar.id}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reactivar),
    }
  );
  const data = await response.json();
  data.status = response.status;
  return data;
};
