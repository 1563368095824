import React from 'react';

function CardProfileProgress(recurso) {
  const {
    comuna,
    region,
    proveedor,
    fecha_termino,
    cargo,
    fecha_baja,
    recontratable,
    comentario_desvinculacion,
    estado,
    updated_at,
    ...paramRecurso
  } = recurso.recurso;

  // porcentaje de avance
  const total = 14;
  const completado = Object.keys(paramRecurso).filter(
    (x) =>
      paramRecurso[x] !== null &&
      paramRecurso[x] !== '0000-00-00' &&
      paramRecurso[x] !== ''
  ).length;

  //   const porcentaje = (completado / total) * 100;
  const porcentaje = Math.round((completado / total) * 100);
  const colorBar = porcentaje < 80 ? 'danger' : 'success';

  return (
    <div className="card">
      <div className="card-header pb-0">
        <h4>Perfil del recurso</h4>
        <p>Progreso de cumplimiento de datos</p>
      </div>
      <div className="card-body">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td className="col-3">Información</td>
              <td className="col-6">
                {/* div className progress whith colorBar */}
                <div className={`progress progress-${colorBar}`}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${porcentaje}%` }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </td>
              <td className="col-3 text-center">{porcentaje}%</td>
            </tr>
            <tr>
              <td className="col-3">Documentos</td>
              <td className="col-6">
                <div className="progress progress-danger">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '0%' }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </td>
              <td className="col-3 text-center">0%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CardProfileProgress;
