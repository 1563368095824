import { useEffect } from 'react';

const ScriptExterno = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default ScriptExterno;

// Recibe como parametro la url del archivo js que se quiere cargar
// Ejemplo: useAplicacionScript('http://localhost:3000/assets/js/aplicacion.js');
// El archivo js debe estar en la carpeta assets/js
