import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { urlS3 } from '../../../config';
import useUser from '../../../hooks/useUser';
import { getRecursosByProveedor } from '../../../services/recursos';
import CustomTable from '../../general/CustomTable';

function TablaRecursosProveedor() {
  const [recursos, setRecursos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { dataUserLogged, apiToken } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRecursosByProveedor(dataUserLogged.rut, apiToken);
      setRecursos(data.recursos);
      setIsLoading(false);
    };
    fetchData();
  }, [dataUserLogged.id, apiToken, dataUserLogged.rut]);

  const columns = [
    {
      name: '',
      cell: (row) => (
        <div className="avatar avatar-lg">
          {row.foto ? (
            <img
              src={urlS3 + row.foto}
              alt={row.nombre.slice(0, 3).toUpperCase()}
            />
          ) : (
            <span className="avatar-content bg-secondary rounded-circle">
              {row.nombre.slice(0, 3).toUpperCase()}
            </span>
          )}
        </div>
      ),
      width: '7%',
    },
    {
      name: 'NOMBRE',
      selector: (row) => row.nombre,
      sortable: true,
      clave: 'nombre', // esta se usa en el componente exportar a excel
    },
    {
      name: 'APELLIDO',
      selector: (row) => row.apellido,
      sortable: true,
      clave: 'apellido',
    },
    {
      name: 'REGION',
      selector: (row) => row.region,
      sortable: true,
      clave: 'region',
    },
    {
      name: 'TELEFONO',
      selector: (row) => row.telefono,
      clave: 'telefono',
    },
    {
      name: 'CARGO',
      selector: (row) => row.cargo,
      sortable: true,
      clave: 'cargo',
    },
    {
      name: 'VER',
      cell: (row) => (
        <Link
          to={`/proveedor/recurso/${row.rut}`}
          className="btn btn-outline-primary"
        >
          <i className="bi bi-eye"></i>
        </Link>
      ),
      width: '7%',
      right: true,
      clave: 'ver',
    },
  ];

  const excelName = `Lista de recursos ${dataUserLogged.proveedor_nombre}`;

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title col-8">RECURSOS ACTIVOS</h4>
          </div>
          <div className="card-body">
            <CustomTable
              columns={columns}
              data={recursos}
              isLoading={isLoading}
              excelName={excelName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablaRecursosProveedor;
