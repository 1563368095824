import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { urlS3 } from '../../../config';
import CardUserLogged from '../../general/cardsInfo/CardUserLogged';
import {
  formatDate,
  formatDateTime,
  calcularEdad,
} from '../../../hooks/useDates';
import useUser from '../../../hooks/useUser';
import { getRecurso, deleteRecurso } from '../../../services/recursos';
import { AlertaSuccess } from '../../general/Alerts';
import CardProfileProgress from '../../general/cardsInfo/CardProfileProgress';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const estados = ['Inactivo', 'Activo']; // estado del recurso
const tipoContratos = ['', 'Indefinido', 'Temporal']; // tipo de contrato del recurso
const initialize = { comentario_desvinculacion: '' };

function DetalleRecurso() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { apiToken, dataUserLogged } = useUser();
  const { id } = useParams();

  const [recurso, setRecurso] = useState([]);
  const [validadesvinculacion, setValidadesvinculacion] = useState('');
  const [desvincular, setDesvincular] = useState(initialize);
  const [radioSeleccionado, setRadioSeleccionado] = useState(false);

  useEffect(() => {
    getRecurso(id, apiToken).then((data) => {
      setRecurso(data.recurso);
      setDesvincular({
        rut: data.recurso.rut,
        region: data.recurso.region_id,
        rut_proveedor: dataUserLogged.rut,
      });
      setIsLoading(false);
    });
  }, [apiToken, dataUserLogged.rut, id]);

  useEffect(() => {
    const validate = () => {
      // comprobar si validadesvinculacion es igual a desvincular radioSeleccionado es true
      if (validadesvinculacion === 'desvincular' && radioSeleccionado) {
        // if (validadesvinculacion === 'desvincular') {
        document
          .getElementById('btn-desvincular')
          .classList.remove('disabled', 'btn-outline-danger');
        document.getElementById('btn-desvincular').classList.add('btn-danger');
      } else {
        if (document.getElementById('btn-desvincular')) {
          document
            .getElementById('btn-desvincular')
            .classList.remove('btn-danger');
          document
            .getElementById('btn-desvincular')
            .classList.add('disabled', 'btn-outline-danger');
        }
      }
    };

    validate();
  }, [radioSeleccionado, validadesvinculacion]);

  const handleChange = useCallback(
    // si el input es un radio seleccionado actualizar el estado de radioSeleccionado a true
    (e) => {
      if (e.target.type === 'radio') {
        setRadioSeleccionado(true);
      }
      setDesvincular({
        ...desvincular,
        [e.target.name]: e.target.value,
      });
    },
    [desvincular]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      deleteRecurso(desvincular, apiToken).then((data) => {
        if (data.status === 200) {
          AlertaSuccess({
            title: 'Éxito',
            success: 'El recurso ha sido desvinculado correctamente',
          });
          setDesvincular(initialize);
          // volver atras
          navigate(-1);
        }
      });
    },
    [apiToken, desvincular, navigate]
  );

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        {isLoading ? <SpinerDownload /> : null}
        <div className="card">
          <div className="card-content">
            <div className="card-body">
              <h4 className="card-title text-uppercase">{`${recurso.nombre} ${recurso.apellido}`}</h4>
              <div className="d-flex flex-wrap">
                <div className="col-md-8 col-12 order-md-1 order-2">
                  <p className="card-text">
                    <span className="text-muted">Nombre: </span>
                    <span className="font-bold text-uppercase">
                      {recurso.nombre}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Apellido: </span>
                    <span className="font-bold text-uppercase">
                      {recurso.apellido}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Rut: </span>
                    <span className="font-bold text-uppercase">
                      {recurso.rut}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Fecha de Nacimiento: </span>
                    <span className="font-bold text-uppercase">
                      {`${formatDate(recurso.fecha_nacimiento)} ${calcularEdad(
                        recurso.fecha_nacimiento
                      )}`}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Dirección: </span>
                    <span className="font-bold text-uppercase">
                      {recurso.direccion}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Comuna: </span>
                    <span className="font-bold text-uppercase">
                      {recurso.comuna}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Región: </span>
                    <span className="font-bold text-uppercase">
                      {recurso.region}
                    </span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Teléfono: </span>
                    <span className="font-bold">{recurso.telefono}</span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Email: </span>
                    <span className="font-bold">{recurso.email}</span>
                  </p>
                  <p className="card-text">
                    <span className="text-muted">Estado: </span>
                    <span className="font-bold text-uppercase">
                      {estados[recurso.estado]}
                    </span>
                  </p>
                </div>

                <div className="col-md-4 col-12 order-md-2 order-1">
                  {recurso.foto && (
                    <img
                      src={`${urlS3}${recurso.foto}`}
                      alt={`Foto ${recurso.nombre}`}
                      max-height="350px"
                    />
                  )}
                </div>
              </div>
              {/* Divider */}
              <div className="divider divider-left">
                <div className="divider-text">Información laboral</div>
              </div>

              <div className="row">
                <p className="card-text">
                  <span className="text-muted">Proveedor: </span>
                  <span className="font-bold text-uppercase">
                    {recurso.proveedor}
                  </span>
                </p>
                <div className="row d-flex justify-content-between">
                  <p className="card-text col-md-4 col-12">
                    <span className="text-muted">Fecha de Ingreso: </span>
                    <span className="font-bold text-uppercase">
                      {formatDate(recurso.fecha_ingreso)}
                    </span>
                  </p>
                  <p className="card-text col-md-4 col-12">
                    <span className="text-muted">Tipo de Contrato: </span>
                    <span className="font-bold text-uppercase">
                      {tipoContratos[recurso.tipo_contrato]}
                    </span>
                  </p>
                  <p className="card-tex col-md-4 col-12">
                    <span className="text-muted">Fecha Termino Plazo: </span>
                    <span className="font-bold text-uppercase">
                      {formatDate(recurso.fecha_termino)}
                    </span>
                  </p>
                </div>

                <p className="card-text">
                  <span className="text-muted">Cargo: </span>
                  <span className="font-bold text-uppercase">
                    {recurso.cargo}
                  </span>
                </p>
              </div>

              {/* Divider */}
              <div className="divider divider-left">
                <div className="divider-text">Documentación</div>
              </div>

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Documentación del Recurso</h4>
                </div>
                <div className="card-content">
                  <div className="card-body pt-0">
                    <p>
                      Listado de documentos personales y laborales del recurso
                    </p>
                    <ul className="list-group">
                      <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center disab">
                        <div className="flex-column">
                          NO DISPONIBLE
                          <p>
                            <small>
                              Descripción - Momentaneamente no disponible
                            </small>
                          </p>
                        </div>
                        <div className="image-parent">
                          <span className="badge bg-secondary">
                            No disponible
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <small className="text-muted float-end">
                Ultima modificación{' '}
                {formatDateTime(recurso.updated_at) ||
                  formatDateTime(recurso.created_at)}
              </small>
            </div>
          </div>
        </div>
      </div>
      {/*  Tercera columna  */}
      <div className="col-12 col-lg-3">
        {/*  Card info usuario logueado  */}
        <CardUserLogged />
        {/* Progreso del perfil */}
        {!isLoading ? <CardProfileProgress recurso={recurso} /> : null}

        {/*  Card asistencia  */}
        <div className="card">
          <div className="card-header">
            <h4>Inasistencias</h4>
            <p>No disponible</p>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>
        {/*  Card reclamos  */}
        <div className="card">
          <div className="card-header">
            <h4>Reclamos</h4>
            <p>No disponible</p>
          </div>
          <div className="card-body">
            <div id="chart-visitors-profile"></div>
          </div>
        </div>

        {/*  Card edicion  */}
        {recurso.estado === '1' && dataUserLogged.rol === 'Proveedor' && (
          <div className="card">
            <div className="card-header">
              <h4>Control</h4>
              {/* boton editar */}
              <button
                type="button"
                className="btn btn-icon btn-outline-primary"
                onClick={() => {
                  navigate(`/proveedor/recurso/editar/${recurso.rut}`);
                }}
              >
                <i className="bi bi-pencil-square"></i> Editar información
              </button>
            </div>
            <div className="card-body">
              <div id="chart-visitors-profile"></div>
            </div>
          </div>
        )}

        {/*  Card Desvincular  */}
        {recurso.estado === '1' && dataUserLogged.rol === 'Proveedor' && (
          <div className="card">
            <div className="card-header">
              <h4>Desvincular Recurso</h4>
              <p>
                Para confirmar la desvinculación deberá seleccionar una de las
                siguientes opciones, adicionalmente puede escribir en el cuadro
                de texto, algo mas detallado.
              </p>
            </div>
            <div className="card-body">
              <form
                className="form"
                id="form-desvinculacion"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label htmlFor="motivo-desvinculacion">
                    Motivo Desvinculación
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="motivo_desvinculacion"
                      id="renuncia"
                      value="Renuncia voluntaria"
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="renuncia">
                      Renuncia voluntaria
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="motivo_desvinculacion"
                      id="termino-contrato"
                      value="Termino de contrato"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="termino-contrato"
                    >
                      Término de contrato
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="motivo_desvinculacion"
                      id="necesidad-empresa"
                      value="Desición de la empresa"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="necesidad-empresa"
                    >
                      Desición de la empresa
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="motivo_desvinculacion"
                      id="solicitud-mandante"
                      value="Solicitud del mandante"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="solicitud-mandante"
                    >
                      Solicitud del mandante
                    </label>
                  </div>
                  <textarea
                    className="form-control mt-3"
                    id="textarea-desvinculacion"
                    rows="3"
                    name="comentario_desvinculacion"
                    placeholder="Motivo detallado de la desvinculación"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Escriba desvincular"
                    aria-label="Recipient's username"
                    aria-describedby="btn-desvincular"
                    onChange={(e) => setValidadesvinculacion(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-danger disabled"
                    type="submit"
                    id="btn-desvincular"
                  >
                    Desvincular
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default DetalleRecurso;
