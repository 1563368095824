import React from 'react';
import useUser from '../../../hooks/useUser';

function CardUserLogged() {
  // llama al hook useUser para obtener el usuario logueado
  const { dataUserLogged, role } = useUser();

  return (
    <div className="card shadow">
      <div className="card-body py-4 px-4">
        <div className="d-flex align-items-center">
          <div className="avatar avatar-xl bg-secondary me-3">
            <span className="avatar-content">
              {dataUserLogged.name.slice(0, 3).toUpperCase()}
            </span>
            {/*  <img src="assets/images/faces/1.jpg" alt="Face 1" />  */}
          </div>
          <div className="ms-3 name">
            <h6 className="font-bold">{dataUserLogged.name}</h6>
            <p className="text-muted mb-0">
              {role + ' - ' + dataUserLogged.proveedor_nombre}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CardUserLogged;
