function DetalleGastoAdicional({ data, tickets, total }) {
  return !data ? null : (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">SERVICIO CON GASTO ADICIONAL</h5>
      </div>
      <div className="card-body position-relative">
        {/* Datos solicitud */}
        <div className="row">
          <p className="card-text">
            <strong>Fecha atención:</strong> {data.fecha_atencion}
          </p>
          <div className="col-8">
            <p className="card-text">
              <strong>Recurso:</strong> {data.recurso_nombre}{' '}
              {data.recurso_apellido}
            </p>
          </div>

          <div className="col-4 position-absolute top-0 end-0">
            <p className="card-text mb-1">
              <strong>Monto total:</strong>{' '}
            </p>
            <h3>${total}</h3>
          </div>

          <p className="card-text my-3">
            <strong>Observación:</strong> {data.observacion}
          </p>
        </div>

        {/* Divider */}
        <div className="divider divider-left mt-2">
          <div className="divider-text">TICKETS</div>
        </div>

        {/* Tickets */}
        {tickets.map((ticket, index) => (
          <div className="row border border-primary mb-3 px-3 pt-3" key={index}>
            <p className="card-text col-6">
              <strong>Proyecto:</strong> {ticket.proyecto}
            </p>

            <p className="card-text col-6">
              <strong>Ticket:</strong>{' '}
              {`${ticket.nomenclatura}${ticket.ticket}`}
            </p>

            <p className="card-text col-6">
              <strong>Dirección:</strong> {ticket.direccion}
            </p>

            <p className="card-text col-3">
              <strong>Comuna:</strong> {ticket.comuna}
            </p>

            <p className="card-text col-3">
              <strong>Región:</strong> {ticket.region}
            </p>

            <p className="card-text col-12">
              <strong>Comentario:</strong> {ticket.observacion}
            </p>

            {/* Divider */}
            <div className="divider divider-left mt-2">
              <div className="divider-text">GASTOS DEL TICKET</div>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col-2">Item</th>
                  <th className="col-1">Kms</th>
                  <th className="col-2">Monto solicitado</th>
                  <th className="col-4">Comentario solicitante</th>
                </tr>
              </thead>
              <tbody>
                {ticket.items_gastos
                  ? ticket.items_gastos.map((item, index) => (
                      <tr key={index}>
                        <td>{item.tipo}</td>
                        <td>{item.kilometros || 'NA'}</td>
                        <td>{item.monto_solicitado}</td>
                        <td>{item.comentario_solicitante}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DetalleGastoAdicional;
