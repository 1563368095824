import React from 'react';

function NuavaSolicitudGastos() {
  return (
    <section className="section">
      <div className="row">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan="1">Fecha</th>
              <th colSpan="3">Recurso</th>
              <th colSpan="4">Observacion Solicitud</th>
            </tr>
          </thead>
          <tbody>
            {/* Solicitud */}
            <tr>
              <td colSpan="1" rowSpan="3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Fecha</option>
                  <option value="1">One</option>
                  <option value="3">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
              <td colSpan="3" rowSpan="3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Recurso</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
              <td colSpan="4" rowSpan="3">
                <div>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr></tr>
            <tr></tr>
            {/* Ticket 1 */}
            <tr>
              <td colSpan="1" rowSpan="2">
                <button
                  type="button"
                  className="btn btn-primary btn-block btn-lg"
                >
                  Primary
                </button>
              </td>
              <td colSpan="1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Proyecto</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
              <td colSpan="2">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Ticket
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>
              </td>
              <td colSpan="3" rowSpan="2">
                Hola
              </td>
            </tr>
            <tr>
              <td colSpan="1">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Direccion
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>
              </td>
              <td colSpan="1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Proyecto</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
              <td colSpan="1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Comuna</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
            </tr>
            {/* Ticket 2 */}
            <tr>
              <td colSpan="1" rowSpan="2">
                <button
                  type="button"
                  className="btn btn-primary btn-block btn-lg"
                >
                  Primary
                </button>
              </td>
              <td colSpan="1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Proyecto</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
              <td colSpan="2">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Ticket
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>
              </td>
              <td colSpan="3" rowSpan="2">
                Hola
              </td>
            </tr>
            <tr>
              <td colSpan="1">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Direccion
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                  />
                </div>
              </td>
              <td colSpan="1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Region</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
              <td colSpan="1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Comuna</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <table className="table">
          <thead>
            <tr>
              <th>Firm</th>
              <th>Org</th>
              <th>Lob</th>
              <th>Sub Lob</th>
              <th>Business Plans</th>
              <th>Business Components</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td rowSpan="11">XYZ</td>
              <td rowSpan="5">CST</td>
              <td rowSpan="3">CT</td>
              <td>ADS</td>
              <td>1</td>
              <td>10</td>
            </tr>
            <tr>
              <td>BTO</td>
              <td>2</td>
              <td>15</td>
            </tr>
            <tr>
              <td>CSI</td>
              <td>3</td>
              <td>6</td>
            </tr>
            <tr>
              <td rowSpan="2">VCT</td>
              <td>ADS</td>
              <td>4</td>
              <td>10</td>
            </tr>
            <tr>
              <td>BTO</td>
              <td>5</td>
              <td>15</td>
            </tr>
            <tr className="subTotatlRow">
              <td colSpan="3" className="sub-total-text">
                Sub Total
              </td>
              <td>6</td>
              <td>15</td>
            </tr>
            <tr>
              <td rowSpan="4">CST</td>
              <td rowSpan="3">CT</td>
              <td>ADS</td>
              <td>7</td>
              <td>10</td>
            </tr>
            <tr>
              <td>BTO</td>
              <td>8</td>
              <td>15</td>
            </tr>
            <tr>
              <td>CSI</td>
              <td>9</td>
              <td>6</td>
            </tr>
            <tr>
              <td rowSpan="1">VCT</td>
              <td>ADS</td>
              <td>10</td>
              <td>10</td>
            </tr>
            <tr className="subTotatlRow">
              <td colSpan="3" className="sub-total-text">
                Sub Total
              </td>
              <td>11</td>
              <td>15</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </section>
  );
}

export default NuavaSolicitudGastos;
