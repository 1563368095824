import AgregarItemGasto from './AgregarItemGasto';
import { capitalize } from '../../../../hooks/useTexto';
import ComunasSelect from '../ComunasSelect';
import { generateKey } from '../../../../hooks/useKeys';
import ProyectosSelect from '../ProyectosSelect';
import RegionesSelect from '../RegionesSelect';
import { useCallback, useEffect, useState } from 'react';

function AgregarTicket({ idKey, onChange, index, tickets, onRemove, total }) {
  const [items, setItems] = useState([]);
  const [ticketInput, setTicketInput] = useState({
    type: 'number',
    placeholder: 'Numero de ticket',
  });
  const [nomenclatura, setNomenclatura] = useState('');

  const sumaTotal = useCallback(() => {
    const sum = tickets.reduce((acc, ticket) => {
      const gastos = ticket.items_gastos || items;
      const gastosTotal = gastos.reduce(
        (gastosAcc, item) => gastosAcc + parseInt(item.monto_solicitado || 0),
        0
      );
      return acc + gastosTotal;
    }, 0);

    return sum;
  }, [tickets, items]);

  useEffect(() => {
    total(sumaTotal());
  }, [items, sumaTotal, total]);

  const updateTicket = (updatedTicket) => {
    const newTickets = [...tickets];
    newTickets[index] = updatedTicket;
    onChange(newTickets);
  };

  const handleChangeTicket = (e) => {
    const name = e.target.name;
    const value =
      name === 'direccion' ? capitalize(e.target.value) : e.target.value;
    updateTicket({ ...tickets[index], [name]: value });
  };

  const seleccionProyecto = (proyecto) => {
    setNomenclatura(proyecto.nomenclatura);
    setTicketInput({
      type: proyecto.nomenclatura === 'OTS' ? 'text' : 'number',
      placeholder:
        proyecto.nomenclatura === 'OTS'
          ? 'Actividad (breve)ej: Retiro de equipos'
          : 'Numero de ticket',
    });
    updateTicket({
      ...tickets[index],
      proyecto: proyecto.proyecto,
      nomenclatura: proyecto.nomenclatura,
      proyecto_id: proyecto.id,
    });
  };

  const seleccionComuna = (comuna) => {
    updateTicket({
      ...tickets[index],
      comuna: comuna.comuna,
      comuna_id: comuna.id,
    });
  };

  const seleccionRegion = (region) => {
    updateTicket({
      ...tickets[index],
      region: region.region,
      region_id: region.id,
    });
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        key: generateKey(),
        item_id: '',
        monto_solicitado: '',
        comentario_solicitante: '',
      },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    total(sumaTotal());
    updateTicket({ ...tickets[index], items_gastos: items });
  };

  const handleDeleteItem = (itemIndex) => {
    const newItems = items.filter((_, i) => i !== itemIndex);
    setItems(newItems);
    total(sumaTotal());
  };

  return (
    <>
      <form
        key={idKey}
        id={`form_agregar_ticket_${idKey}`}
        className="form mb-4"
        data-parsley-validate
        onSubmit={handleSubmit}
      >
        <div className="row">
          {/* Proyecto */}
          <div className="col-md-5 col-12">
            <ProyectosSelect onSelect={seleccionProyecto} selDefault={''} />
          </div>

          {/* Ticket */}
          <div className="col-md-7 col-12 mt-4">
            <div className="input-group mt-2">
              <span className="input-group-text col-2" id="nomenclatura">
                {nomenclatura}
              </span>

              <input
                type={ticketInput.type}
                className="form-control"
                placeholder={ticketInput.placeholder}
                name="ticket"
                onChange={handleChangeTicket}
                required
              />
            </div>
          </div>

          {/* Direccion */}
          <div className="col-md-4 col-12">
            <div className="form-group mandatory">
              <label htmlFor="direccion" className="form-label">
                Dirección
              </label>
              <input
                type="text"
                className="form-control"
                name="direccion"
                onChange={handleChangeTicket}
              />
            </div>
          </div>

          {/* Comuna */}
          <div className="col-md-4 col-12">
            <ComunasSelect seleccion={seleccionComuna} selDefault={''} />
          </div>

          {/* Region */}
          <div className="col-md-4 col-12">
            <RegionesSelect seleccion={seleccionRegion} selDefault={''} />
          </div>

          {/* Observacion Ticket */}
          <div className="col-md-12 col-12">
            <div className="form-group">
              <label htmlFor="observacion_t" className="form-label">
                Comentario al ticket
              </label>
              <textarea
                className="form-control"
                id="observacion_t"
                name="observacion"
                rows="2"
                onChange={handleChangeTicket}
              ></textarea>
            </div>
          </div>
        </div>
      </form>
      {/* Agregar Item Gasto */}
      {items.map((item, i) => (
        <div className="card border border-secondary bg-gray" key={item.key}>
          <AgregarItemGasto
            onChange={setItems}
            items={items}
            index={i}
            deleteItem={() => handleDeleteItem(i)}
          />
        </div>
      ))}
      {/* Botones */}
      <div className="col-12 d-flex justify-content-between">
        {/* delete ticket */}
        <button
          type="button"
          className="btn btn-danger me-1 mb-1"
          onClick={onRemove}
        >
          Eliminar Ticket
        </button>

        <div className="d-flex">
          {/* Boton submit ticket */}
          <button
            type="submit"
            className="btn btn-success me-2 mb-1 ml-auto"
            form={`form_agregar_ticket_${idKey}`}
          >
            Confirmar Ticket
          </button>

          {/* Boton Agregar Item */}
          <button
            type="button"
            className="btn btn-primary me-2 mb-1"
            onClick={handleAddItem}
          >
            Agregar Item Gasto
          </button>
        </div>
      </div>
    </>
  );
}

export default AgregarTicket;
