import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  getDocumentosProveedores,
  createDocumentoProveedor,
  deleteDocumentoProveedor,
} from '../../../services/docsProveedor';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const initialstate = {
  id: '',
  documento: '',
  descripcion: '',
  sigla_documento: '',
  expirable: 0,
  duracion: 0,
};

function DocumentacionProveedor() {
  const { apiToken } = useUser();
  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState({ ...initialstate });
  const [isLoading, setIsLoading] = useState(false);

  // get data
  const getData = (apiToken) => {
    setIsLoading(true);
    getDocumentosProveedores(apiToken).then((data) => {
      setDocumentos(data.documentos || data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  // handleChange
  const handleChange = (e) => {
    setDocumento({
      ...documento,
      [e.target.name]: e.target.value,
    });
  };

  // documento edit
  const handleDocumentoClicked = (documento) => {
    setDocumento(documento);
  };

  // delete documento
  const handleDeleteDocumento = (documento) => {
    AlertaConfirm({
      title: '¿Eliminar documento?',
      text: `Se eliminara ${documento.documento}`,
      callback: () => {
        deleteDocumentoProveedor(documento.id, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({ message: 'Documento eliminado correctamente' });
            getData(apiToken);
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      documento.documento === '' ||
      documento.sigla_documento === '' ||
      documento.expirable === ''
    ) {
      AlertaError({ error: 'Debe ingresar todos los campos requeridos.' });
      return;
    }

    AlertaConfirm({
      title: 'Confirmar',
      text: '¿Está seguro de guardar el documento?',
      callback: () => {
        createDocumentoProveedor(documento, apiToken).then((data) => {
          if (data.status === 200) {
            if (documento.id === '') {
              setDocumentos([...documentos, data.documentos]);
            } else {
              const documentosUpdated = documentos.map((documento) => {
                if (documento.id === data.documentos.id) {
                  return data.documentos;
                }
                return documento;
              });
              setDocumentos(documentosUpdated);
            }
            setDocumento({ ...initialstate });
            document.getElementById('form-create-documento').reset();
            AlertaSuccess({ text: 'Documento guardado correctamente.' });
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>Documentación de Proveedores</h3>
          {/* table */}
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Documento</th>
                <th>Descripcion</th>
                <th>Sigla doc.</th>
                <th>Expirable</th>
                <th>Duracion</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6">
                    Obteniendo Información...
                    <SpinerDownload />
                  </td>
                </tr>
              ) : documentos['message'] ? (
                <tr>
                  <td colSpan="6">{documentos['message']}</td>
                </tr>
              ) : (
                documentos.map((documento) => (
                  <tr key={documento.id}>
                    <td>{documento.documento}</td>
                    <td>{documento.descripcion}</td>
                    <td>{documento.sigla_documento}</td>
                    <td>{documento.expirable}</td>
                    <td>{documento.duracion}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleDocumentoClicked(documento)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteDocumento(documento)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Nuevo Documento</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                id="form-create-documento"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  {/* Docuemento */}
                  <div className="col-md-8 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-documento" className="form-label">
                        Documento
                      </label>
                      <input
                        type="text"
                        id="input-nombre"
                        className="form-control"
                        placeholder="documento"
                        name="documento"
                        value={documento.documento}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Sigla */}
                  <div className="col-md-4 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-sigla" className="form-label">
                        Sigla
                      </label>
                      <input
                        type="text"
                        id="input-sigla"
                        className="form-control"
                        name="sigla_documento"
                        placeholder="Sigla"
                        value={documento.sigla_documento}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Descripcion */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="input-descripcion" className="form-label">
                        Descripción
                      </label>
                      <input
                        type="text"
                        id="input-descripcion"
                        className="form-control"
                        name="descripcion"
                        placeholder="Descripcion"
                        value={documento.descripcion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Expirable */}
                  <div className="col-md-6 col-12">
                    <div className="form-group mandatory">
                      <label htmlFor="input-expirable" className="form-label">
                        Expirable
                      </label>
                      <select
                        className="form-control"
                        id="input-expirable"
                        name="expirable"
                        value={documento.expirable}
                        onChange={handleChange}
                      >
                        <option>Selccionar</option>
                        <option value="1">Si</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>

                  {/* Duracion */}
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="input-duracion" className="form-label">
                        Duración <small>(en meses)</small>
                      </label>
                      <input
                        type="number"
                        id="input-duracion"
                        className="form-control"
                        name="duracion"
                        placeholder="Duracion"
                        value={documento.duracion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                      onClick={() => setDocumento({ ...initialstate })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="alert alert-info">
        <h4 className="alert-heading">Importante</h4>
        <p>
          Los documentos del listado seran solicitados a todos los proveedores
          registrados en el sistema, debiendo cada uno cumplir con la carga de
          los archivos necesarios.
        </p>
      </div>
    </section>
  );
}

export default DocumentacionProveedor;
