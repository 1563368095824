import { Link } from 'react-router-dom';

function RecuperarPassword() {
  return (
    <>
      <h3 className="auth-title">Recuperar Password</h3>
      <p className="auth-subtitle mb-5">
        Ingrese el correo registrado en la aplicacion para obtener un enlace de
        reinicio de password.
      </p>

      <form action="index.html">
        <div className="form-group position-relative has-icon-left mb-4">
          <input
            type="email"
            className="form-control form-control-xl"
            placeholder="Email"
          />
          <div className="form-control-icon">
            <i className="bi bi-envelope"></i>
          </div>
        </div>
        <button className="btn btn-primary btn-block btn-lg shadow-lg mt-5">
          Enviar
        </button>
      </form>
      <div className="text-center mt-5 text-lg fs-4">
        <p className="text-gray-600">
          Recordo su password?
          <Link to="/administracion" className="font-bold">
            Ingrese
          </Link>
        </p>
      </div>
    </>
  );
}

export default RecuperarPassword;
