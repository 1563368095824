import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useUser from '../../../hooks/useUser';
import { getTiposGastos } from '../../../services/tiposGastos.js';

function TiposGastosSelect({ opcionDefault, onSelect }) {
  const { apiToken } = useUser();
  const [tiposGastos, setTiposGastos] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    opcionDefault ? opcionDefault : ''
  );

  // Obtiene los tipos de gastos
  useEffect(() => {
    getTiposGastos(apiToken).then((data) => {
      setTiposGastos(data.tipos_gastos);
    });
  }, [apiToken]);

  return (
    <>
      <div className="form-group mandatory">
        <label htmlFor="input-tipoGasto" className="form-label">
          Concepto
        </label>
        <select
          value={selectedOption}
          className="form-select"
          id="basicSelect"
          name="tipoGasto"
          onChange={(e) => {
            setSelectedOption(e.target.value);
            if (onSelect)
              onSelect(
                tiposGastos.find((tipoGasto) => tipoGasto.id === e.target.value)
              );
          }}
        >
          <option value={''} disabled>
            Seleccionar Tipo de Gasto
          </option>
          {tiposGastos.map((tipoGasto) => (
            <option key={tipoGasto.id} value={tipoGasto.id}>
              {tipoGasto.tipo}
            </option>
          ))}
        </select>
        {/* link to open modal */}
        <Link
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#modalTpoGasto"
          className="text-decoration-none"
        >
          <i className="bi bi-info-circle"></i> Tipos de gastos
        </Link>
      </div>
      {/* Modal */}
      <div
        className="modal fade text-left"
        id="modalTpoGasto"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalTpoGastoLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalTpoGastoLabel1">
                Detalle de TpoGastos
              </h5>
              <button
                type="button"
                className="close rounded-pill"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>TpoGasto</th>
                    <th>Descripcion</th>
                  </tr>
                </thead>
                <tbody>
                  {tiposGastos.map((tipoGasto) => (
                    <tr key={tipoGasto.id}>
                      <td>{tipoGasto.tipo}</td>
                      <td>{tipoGasto.descripcion}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">
                <i className="bx bx-x d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Cerrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TiposGastosSelect;
