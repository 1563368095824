import React, { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import { getRepresentanteLegal } from '../../../services/proveedores';

function RepresentanteCard({ id }) {
  const { apiToken } = useUser();
  const [representante, setRepresentante] = useState([]);

  useEffect(() => {
    getRepresentanteLegal(id, apiToken).then((data) => {
      setRepresentante(data.representante || data.message);
    });
  }, [id, apiToken]);

  return (
    <div className="card">
      <div className="card-header">
        <h4>Representante</h4>
      </div>
      <div className="card-content pb-4">
        <div className="recent-message d-flex px-4 py-3">
          <div className="name ms-4">
            <h6 className="mb-1">
              {representante.nombre
                ? `${representante.nombre} ${representante.apellido}`
                : representante}
            </h6>
            <p>
              <span className="text-muted">Rut:</span>{' '}
              {representante.rut || 'No informado'}
            </p>
            <p>
              <span className="text-muted">Direccion: </span>
              {representante.direccion || 'No informada'}
            </p>
            <p>
              <span className="text-muted">Comuna: </span>
              {representante.comuna || 'No informada'}
            </p>
            <p>
              <span className="text-muted">Region: </span>
              {representante.region || 'No informada'}
            </p>
            <p>
              <span className="text-muted">Telefono: </span>
              {representante.telefono || 'No informado'}
            </p>
            <p>
              <span className="text-muted">Email: </span>{' '}
              {representante.email || 'No informado'}
            </p>
          </div>
        </div>
        {!representante.nombre ? (
          <div className="recent-message d-flex px-4 py-3">
            <h6>Agregar Representante</h6>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RepresentanteCard;
