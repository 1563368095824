import { Link } from 'react-router-dom';

function CardUltimosOvtGeneradosP({ data, perfil }) {
  return (
    <div className="card">
      <div className="card-body px-1">
        <h6 className='text-center'>ÚLTIMOS OVT GENERADOS</h6>
        <div className="list-group list-group-flush">
          {data.map((ovt) => (
            <Link 
              key={ovt.id} 
              to={`/${perfil}/overtime/${ovt.id}`} 
              className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
            >
              <div className="name col-8">
                <h6 className="mb-1">N° {ovt.correlativo}</h6>
                <small className="text-muted mb-0">{`${ovt.nombre} ${ovt.apellido}`}</small>
                <p className="text-primary mb-0">Solicita: {ovt.solicitante}</p>
                <small className="text-muted">{ovt.created_at} hrs</small>
              </div>
              <div className="col-4 ms-3">
                <h6 className="text-success mb-0">
                  {ovt.horas_solicitadas.slice(0, -3)} hrs
                </h6>
                <small className="text-info">{ovt.estado}</small>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardUltimosOvtGeneradosP;
