import { useEffect, useState } from 'react';
import useUser from '../../../hooks/useUser';
import {
  getProyectos,
  createProyecto,
  deleteProyecto,
} from '../../../services/proyectos';
import {
  AlertaConfirm,
  AlertaError,
  AlertaSuccess,
} from '../../general/Alerts';
import { SpinerDownload } from '../../general/loader/SpinerLoader';

const initialState = {
  id: '',
  proyecto: '',
  nomenclatura: '',
  descripcion: '',
};

function Proyectos() {
  const { apiToken } = useUser();
  const [proyectos, setProyectos] = useState([]);
  const [proyecto, setProyecto] = useState({ ...initialState });
  const [isLoading, setIsLoading] = useState(false);

  // get data
  const getData = (apiToken) => {
    setIsLoading(true);
    getProyectos(apiToken).then((data) => {
      setProyectos(data.proyectos);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData(apiToken);
  }, [apiToken]);

  // handleChange
  const handleChange = (e) => {
    setProyecto({
      ...proyecto,
      [e.target.name]: e.target.value,
    });
  };

  // proyecto edit
  const handleProyectoClicked = (proyecto) => {
    setProyecto(proyecto);
  };

  // delete proyecto
  const handleDeleteProyecto = (proyecto) => {
    AlertaConfirm({
      title: '¿Eliminar proyecto?',
      text: `Se eliminara ${proyecto.proyecto}`,
      callback: () => {
        deleteProyecto(proyecto.id, apiToken).then((data) => {
          if (data.status === 200) {
            AlertaSuccess({ message: 'Proyecto eliminada correctamente' });
            getData(apiToken);
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  // submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (proyecto.proyecto === '') {
      AlertaError({ error: 'Debe ingresar un proyecto' });
      return;
    }
    AlertaConfirm({
      title: '¿Crear proyecto?',
      text: `Se creara ${proyecto.proyecto}`,
      callback: () => {
        createProyecto(proyecto, apiToken).then((data) => {
          if (data.status === 200) {
            if (proyecto.id === '') {
              setProyectos([...proyectos, data.proyecto]);
            } else {
              const proyectosUpdated = proyectos.map((proyecto) => {
                if (proyecto.id === data.proyecto.id) {
                  return data.proyecto;
                }
                return proyecto;
              });
              setProyectos(proyectosUpdated);
            }
            setProyecto({ ...initialState });
            document.getElementById('form-create-proyecto').reset();
            AlertaSuccess({ message: 'Proyecto creada correctamente' });
          } else {
            AlertaError({ error: data.error });
          }
        });
      },
    });
  };

  return (
    <section className="section">
      <div className="row">
        <div className="col-sm-12 col-lg-8">
          <h3>Proyectos Activos</h3>
          {/* table */}
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th className="col-2">Proyecto</th>
                <th className="col-1">Nomen.</th>
                <th>Descripción</th>
                <th className="col-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="3">
                    Obteniendo Información...
                    <SpinerDownload />
                  </td>
                </tr>
              ) : (
                proyectos.map((proyecto) => (
                  <tr key={proyecto.id}>
                    <td>{proyecto.proyecto}</td>
                    <td>{proyecto.nomenclatura}</td>
                    <td>{proyecto.descripcion}</td>
                    <td>
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          className="btn btn-primary btn-sm mr-2"
                          onClick={() => handleProyectoClicked(proyecto)}
                        >
                          Editar
                        </button>

                        <button
                          className="btn btn-danger btn-sm ms-1"
                          onClick={() => handleDeleteProyecto(proyecto)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Agregar Nuevo Proyecto</h4>
            </div>
            <div className="card-body">
              {/* Formulario */}
              <form
                className="form"
                data-parsley-validate
                id="form-create-proyecto"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  {/* Proyecto */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="proyecto">Proyecto</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Proyecto"
                        name="proyecto"
                        value={proyecto.proyecto}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Nomenclatura */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="nomenclatura">Nomenclatura</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nomenclatura"
                        name="nomenclatura"
                        value={proyecto.nomenclatura}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* Descripcion */}
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="descripcion">Descripción</label>
                      <textarea
                        className="form-control"
                        placeholder="Descripción"
                        name="descripcion"
                        value={proyecto.descripcion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary me-1 mb-1">
                      Agregar
                    </button>
                    <button
                      type="reset"
                      className="btn btn-light-secondary me-1 mb-1"
                      onClick={() => setProyecto({ ...initialState })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Proyectos;
