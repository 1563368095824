import React, { useState, useEffect } from 'react';
import useUser from '../../../../hooks/useUser';

function DocumentosGastosProveedor() {
  const { dataUserLogged } = useUser();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [availableMonths, setAvailableMonths] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Enero es 0
    const yearsArray = [2023]; // Añade el primer año registrado
    for (let year = 2024; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    if (currentMonth === 12) {
      // Solo añadir el próximo año si estamos en diciembre
      yearsArray.push(currentYear + 1);
    }
    setYears(yearsArray);
    updateAvailableMonths(selectedYear);
  }, [selectedYear]);

  const updateAvailableMonths = (year) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Enero es 0
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    let startMonth = 1; // Enero por defecto
    if (year === 2023) startMonth = 4; // Comienza en Abril para 2023
    if (year > currentYear) startMonth = 1; // Futuros años empiezan en Enero
    const endMonth = year === currentYear ? currentMonth : 12; // Ajusta el mes final al mes actual si es el año corriente

    setAvailableMonths(months.slice(startMonth - 1, endMonth));
  };

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
    updateAvailableMonths(year);
  };

  const handleDownload = (rut, mes) => {
    const downloadUrl = `https://mappstorage.s3.amazonaws.com/gepro/gasto_adicional/informes/${rut}/${selectedYear}/GASTOS ADICIONALES DE ${mes.toUpperCase()} ${selectedYear}.xlsx`;

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute(
      'download',
      `Gastos Adicionales de ${mes} ${selectedYear}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="row">
      <div className="col-12 col-lg-9">
        <div className="card">
          <div className="card-header">
            <h4>GASTOS ADICIONALES - INFORMES</h4>
          </div>
          <div className="card-body">
            <div className="d-flex flex-wrap">
              {availableMonths.map((mes, index) => (
                <div key={index} className="card border border-info col-2 mx-1">
                  <div className="card-header bg-transparent p-0 m-auto">
                    <div className="d-flex px-4 py-3">
                      <div className="rounded-circle rounded-5 py-0 px-2 bg-light">
                        <i
                          className="bi bi-file-earmark-spreadsheet"
                          style={{ fontSize: 35 }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0 m-0 border-top border-bottom border-info text-center">
                    <h5>{mes}</h5>
                    <small className="text-muted">
                      Actualizado recientemente
                    </small>
                  </div>
                  <div className="card-footer p-0 m-0 text-center text-white bg-primary">
                    <button
                      className="text-white border-0 bg-transparent"
                      onClick={() => handleDownload(dataUserLogged.rut, mes)}
                    >
                      Descargar
                      <i className="bi bi-download ms-2"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <div className="card">
          <div className="card-header">
            <h4>Mostrar año</h4>
          </div>
          <div className="card-body">
            <div className="d-flex">
              <div className="form-floating me-2 col-8">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Seleccionar año</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DocumentosGastosProveedor;
