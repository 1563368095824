import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import ComunasSelect from './ComunasSelect';
import RegionesSelect from './RegionesSelect';
import { CargosSelect } from './CargosSelect';
import { getRecurso, updateRecurso } from '../../../services/recursos';
import {
  AlertaError,
  AlertaSuccess,
  AlertaConfirm,
} from '../../general/Alerts';
import useUser from '../../../hooks/useUser';
import Resizer from 'react-image-file-resizer';
import { SpinerLoad } from '../../general/loader/SpinerLoader';
import { useNavigate, useParams } from 'react-router-dom';

const initialState = {
  rut: '',
  nombre: '',
  apellido: '',
  fecha_nacimiento: '',
  direccion: '',
  comuna: '',
  region: '',
  telefono: '',
  email: '',
  rut_proveedor: '',
  fecha_ingreso: '',
  tipo_contrato: '',
  fecha_termino: '',
  cargo: '',
  foto: '',
};

// Tipos de contrato
const CONTRATO_PLAZO = '2';
const CONTRATO_INDEFINIDO = '1';

// file resizer
const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });
};

function EditarRecurso() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { role, dataUserLogged, apiToken } = useUser();
  const [data, setData] = useState({ ...initialState });
  const [isloading, setIsLoading] = useState(false);

  // select Region
  function seleccionRegion(region) {
    setData({ ...data, region: region.id });
  }

  // select Comuna
  function seleccionComuna(comuna) {
    setData({ ...data, comuna: comuna.id });
  }

  // select Cargo
  function seleccionCargo(valor) {
    setData({ ...data, cargo: valor });
  }

  //   load recurso
  useEffect(() => {
    getRecurso(id, apiToken).then((data) => {
      // setData(data.recurso);
      setData({
        rut: data.recurso.rut,
        nombre: data.recurso.nombre,
        apellido: data.recurso.apellido,
        fecha_nacimiento: data.recurso.fecha_nacimiento || null,
        direccion: data.recurso.direccion || null,
        comuna: data.recurso.comuna_id || null,
        region: data.recurso.region_id,
        telefono: data.recurso.telefono || null,
        email: data.recurso.email || null,
        rut_proveedor: data.recurso.rut_proveedor,
        fecha_ingreso: data.recurso.fecha_ingreso,
        tipo_contrato: data.recurso.tipo_contrato,
        fecha_termino: data.recurso.fecha_termino || null,
        cargo: data.recurso.cargo_id,
        foto: data.recurso.foto || null,
      });
    });
  }, [id, apiToken]);

  // Evento Change
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      rutProveedor: dataUserLogged.rut,
    });
  };

  // File change
  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];
      resizeFile(file).then((uri) => {
        setData({
          ...data,
          foto: uri,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Envio del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      data.rut !== '' &&
      data.nombre !== '' &&
      data.apellido &&
      data.region !== '' &&
      data.rutProveedor !== '' &&
      data.fechaIngreso !== '' &&
      data.tipoDeContrato !== ''
    ) {
      AlertaConfirm({
        title: 'Editar recurso?',
        text: `Se editara el recurso ${data.nombre} ${data.apellido}`,
        callback: () => {
          setIsLoading(true);
          updateRecurso(data, apiToken)
            .then((response) => {
              if (response.status === 200) {
                setIsLoading(false);
                setData({ ...initialState });
                // reset form
                document.getElementById('form-create-recurso').reset();
                AlertaSuccess({
                  success: `El recurso ${data.nombre} ${data.apellido} ha sido editado con éxito`,
                  callback: () => {
                    navigate(-1);
                  },
                });
              } else {
                console.log(response.message);
                setIsLoading(false);
                AlertaError({
                  title: response.message,
                  error: response.errors,
                });
              }
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(error);
              AlertaError({
                error: 'Ha ocurrido un error al editar el recurso',
              });
            });
        },
      });
    } else {
      AlertaError({
        error: 'Debe completar todos los campos',
      });
    }
  };

  return (
    <>
      <section className="section">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Edición de Recurso</h4>
          </div>
          <div className="card-body">
            <form
              className="form"
              data-parsley-validate
              onSubmit={handleSubmit}
              id="form-create-recurso"
            >
              <div className="row">
                {/* RUT */}
                <div className="col-md-2 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="input-rut" className="form-label">
                      RUT <small>sin puntos, con guión</small>
                    </label>
                    <InputMask
                      className="form-control"
                      mask={'99999999-*'}
                      id="input-rut"
                      name="rut"
                      value={data.rut}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </div>
                {/* Nombres */}
                <div className="col-md-4 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="input-nombre" className="form-label">
                      Nombres
                    </label>
                    <input
                      type="text"
                      id="input-nombre"
                      className="form-control"
                      placeholder="Nombre"
                      name="nombre"
                      value={data.nombre}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Apellidos */}
                <div className="col-md-4 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="input-apellidos" className="form-label">
                      Apellidos
                    </label>
                    <input
                      type="text"
                      id="input-apellidos"
                      className="form-control"
                      placeholder="Apellidos"
                      name="apellido"
                      value={data.apellido}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Fecha Nacimiento */}
                <div className="col-md-2 col-12">
                  <div className="form-group">
                    <label
                      htmlFor="input-fechaNacimiento"
                      className="form-label"
                    >
                      Fecha Nacimiento
                    </label>
                    <input
                      type="date"
                      id="input-fechaNacimiento"
                      className="form-control"
                      name="fecha_nacimiento"
                      onChange={handleChange}
                      placeholder="Giro"
                      value={data.fecha_nacimiento}
                    />
                  </div>
                </div>
                {/* Direccion */}
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label htmlFor="input-direccion" className="form-label">
                      Dirección
                    </label>
                    <input
                      type="text"
                      id="input-direccion"
                      className="form-control"
                      name="direccion"
                      onChange={handleChange}
                      placeholder="Direccion"
                      value={data.direccion}
                    />
                  </div>
                </div>
                {/* Aca van los select region comuna */}
                <div className="col-md-3 col-12">
                  <RegionesSelect
                    seleccion={seleccionRegion}
                    selDefault={data.region}
                  />
                </div>
                <div className="col-md-3 col-12">
                  <ComunasSelect
                    seleccion={seleccionComuna}
                    selDefault={data.comuna}
                  />
                </div>

                {/* Telefono */}
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="input-telefono" className="form-label">
                      Teléfono
                    </label>
                    <InputMask
                      className="form-control"
                      id="input-telefono"
                      name="telefono"
                      value={data.telefono}
                      onChange={handleChange}
                      mask={'+56 9 9999 9999'}
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="input-email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="input-email"
                      className="form-control"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                  </div>
                </div>
                {/* Foto */}
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="input-foto" className="form-label">
                      Foto
                    </label>
                    <input
                      type="file"
                      id="input-foto"
                      className="form-control"
                      name="foto"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                {/* Informacion Laboral */}
                <div className="divider divider-left">
                  <div className="divider-text">Información Laboral</div>
                </div>
                {/* Rut Proveedor */}
                <div className="col-md-2 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="input-rut-proveedor" className="form-label">
                      RUT Proveedor
                    </label>
                    <InputMask
                      className="form-control"
                      mask={'99999999-*'}
                      id="input-rut-proveedor"
                      onChange={handleChange}
                      defaultValue={dataUserLogged.rut}
                      name="rut_proveedor"
                      disabled={role !== 'Administracion' ? true : false}
                    />
                  </div>
                </div>
                {/* Fecha Ingreso */}
                <div className="col-md-2 col-12">
                  <div className="form-group mandatory">
                    <label htmlFor="input-ingreso" className="form-label">
                      Fecha Ingreso
                    </label>
                    <input
                      type="date"
                      id="input-ingreso"
                      className="form-control"
                      name="fecha_ingreso"
                      value={data.fecha_ingreso}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Tipo de Contrato */}
                <div className=" form-group mandatory col-md-4 col-12">
                  <label className="form-label d-block">
                    Tipo de Contrato / Termino Plazo
                  </label>
                  <div className="row">
                    <div className="col-md-3 col-12">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipo_contrato"
                          id="tipoContrato2"
                          value={CONTRATO_INDEFINIDO}
                          onChange={handleChange}
                          checked={data.tipo_contrato === CONTRATO_INDEFINIDO}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tipoContrato2"
                        >
                          Indefinido
                        </label>
                      </div>
                    </div>

                    <div className="col-md-3 col-12">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="tipo_contrato"
                          id="tipoContrato1"
                          value={CONTRATO_PLAZO}
                          onChange={handleChange}
                          checked={data.tipo_contrato === CONTRATO_PLAZO}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="tipoContrato1"
                        >
                          Plazo
                        </label>
                      </div>
                    </div>

                    <div className="col-6">
                      <input
                        type="date"
                        className="form-control"
                        id="fechaPlazo"
                        name="fecha_termino"
                        value={data.fecha_termino}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {/* Select Cargos */}
                <CargosSelect
                  seleccion={seleccionCargo}
                  selDefault={data.cargo}
                />

                <div className="col-12 d-flex justify-content-end">
                  {isloading ? <SpinerLoad /> : null}
                  <button type="submit" className="btn btn-primary me-1 mb-1">
                    Agregar
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light-secondary me-1 mb-1"
                  >
                    Borrar Form
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="section mt-5 mb-5">
        <div className="accordion accordion-flush" id="acordionReload">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i
                  className="bi bi-exclamation-circle-fill text-warning mx-4 pb-5"
                  style={{ fontSize: '2rem' }}
                ></i>
                <h6 className="text-secondary">
                  ¿Sigo viendo la misma foto despues de actualizarla?
                </h6>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#acordionReload"
            >
              <div className="accordion-body text-secondary">
                <p>
                  Esto es debido a que el navegador guarda una copia de la
                  imagen en su memoria cache. Para forzar al navegador a traer
                  la nueva imagen desde el servidor haga lo siguiente:
                </p>

                <div className="row mt-2 mb-4 p-3 shadow">
                  <span>
                    <p className="mb-2">
                      Google Chrome <i className="bi bi-browser-chrome"></i>
                    </p>
                    <p className="mb-2">
                      Windows <i className="bi bi-windows"></i>:{' '}
                      <kbd className="bg-primary">Ctrl</kbd> +{' '}
                      <kbd className="bg-primary">F5</kbd> o tambien puede
                      presionar <kbd className="bg-primary">Ctrl</kbd> y hacer
                      click en <i className="bi bi-arrow-clockwise"></i> (Botón
                      recargar)
                    </p>
                    <p>
                      Mac <i className="bi bi-apple"></i>:{' '}
                      <kbd className="bg-primary">Cmd</kbd> +{' '}
                      <kbd className="bg-primary">Shift</kbd> +{' '}
                      <kbd className="bg-primary">R</kbd> o tambien puede
                      presionar <kbd className="bg-primary">Cmd</kbd> y hacer
                      click en <i className="bi bi-arrow-clockwise"></i> (Botón
                      recargar)
                    </p>
                  </span>
                </div>
                <div className="row mb-4 p-3 shadow">
                  <span>
                    <p className="mb-2">
                      Mozilla Firefox <i className="bi bi-browser-firefox"></i>
                    </p>
                    <p className="mb-2">
                      Windows <i className="bi bi-windows"></i>:{' '}
                      <kbd className="bg-primary">Ctrl</kbd>{' '}
                      <kbd className="bg-primary">Shift</kbd> +{' '}
                      <kbd className="bg-primary">R</kbd> o tambien puede
                      presionar <kbd className="bg-primary">Ctrl</kbd> +{' '}
                      <kbd className="bg-primary">F5</kbd>
                    </p>
                    <p>
                      Mac <i className="bi bi-apple"></i>:{' '}
                      <kbd className="bg-primary">Cmd</kbd> +{' '}
                      <kbd className="bg-primary">Shift</kbd> +{' '}
                      <kbd className="bg-primary">R</kbd> o tambien puede
                      presionar <kbd className="bg-primary">Shift</kbd> y hacer
                      click en <i className="bi bi-arrow-clockwise"></i> (Botón
                      recargar)
                    </p>
                  </span>
                </div>
                <div className="row mb-4 p-3 shadow">
                  <span>
                    <p>
                      Safari <i className="bi bi-browser-safari"></i>
                    </p>
                    <p>
                      Mac <i className="bi bi-apple"></i>:{' '}
                      <kbd className="bg-primary">Cmd</kbd> +{' '}
                      <kbd className="bg-primary">Option</kbd> +{' '}
                      <kbd className="bg-primary">E</kbd>
                    </p>
                  </span>
                </div>
                <div className="row  p-3 shadow">
                  <span>
                    <p>
                      Edge: <i className="bi bi-browser-edge"></i>
                    </p>
                    <p>
                      Windows <i className="bi bi-windows"></i>:{' '}
                      <kbd className="bg-primary">Ctrl</kbd> +{' '}
                      <kbd className="bg-primary">F5</kbd> o tambien puede
                      presionar <kbd className="bg-primary">Ctrl</kbd> y hacer
                      click en <i className="bi bi-arrow-clockwise"></i> (Botón
                      recargar)
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditarRecurso;
