import React, { useEffect, useState } from 'react';
import { getDesvinculadosByProveedor } from '../../../services/recursos';
import useUser from '../../../hooks/useUser';
import { Link } from 'react-router-dom';
import CustomTable from '../../general/CustomTable';
import { formatDate } from '../../../hooks/useDates';
import { urlS3 } from '../../../config';

const columns = [
  {
    name: '',
    cell: (row) => (
      <div className="avatar avatar-lg">
        {row.foto ? (
          <img
            src={urlS3 + row.foto}
            alt={row.nombre.slice(0, 3).toUpperCase()}
          />
        ) : (
          <span className="avatar-content bg-secondary rounded-circle">
            {row.nombre.slice(0, 3).toUpperCase()}
          </span>
        )}
      </div>
    ),
    width: '7%',
  },
  {
    name: 'NOMBRE',
    selector: (row) => row.nombre,
    sortable: true,
    clave: 'nombre', // esta se usa en el componente exportar a excel
  },
  {
    name: 'APELLIDO',
    selector: (row) => row.apellido,
    sortable: true,
    clave: 'apellido',
  },
  {
    name: 'TELEFONO',
    selector: (row) => row.telefono,
    clave: 'telefono',
  },
  {
    name: 'REGION',
    selector: (row) => row.region,
    sortable: true,
    clave: 'region',
  },
  {
    name: 'CARGO',
    selector: (row) => row.cargo,
    sortable: true,
    clave: 'cargo',
  },
  {
    name: 'FECHA BAJA',
    selector: (row) => formatDate(row.fecha_baja),
    sortable: true,
    clave: 'fecha_baja',
    width: '8%',
  },
  {
    name: 'MOTIVO',
    cell: (row) => (
      <span
        className={
          row.motivo_desvinculacion === 'Solicitud del mandante'
            ? 'text-danger'
            : ''
        }
        style={{
          color:
            row.motivo_desvinculacion === 'Solicitud Mandante'
              ? 'red'
              : 'inherit',
        }}
      >
        {row.motivo_desvinculacion || 'Sin información'}
      </span>
    ),
  },
  {
    name: 'VER',
    cell: (row) => (
      <Link
        to={`/proveedor/recurso/desvinculado/${row.id}`}
        className="btn btn-outline-primary btn-sm"
      >
        <i className="bi bi-eye"></i>
      </Link>
    ),
    width: '5%',
    right: true,
  },
];
function VerRecursosDesvinculadosP() {
  const { apiToken, dataUserLogged } = useUser();
  const [recursos, setRecursos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getDesvinculadosByProveedor(
        dataUserLogged.rut,
        apiToken
      );
      setRecursos(data.recursos);
      setIsLoading(false);
    };
    fetchData();
  }, [apiToken, dataUserLogged.rut]);

  const excelName = `Lista de recursos desvinculados ${dataUserLogged.proveedor_nombre}`;

  return (
    <section className="section">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">RECURSOS DESVINCULADOS</h4>
        </div>
        <div className="card-body">
          <CustomTable
            columns={columns}
            data={recursos}
            isLoading={isLoading}
            excelName={excelName}
          />
        </div>
      </div>
    </section>
  );
}

export default VerRecursosDesvinculadosP;
