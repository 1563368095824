import { Link, useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';

function MenuSpoc() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/');

  const { logout } = useUser(); // hook para hacer logout

  return (
    <div className="sidebar-menu">
      <ul className="menu">
        <li className="sidebar-title">Menu</li>
        <li
          className={'sidebar-item ' + (path[2] === undefined ? 'active' : '')}
        >
          <Link to="/spoc" className="sidebar-link">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        {/* Recursos */}
        <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'recursos' || path[2] === 'recurso' ? 'active' : '')
          }
        >
          <Link to="/spoc/recursos" className="sidebar-link">
            <i className="bi bi-person-video2"></i>
            <span>Recursos</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/spoc/recursos">Ver Todos</Link>
            </li>
            <li className="submenu-item">
              <a
                href="https://mappstorage.s3.amazonaws.com/gepro/92040000-0/informes/Listado_General_Recursos.xlsx"
                download
              >
                Descargar Lista Completa{' '}
                <i className="bi bi-file-earmark-bar-graph"></i>
              </a>
            </li>
          </ul>
        </li>

        {/* OVT */}
        <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'ovt' ? 'active' : '')
          }
        >
          <Link to="/spoc/ovt" className="sidebar-link">
            <i className="bi bi-clock-fill"></i>
            <span>Overtime</span>
          </Link>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/spoc/overtime">Ir</Link>
            </li>
            <li className="submenu-item">
              <Link to="/spoc/overtime/nueva">Nueva solicitud</Link>
            </li>
          </ul>
        </li>

        {/* Reclamos */}
        {/* <li
          className={
            'sidebar-item has-sub ' +
            (path[2] === 'reclamos' || path[2] === 'reclamo' ? 'active' : '')
          }
        >
          <a href="#" className="sidebar-link">
            <i className="bi bi-exclamation-diamond-fill"></i>
            <span>Reclamos</span>
          </a>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/spoc/reclamo/nuevo">Generar Reclamo</Link>
            </li>
            <li className="submenu-item">
              <Link to="/spoc/reclamos">Ver Todos</Link>
            </li>
          </ul>
        </li> */}

        {/* Documentos */}
        {/* <li
          className={
            'sidebar-item has-sub ' + (path[2] === 'documentos' ? 'active' : '')
          }
        >
          <a href="#" className="sidebar-link">
            <i className="bi bi-archive-fill"></i>
            <span>Documentos</span>
          </a>
          <ul className="submenu">
            <li className="submenu-item">
              <Link to="/spoc/documentos/empresa">Empresa</Link>
            </li>
            <li className="submenu-item">
              <Link to="/spoc/documentos/recursos">Recursos</Link>
            </li>
          </ul>
        </li> */}
        <li className="sidebar-item">
          <Link to="/" className="sidebar-link" onClick={logout}>
            <i className="bi bi-door-open-fill"></i>
            <span>Cerrar Sesión</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MenuSpoc;
