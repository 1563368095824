import { Link } from 'react-router-dom';

function CardUltimosGastosGenerados({ data, perfil }) {
  return (
    <div className="card shadow">
      <div className="card-body px-1">
        <h6 className='text-center'>ÚLTIMAS GENERADAS</h6>
        <div className="list-group list-group-flush">
          {data.map((generada) => (
            <Link 
              key={generada.id} 
              to={`/${perfil}/gastos/${generada.id}`} 
              className="list-group-item list-group-item-action d-flex justify-content-between py-2 px-2"
            >
              <div className="name col-8">
                <h6 className="mb-1">N° {generada.correlativo}</h6>
                <small className="text-muted mb-0">{generada.solicitante}</small>
                <p className="text-primary mb-0">
                  {generada.proveedor_nombre}
                </p>
                <small className="text-muted">
                  {generada.created_at} hrs
                </small>
              </div>
              <div className="col-4 ms-3">
                <h6 className="text-success mb-0">
                  $ {generada.monto_total}
                </h6>
                <small className="text-info">{generada.estado}</small>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardUltimosGastosGenerados;
