import { useEffect, useRef, useState } from 'react';
import { preaprobacionItem } from '../../../../services/gastosAdicionales';

function ModalEditValorItem({ data, setActualizarData }) {
  const [dataItem, setDataItem] = useState(null);
  const inputMontoPreaprobado = useRef(null);
  const inputComentarioItem = useRef(null);
  const inputMontoSolicitado = useRef(null);

  useEffect(() => {
    setDataItem(data);
  }, [data]);

  useEffect(() => {
    if (data) {
      if (data.monto_preaprobado !== null) {
        inputMontoPreaprobado.current.value = data.monto_preaprobado;
        inputMontoSolicitado.current.value = data.monto_solicitado;
      } else {
        inputMontoPreaprobado.current.value = data.monto_solicitado;
        inputMontoSolicitado.current.value = data.monto_solicitado;
      }
    }
  }, [data]);

  const handleUpload = () => {
    const { monto_preaprobado, monto_solicitado, monto_total } = dataItem;
    const inputMontoPreaprobadoValue = Number(
      inputMontoPreaprobado.current.value
    );
    const montoPreaprobado = monto_preaprobado ?? monto_solicitado;

    dataItem.monto_total =
      Number(monto_total) + (inputMontoPreaprobadoValue - montoPreaprobado);
    dataItem.monto_preaprobado = inputMontoPreaprobadoValue;

    preaprobacionItem(dataItem).then((res) => {
      if (res.status === 200) {
        setActualizarData(true);
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    setDataItem(null);
    inputMontoPreaprobado.current.value = '';
    inputComentarioItem.current.value = '';
  };

  return (
    <div
      className="modal fade modal-lg"
      id="modalEditarPreaprobado"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Preaprobar Otro Monto</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group mb-4 col-3">
                <label htmlFor="MontoSolicitado">Monto Solicitado $</label>
                <input
                  ref={inputMontoSolicitado}
                  type="number"
                  className="form-control"
                  id="MontoSolicitado"
                  name="monto_solicitado"
                  disabled
                />
              </div>
              <div className="form-group mb-4 col-3">
                <label htmlFor="MontoPreaprobado">Monto Preaprobado $</label>
                <input
                  ref={inputMontoPreaprobado}
                  type="number"
                  className="form-control"
                  id="MontoPreaprobado"
                  name="monto_preaprobado"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label htmlFor="comentarioPreaprobacion">
                  Comentario Preaprobacion
                </label>
                <textarea
                  ref={inputComentarioItem}
                  type="text"
                  className="form-control"
                  id="comentarioPreaprobacion"
                  name="comentario_preaprobacion"
                  onChange={(e) => {
                    setDataItem({
                      ...dataItem,
                      comentario_preaprobacion: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleUpload}
            >
              Modificar monto
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEditValorItem;
