/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { urlS3 } from '../../../config';
import {
  getLastDesvinculacionesByProveedor,
  getUltimosRecursosDesvinculados,
} from '../../../services/recursos';

function UltimasDesvinculaciones({ rut_proveedor, apiToken }) {
  const laUrl = useLocation().pathname;
  const perfil = useMemo(() => laUrl.split('/')[1], [laUrl]);
  const [ultimasDesvinculaciones, setUltimasDesvinculaciones] = useState([]);

  async function fetchData() {
    try {
      let data;
      if (perfil === 'proveedor') {
        data = await getLastDesvinculacionesByProveedor(
          rut_proveedor,
          apiToken
        );
      } else if (perfil === 'administracion' || perfil === 'spoc') {
        data = await getUltimosRecursosDesvinculados(apiToken);
      }
      setUltimasDesvinculaciones(data.recursos);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card shadow">
      <div className="card-header">
        <h6>ÚLTIMAS DESVINCULACIONES</h6>
      </div>
      <div className="card-content pb-4">
        {ultimasDesvinculaciones ? (
          ultimasDesvinculaciones.map((recurso, i) => (
            <div className="recent-message d-flex px-4 py-3" key={i}>
              <div className="avatar avatar-xl">
                {recurso.foto ? (
                  <img
                    src={urlS3 + recurso.foto}
                    alt={recurso.nombre.slice(0, 3).toUpperCase()}
                  />
                ) : (
                  <span className="avatar-content bg-secondary rounded-circle">
                    {recurso.nombre.slice(0, 3).toUpperCase()}
                  </span>
                )}
              </div>
              <div className="name ms-4">
                <Link to={`/${perfil}/recurso/desvinculado/${recurso.id}`}>
                  <h6 className="mb-1">{`${recurso.nombre} ${recurso.apellido}`}</h6>
                </Link>

                <p className="text-muted mb-0">
                  {recurso.proveedor_nombre || recurso.region}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="recent-message d-flex px-4 py-3">
            <h6>No hay dinformación</h6>
          </div>
        )}
        {`/${perfil}` === laUrl && (
          <div className="px-4">
            <Link
              to={`/${perfil}/recursos/desvinculados`}
              className="btn btn-block btn-outline-primary font-bold mt-3"
            >
              Ver Todos
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default UltimasDesvinculaciones;
